import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";

import CustomerLoader from "../CRM/Modal/CustomerLoder";

function UpdateProduct() {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const navigate = useNavigate();
  const { _id } = useParams();
  const location = useLocation();
  const DefaulData = location?.state;

  console.log({ DefaulData });

  const [formData, setFormData] = useState({
    IDphoto: "",
    pricePerKg: "",
    unit: "",
    purchaseUnit: "",
    salesUnit: "",
    variety: "",
    description: "",
    companyName: "",
    availableQuantity: "",
    category: "",
    purchaseCost: "",
  });

  useEffect(() => {
    if (DefaulData) {
      setFormData({ ...DefaulData, productId: _id });
      setImageUrl(DefaulData?.image);
      setSelectedOption([
        { label: DefaulData?.productName, value: DefaulData?.productName },
      ]);
      setSelectedUnitId(DefaulData?.productUnitId);
    }
  }, [DefaulData]);

  const [ShowPassword, setShowPassword] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [unitData, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [relateUnitData, setRelatableUnitData] = useState([]);
  const [productCategories, setCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useState(""); // State for unitId
  const [categoryItem, setCategoryItem] = useState(""); // State for unitId
  const [selectedSalesUnitId, setSelectedSalesUnitId] = useState(""); // State for unitId
  const [purchaseUnitId, setSelectedPurchaseUnitId] = useState(""); // State for unitId
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const togglePopup = () => {
    setShowInfoPopup(!showInfoPopup);
  };
  const [imageUrl, setImageUrl] = useState(selectedProduct?.image || "");

  useEffect(() => {
    setImageUrl(selectedProduct?.image || "");
  }, [selectedProduct]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSalesUnitChange = (e) => {
    const { name, value } = e.target;
    console.log({});
    const selectedUnit = unitData.find((unit) => unit.name === value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (selectedUnit) {
      setSelectedSalesUnitId(selectedUnit._id);
    }
  };
  const handlePurchaseIdChange = (e) => {
    const { name, value } = e.target;
    console.log({});
    const selectedUnit = unitData.find((unit) => unit.name === value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (selectedUnit) {
      setSelectedPurchaseUnitId(selectedUnit._id);
    }
  };

  const handlePurchaseChange = (e) => {
    const { name, value } = e.target;
    const selectedUnit = unitData.find((unit) => unit.name === value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (selectedUnit) {
      setSelectedUnitId(selectedUnit._id);
    } else {
      setSelectedUnitId(""); // Reset if no valid unit is found
    }
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    console.log({ value });
    setCategoryItem(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const options = productData.map((option) => ({
    value: option._id,
    label: option.productName,
    ...option,
  }));

  const handleSelectChange = (option) => {
    console.log({ option });
    setSelectedOption(option);
    setFormData(option);
    if (option.__isNew__) {
      setIsProduct(false);
      setSelectedProduct(null);
      handleChange({ target: { name: "productName", value: option.label } });
    } else {
      setIsProduct(true);
      setSelectedProduct(option);
      setFormData((prevData) => ({
        ...prevData,
        IDphoto: option?.image || "",
        pricePerKg: option?.pricePerKg || "",
        unit: option?.unit || "",
        purchaseUnit: option?.purchaseUnit || "",
        salesUnit: option?.salesUnit || "",
        variety: option?.variety || "",
        description: option?.description || "",
        companyName: option?.companyName || "",
        availableQuantity: option?.availableQuantity || "",
        category: option?.category || "",
      }));

      handleChange({ target: { name: "productName", value: option.value } });
    }
  };

  const getRelatableUnits = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-Relatable-Units`,
        { unitId: selectedUnitId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        setRelatableUnitData(data);
        setShowLoader(false);
      });
  };

  const unitlist = async () => {
    await axios
      .post(
        `/v1/super-Agent/unit-list`,
        { limit: 100 },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        console.log("pass" + data);
        const total = resp.data.totalRow;
        // console.log(total);
        const totalPage = Math.ceil(total / 100);
      });
  };

  const categoryList = async () => {
    await axios
      .post(
        `/v1/super-Agent/get-productCategory`,
        { limit: 1000 },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        setCategories(data);
        console.log("testCategory", data);
      });
  };

  console.log({ selectedProduct });

  const productList = async () => {
    await axios
      .post(
        `/v1/super-Agent/product-list-for-purchase`,
        { limit: 100 },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        const data = resp.data.data;
        setProductData(data);
        console.log("pass" + data);
        const total = resp.data.totalRow;
        // console.log(total);
        const totalPage = Math.ceil(total / 100);
      });
  };

  const handleFileChange = (data) => {
    let formdata = new FormData();
    // console.log("@#@#@123" + data);

    if (data) {
      const url = URL.createObjectURL(data);
      setImageUrl(url);
      formdata.append("file", data);

      console.log("image first data", formdata);
      formdata.append("Image", data);
      axios
        .post(`/v1/DID/ImageUploader`, formdata, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const imgurl = res.data.data;
          console.log("image url", imgurl);
          //   setCutsmerImage(imgurl);
          setFormData({
            ...formData,
            image: imgurl,
          });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    } else {
      toast.error(data.msg);
    }
    // })
    // .catch(error => {
    //   console.error("Error checking face API:", error);
    // });
    // } else {

    // }
  };

  const formcheck = (e) => {
    e.preventDefault();
    setShowPassword(true);
  };

  const UpdateFormData = async (e) => {
    axios
      .post(
        `/v1/super-Agent/product/update`,
        {
          isProduct,
          ...formData,
          unitId: selectedSalesUnitId,
          productUnitId: selectedUnitId,
          purchaseUnitId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        let data = resp.data;
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            navigate("/products/ManagementList");
            e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const VerifyPassword = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;

      if (data.status) {
        setShowLoader(false);
        UpdateFormData();
      } else {
        toast.error(response.data.message);
        setShowPassword(false);
        setShowLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    ;
    unitlist();
    productList();
    getRelatableUnits();
    categoryList();
  }, []);

  useEffect(() => {
    getRelatableUnits();
  }, [selectedUnitId]);
  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Update Products</h3>
                    </div>
                    <div className="card-title m-0">
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="btn btn-dark"
                        id="kt_advanced_search_button_1"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => formcheck(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-8">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Product Image
                          </label>
                          <div className="col-lg-8">
                            <div
                              className="image-input image-input-outline"
                              data-kt-image-input="true"
                            >
                              <div className="image-input-wrapper w-125px h-125px"></div>
                              <div style={{ position: "absolute", top: "0px" }}>
                                {imageUrl && (
                                  <img
                                    src={imageUrl}
                                    alt="Uploaded"
                                    className="w-125px h-125px"
                                    Value={selectedProduct?.IDphoto}
                                  />
                                )}
                              </div>
                              <label
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Product Image"
                              >
                                <i className="bi bi-pencil-fill fs-7"></i>
                                <input
                                  type="file"
                                  name="IDphoto"
                                  onChange={(e) =>
                                    handleFileChange(e.target.files[0])
                                  }
                                  Value={selectedProduct?.IDphoto}
                                />
                              </label>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel"
                                data-bs-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                            </div>
                            <div className="form-text">
                              Allowed file types: png, jpg, jpeg.
                            </div>
                          </div>
                        </div>

                        <div className="row mb-8">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Product Name
                              </label>
                              <div className="col-lg-8 fv-row">
                                <CreatableSelect
                                  isClearable
                                  options={options}
                                  onChange={handleSelectChange}
                                  value={selectedOption}
                                  placeholder="Please Select or Enter Product"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Selling Price
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  min={0}
                                  step="0.01"
                                  name="pricePerKg"
                                  Value={formData?.pricePerKg || ""}
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Price Per"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Purchase Price
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  min={0}
                                  step="0.01"
                                  name="purchaseCost"
                                  Value={formData?.purchaseCost || ""}
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Price Per"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Product Unit
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  className="form-select flex-grow-1"
                                  data-placeholder="Please Select Category"
                                  data-hide-search="true"
                                  name="productUnit"
                                  value={formData?.productUnit}
                                  // Name to identify the field
                                  onChange={handlePurchaseChange}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Please Select Unit
                                  </option>
                                  {unitData?.map((option) => (
                                    <option
                                      key={option._id}
                                      value={option.name}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Purchase Unit
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  className="form-select  flex-grow-1"
                                  value={formData?.purchaseUnit}
                                  data-placeholder="Please Select Category"
                                  data-hide-search="true"
                                  name="purchaseUnit"
                                  onChange={handlePurchaseIdChange}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Please Select Unit
                                  </option>
                                  {relateUnitData?.map((option) => (
                                    <option
                                      key={option.name}
                                      value={option.name}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Sales Unit
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  className="form-select  flex-grow-1"
                                  // data-control="select2"
                                  data-placeholder="Please Select Category"
                                  data-hide-search="true"
                                  name="salesUnit"
                                  value={formData?.salesUnit}
                                  onChange={handleSalesUnitChange}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Please Select Unit
                                  </option>
                                  {relateUnitData?.map((option) => (
                                    <option
                                      key={option.name}
                                      value={option.name}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Variety
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="variety"
                                  onChange={handleChange}
                                  min={0}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Optional"
                                  Value={formData?.variety}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Description
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="description"
                                  onChange={handleChange}
                                  min={0}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  required
                                  placeholder="Enter Description"
                                  Value={formData?.description}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-6">
                            <div className="row ">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Product Brand Name
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="test"
                                  name="companyName"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Enter Company Name"
                                  onChange={handleChange}
                                  required
                                  min={0}
                                  Value={formData?.companyName}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Enter Inventory 
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  min={0}
                                  step="0.01"
                                  name="availableQuantity"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Inventory"
                                  onChange={handleChange}
                                  Value={formData?.availableQuantity}
                                />
                             
                              </div>
                            </div>

                    
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Category
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  className="form-select  flex-grow-1"
                                  // data-control="select2"
                                  data-placeholder="Please Select Category"
                                  data-hide-search="true"
                                  name="category"
                                  value={formData?.category}
                                  onChange={handleCategoryChange}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Please Select Category
                                  </option>
                                  {productCategories?.map((option) => (
                                    <option
                                      key={option._id}
                                      value={option.title}
                                    >
                                      {option.title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          {categoryItem?.toLowerCase() === "seeds" && (
                            <div className="col-lg-6 mb-6">
                              <div className="row">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                  Please Enter Seeds Type
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="text"
                                    name="seedType"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Available Quantity"
                                    onChange={handleChange}
                                    Value={formData?.availableQuantity}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-center"
        show={ShowPassword}
        // show={Candelhandler}
        onHide={() => setShowPassword(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerifyPassword}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Verify
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
export default UpdateProduct;
