import Header from "../Header";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import Sidebar from "../Sidebar";
import { useHistory } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import CustomerLoader from "./Modal/CustomerLoder";
// import CanvasJSReact from './canvasjs.react';
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Custometrnsaction() {
  const { _id } = useParams("");
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const [data, setData] = useState([]);
  const [document, setdocument] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [translist, settranslist] = useState([]);
  const [Query, setQuery] = useState("");
  const [searching, setsearching] = useState("");
  const [show, setshow] = useState(false);
  const [photo, setphoto] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [pageCountEMI, setpageCountEMI] = useState("");
  const [Blockhandler, setBlockhandler] = useState(false);
  const [Orguselistdata, setOrguselistdata] = useState([]);
  const [userPro, setUserinfo] = useState([]);
  const [userLoanDetail, setUserLoan] = useState([]);
  const [existingLoan, setExistingLoan] = useState([]);
  const [imagepassword, setimagepassword] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [Image, setImage] = useState();
  const [show1, setShow1] = useState(false);
  const [Addloanhistory, setAddloanhistory] = useState([]);
  const [loanhistory, setloanhistory] = useState([]);
  const [Collateral, setCollateral] = useState(null);
  const [Loanshow, setLoanshow] = useState(false);
  const [btnshow, setbtnshow] = useState(false);
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgAgentType = localStorage.getItem("orgAgentType");
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [otherimage, setotherimage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [ShowLoader, setShowLoader] = useState(true);
  
  const [selectedEducation, setSelectedEducation] = useState(userPro?.education || '');

  const handleEducationChange = (event) => {
    setSelectedEducation(event.target.value);
  };

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleSelectChange = (e) => {
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setSelectedOption(e.target.value);
    console.log({ selectedText });
    setotherimage(selectedText);
  };

  const imageInputRef = React.createRef();

  const handleEditImage = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleImageUpload = (event) => {
    const uploadedImage = event.target.files[0];
    setImageFile(uploadedImage);

    let formdata = new FormData();
    formdata.append("image", uploadedImage);

    console.log("image first datav ", formdata);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        console.log(" imagr url ", imgurl);
        setImage(imgurl);
      });
  };

  const handleUploadToServer = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", Image);
    formData.append("Type", "asset");
    formData.append("custId", _id);

    axios
      .post(`/v1/org/ActiveDID/update-customer-docs`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        if (data.status) {
          toast.success(data.msg);
          return custumerDetails();
        }
      })
      .catch((error) => {
        toast.error(data.msg);
      });
    // }
  };

  //integrate create loan api

  const AddFormData = async (e) => {
    e.preventDefault();
    var data = new FormData(e.target);

    var Formvlaues = Object.fromEntries(data.entries());
    Formvlaues.custId = _id;

    const formData = Formvlaues;

    console.log(Formvlaues);

    navigate(`/superagent/customer/loan-allow/${_id}`, {
      state: Formvlaues,
    });
  };

  const VeriflyLoan = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });

        setLoanshow(false);
        setbtnshow(true);

        // toast.success(data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // toast.error(error.response.data.message)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };

  const payLoan = async (e) => {
    // const organisation = localStorage.getItem('6311a0de778efce58f2336db');
    // console.log(organisation);
    // setDataID(organisation);
    var token = localStorage.getItem("token");

    e.preventDefault();
    var data = new FormData(e.target);

    var Formvlaues = Object.fromEntries(data.entries());
    // Formvlaues.custId = _id

    const formData = Formvlaues;
    // let dataToSend2 = new FormData();
    // dataToSend2.append('Addsubagent', Formvlaues.Addsubagent);
    // // dataToSend2.append('performPayOut', Formvlaues.isChecked);
    // // dataToSend2.append('cancelTarnsection', Formvlaues.isChecked);
    // // dataToSend2.append('approveTransection', Formvlaues.isChecked);
    // // dataToSend2.append('createdigitalID', Formvlaues.isChecked);
    // // dataToSend2.append('cashierapprove', Formvlaues.isChecked);
    console.log(Formvlaues);

    axios
      .post(`/v1/super-Agent/pay-loan`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            // window.location = "/agent-list";
            e.target.reset();
            custumerLoanDetail();
            loanDeatailshistory();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const getExistingLoans = () => {
    const { custId } = { custId: _id };
    axios
      .post(
        `/v1/super-Agent/all-customer-loans`,
        { custId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        console.log("loandata", { data });
        setExistingLoan(data);
        // console.log("@@@@@@@" + data.firstName);
      })
      .catch(error);
  };

  const limit = 5;
  const userlist = () => {
    setShowLoader(true);
    const { agentId } = { agentId: _id };
    axios
      .post(
        `v1/org/CRM/get-super-agent-detail`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data[0];
        setData(data);
        console.log("@@@@@@@" + data.firstName);
        setShowLoader(false);
      })
      .catch(error);
  };

  const custumerDetails = () => {
    axios
      .post(
        `/v1/super-Agent/cust-detail `,
        { custID: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.Data;
        console.log("---->>>>--->>>", data);
        setUserinfo(data);
        setSelectedEducation(data?.education);
        console.log("details", data);
        setShowLoader(false);
      });
  };

  const custumerLoanDetail = () => {
    axios
      .post(
        `/v1/super-Agent/trasaction-dash`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        console.log("---->>>>--->>>Loan", data);
        setUserLoan(data);

        console.log("data===== filter", data);
      });
  };

  // graph show
  const [frequency, setFrequency] = useState("month");
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);

  const UserPermoanceyear = () => {
    let agentId = _id;
    let type = frequency;
    const body = { agentId: agentId, Type: type };

    axios
      .post(`/v1/org/CRM/org-superagent-performance-graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const count = userData.map((item) => item.count);
        const month = userData.map((item) => item.value);
        console.log("++++++++++++++graph data", count);

        setuseractivedatayear(count);
        setuseractivedatamonths(month);
      });
  };

  useEffect(() => {
    UserPermoanceyear();
  }, [frequency]);

  const AddloanDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/get-loan-logs`,
        { custId: _id, page: 1, type: "Loan" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setAddloanhistory(saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        console.log("Addloan", total);
      });
  };

  const fetchCommentspage = async (page) => {
    axios
      .post(
        `/v1/super-Agent/get-loan-logs`,
        { custId: _id, page: page, type: "Loan" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setAddloanhistory(saveData);
      });
    return data;
  };
  const handlePageClickpage = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentspage(page);
  };

  const loanDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/get-loan-logs`,
        { custId: _id, page: 1, type: "EMI" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setloanhistory(saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpageCountEMI(totalPage);
        console.log("EMI", totalPage);
      });
  };

  const fetchCommentsCMI = async (page) => {
    axios
      .post(
        `/v1/super-Agent/get-loan-logs`,
        { custId: _id, page: page, type: "EMI" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setloanhistory(saveData);
      });

    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentsCMI(page);
  };

  // api call
  useEffect(() => {
    AddloanDeatailshistory();
    loanDeatailshistory();
    ;
    // Orguselist();
    custumerDetails();
    custumerLoanDetail();
    getExistingLoans();
  }, []);

  // document updated

  const handleEditClick = () => {
    // Trigger file input click when edit icon is clicked
    fileInputRef.current.click();
  };

  const handleFileChange = (event, key) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      console.log(`Selected file: ${fileName}`);
      // Do whatever you need with the fileName
    }
    // Handle file change, you can upload the file or perform other actions
    let formdata = new FormData();
    formdata.append("image", selectedFile);

    console.log("Selected file:", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdatedata(imgurl);
        }
      })
      .catch(error);
  };

  const handleupdatedata = (image) => {
    let formdata = {
      type: "licenceCertificate",
      image: image,
      superAgentId: _id,
    };
    axios
      .put(`/v1/org/CRM/update-super-agent-doc-by-org`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        console.log({ data });
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };

  const handleEditClick1 = () => {
    // Trigger file input click when edit icon is clicked
    fileInputRef1.current.click();
  };

  const handleFileChange1 = (event, key) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      console.log(`Selected file: ${fileName}`);
      // Do whatever you need with the fileName
    }
    // Handle file change, you can upload the file or perform other actions
    let formdata = new FormData();
    formdata.append("image", selectedFile);

    console.log("Selected file:", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdatedata1(imgurl);
        }
      })
      .catch(error);
  };

  const handleupdatedata1 = (image) => {
    let formdata = {
      type: "membershipRegistration",
      image: image,
      superAgentId: _id,
    };
    axios
      .put(`/v1/org/CRM/update-super-agent-doc-by-org`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        console.log({ data });
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };

  const handleEditClick2 = () => {
    // Trigger file input click when edit icon is clicked
    fileInputRef2.current.click();
  };

  const handleFileChange2 = (event, key) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      console.log(`Selected file: ${fileName}`);
      // Do whatever you need with the fileName
    }
    // Handle file change, you can upload the file or perform other actions
    let formdata = new FormData();
    formdata.append("image", selectedFile);

    console.log("Selected file:", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdatedata2(imgurl);
        }
      })
      .catch(error);
  };

  const handleupdatedata2 = (image) => {
    let formdata = {
      type: "applicationLetter",
      image: image,
      superAgentId: _id,
    };
    axios
      .put(`/v1/org/CRM/update-super-agent-doc-by-org`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        console.log({ data });
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };

  // const handleresidance = () => {

  //   let formdata = new FormData();
  //   formdata.append("image", );

  //   console.log("image first datav ", formdata);
  //   axios
  //     .post(`/v1/DID/ImageUploader`, formdata, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((res) => {
  //       const imgurl = res.data.data;

  //     })
  //     .catch(error);
  // };

  const VerfiyBlock = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/ChromePay855658ADKI/kacha/verify-Password`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockhandler(false);
      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3000,
        });

        // toast.success(data.message);

        // handleblocked();
        if (imagepassword == 0) {
          return handleEditClick();
        } else if (imagepassword == 1) {
          return handleEditClick1();
        } else if (imagepassword == 2) {
          return handleEditClick2();
        } else if (imagepassword == 3) {
          setTimeout(() => {
            setShow1(true);
          }, 4000);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // toast.error(error.response.data.message)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler(false);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              {/* <!--begin: Pic--> */}
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={
                                      userPro?.IDphoto ||
                                      "/assets_new/images/lodingwallet.gif"
                                    }
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              {/* <!--end::Pic--> */}

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  {/* <!--begin::User--> */}
                                  <div className="d-flex flex-column">
                                    {/* <!--begin::Name--> */}
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {userPro?.fullname}
                                      </a>

                                      {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                    </div>
                                    {/* <!--end::Name--> */}

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.professoin}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.nationality || "Ethiopia"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.email}
                                      </a>
                                    </div>
                                    {/* <!--end::Info--> */}
                                  </div>
                                  {/* <!--end::User--> */}
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap"></div>
                                    <div className="d-flex mt-3 flex-wrap">
                                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            class="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.totalLoanAmount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div class="fw-semibold fs-6 text-white text-center">
                                          Total Loan Amount (ETB)
                                        </div>
                                      </div>
                                      <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            class="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.totalPaidAmount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div class="fw-semibold fs-6 text-white text-center">
                                          Total Paid Amount
                                        </div>
                                      </div>
                                      <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            class="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.remainingamount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div class="fw-semibold fs-6 text-white text-center">
                                          Principal (ETB)
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            class="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.totalIntrestAmount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div class="fw-semibold fs-6 text-white text-center">
                                          Total Interest Amount
                                        </div>
                                      </div>
                                      {/* {userLoanDetail?.findAllTotalSavings?.map(item => {
                                         <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                         <div class="d-flex align-items-center justify-content-center">
                                           <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                           <div
                                             class="fs-2 fw-bold text-white"
                                             data-kt-countup="true"
                                             data-kt-countup-prefix="$"
                                           >
                                             {`${(
                                               userLoanDetail?.Mandatory || 0
                                             ).toFixed(2)}`}
                                           </div>
                                         </div>
                                         <div class="fw-semibold fs-6 text-white text-center">
                                           Mandatory Savings
                                         </div>
                                       </div>
                                      })} */}
                                      {/* <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            class="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.Mandatory || 0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div class="fw-semibold fs-6 text-white text-center">
                                          Mandatory Savings
                                        </div>
                                      </div> */}
                                     
                                    </div>
                                  </div>

                                  {/* <!--begin::Progress--> */}

                                  {/* <!--end::Progress--> */}
                                </div>
                                <div className="activediv d-flex  flex-column align-items-start justift-content-center flex-equal me-5">
                                  <h1 className="fw-bold  fs-4 fs-lg-1 text-white mb-5 mb-lg-5 mt-7">
                                    {isVisible ? (
                                      <>
                                        <span>
                                          Bruh-ID Ref : {userPro.digitalrefID}
                                        </span>
                                        &nbsp;
                                      </>
                                    ) : (
                                      <>
                                        <span>Bruh-ID Ref : #######{}</span>
                                        &nbsp;
                                      </>
                                    )}

                                    <button
                                      type="button"
                                      onClick={toggleVisibility}
                                      className="didview  bg_ab96 btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    >
                                      <span className="svg-icon svg-icon-3">
                                        {isVisible ? (
                                          <>
                                            {" "}
                                            <i className="fad fa-eye-slash fs-4 text-white"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="fad fa-eye fs-4 text-white"></i>
                                          </>
                                        )}
                                      </span>
                                    </button>
                                  </h1>

                                  <div className="did-title mt-3 ">
                                    {isVisible ? (
                                      <>
                                        <h1>D-ID Token : {userPro.hash}</h1>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              {/* <!--end::Info--> */}
                            </div>
                          </div>

                          <div className="flex-equal  d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100  bg_ab96 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            {/* <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-4 fs-lg-base nav-link px-3 px-lg-7 mx-1  text-uppercase"
                              >
                                Add Loan
                              </a>
                            </li> */}

                            {/* <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 text-uppercase "
                              >
                                Pay Loan{" "}
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      {/* make loan */}

                      <div
                        id="kt_account_settings_profile_details"
                        className="collapse show"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div
                              className="card mb-5 mb-xl-10"
                              id="kt_profile_details_view"
                            >
                              <div className="card-header cursor-pointer">
                                <div className="card-body p-9">
                                  <div
                                    id="kt_account_settings_profile_details"
                                    className="collapse show"
                                  >
                                    <h3 className="card-title align-items-center flex-column">
                                      <span className="card-label fw-bold fs-3 mb-1">
                                        Personal Details
                                      </span>
                                    </h3>
                                    <form
                                      onSubmit={(e) => AddFormData(e)}
                                      id="kt_account_profile_details_form"
                                      className="form"
                                    >
                                      <div className="card-body border-top p-9">
                                        <div className="row mb-8">
                                          <div className="col-lg-6 mb-8">
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Full Name
                                                </label>

                                                <div className="col-lg-6 fv-row">
                                                  <input
                                                    name="fullName"
                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    type="text"
                                                    required
                                                    placeholder="Full Name"
                                                    defaultValue={
                                                      userPro?.fullname
                                                    }
                                                  />
                                                  <input
                                                    name="custId"
                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    type="hidden"
                                                    required
                                                    defaultValue={_id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Mother's Name
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                  <input
                                                    name="motherName"
                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    type="text"
                                                    required
                                                    placeholder="Mother's Name"
                                                    defaultValue={
                                                      userPro?.motherName
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Contact Number
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                  <input
                                                    name="phoneNumber"
                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    type="text"
                                                    required
                                                    placeholder="Contact Number"
                                                    defaultValue={
                                                      userPro?.phone
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Home Address
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                  <input
                                                    name="homeAddress"
                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    type="text"
                                                    required
                                                    defaultValue={
                                                      userPro?.address || "N/A"
                                                    }
                                                    placeholder="Home Address"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Marriage Status
                                                </label>

                                                {/* <div className="col-lg-8 fv-row">
                                                  <select
                                                    name="marriageStatus"
                                                    className="form-select border-0 flex-grow-1"
                                                    data-control="select2"
                                                    data-placeholder="Please Select"
                                                    required
                                                    defaultValue={
                                                      userPro?.married
                                                        ? "married"
                                                        : "single"
                                                    }
                                                  >
                                                    <option
                                                      value={
                                                        userPro?.marriageStatus ||
                                                        (userPro?.married
                                                          ? "married"
                                                          : "single")
                                                      }
                                                    >
                                                      {userPro?.marriageStatus ||
                                                      userPro?.married
                                                        ? "Married"
                                                        : "Single"}
                                                    </option>
                                                    <option value={true}>
                                                      Married
                                                    </option>
                                                    <option value={false}>
                                                      Single
                                                    </option>
                                                  </select>
                                                </div> */}

                                                <div className="col-lg-8 fv-row">
                                                  <select
                                                    name="marriageStatus"
                                                    className="form-select border-0 flex-grow-1"
                                                    data-control="select2"
                                                    data-placeholder="Please Select"
                                                    required
                                                    defaultValue={
                                                      userPro?.marriageStatus ??
                                                      (userPro?.married
                                                        ? "married"
                                                        : "single")
                                                    }
                                                  >
                                                    <option disabled value="">
                                                      Please Select
                                                    </option>
                                                    <option value="married">
                                                      Married
                                                    </option>
                                                    <option value="single">
                                                      Single
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Date Of Birth
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                  <input
                                                    name="dateOfBirth"
                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    type="date"
                                                    required
                                                    defaultValue={
                                                      userPro?.dateOfBirth
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Education
                                                </label>
                                                {/* <div className="col-lg-8 fv-row">
                                                  <select
                                                    name="education"
                                                    className="form-select border-0 flex-grow-1"
                                                    data-placeholder="Selected Education"
                                                    required
                                                    defaultValue={
                                                      userPro?.education
                                                    }
                                                    onChange={
                                                      handleEducationChange
                                                    }
                                                  >
                                                    <option
                                                      value={userPro?.education}
                                                    >
                                                      Selected Education
                                                    </option>
                                                    <option value="HighSchoolGraduate">
                                                      High School Graduate
                                                    </option>
                                                    <option value="UniversityGraduate">
                                                      University Graduate
                                                    </option>
                                                    <option value="None">
                                                      None
                                                    </option>
                                                    <option value="Other">
                                                      Other
                                                    </option>
                                                  </select>

                                                  {selectedEducation ===
                                                    "Other" && (
                                                    <input
                                                      type="text"
                                                      name="otherEducation"
                                                      className="form-control mt-2"
                                                      placeholder="Enter other education"
                                                     
                                                    />
                                                  )}
                                                </div> */}

<div className="col-lg-8 fv-row">
      <select
        name="education"
        className="form-select border-0 flex-grow-1"
        data-placeholder="Selected Education"
        required
        value={selectedEducation}
        onChange={handleEducationChange}
      >
        <option value="" disabled>
          Selected Education
        </option>
        <option value="HighSchoolGraduate">
          High School Graduate
        </option>
        <option value="UniversityGraduate">
          University Graduate
        </option>
        <option value="None">
          None
        </option>
        <option value="Other">
          Other
        </option>
      </select>

      {selectedEducation === 'Other' && (
        <input
          type="text"
          name="otherEducation"
          className="form-control mt-2"
          placeholder="Enter other education"
        />
      )}
    </div>





                                              </div>
                                            </div>

                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Professoin
                                                </label>
                                                <div className="col-lg-8 fv-row">
                                                  <select
                                                    name="profession"
                                                    className="form-select border-0 flex-grow-1"
                                                    data-placeholder="Please Select"
                                                    required
                                                    defaultValue={
                                                      userPro?.professoin
                                                    }
                                                  >
                                                    <option
                                                      value={
                                                        userPro?.professoin
                                                      }
                                                    >
                                                      {userPro?.professoin ||
                                                        userPro?.professoin}
                                                    </option>
                                                    <option value="Not-Employeed">
                                                      Not Employeed
                                                    </option>
                                                    <option value="Student">
                                                      Student
                                                    </option>
                                                    <option value="Farmar">
                                                      Farmar
                                                    </option>
                                                    <option value="Merchant">
                                                      Merchant
                                                    </option>
                                                    <option value="Teacher">
                                                      Teacher
                                                    </option>
                                                    <option value="Accountant">
                                                      Accountant
                                                    </option>
                                                    <option value="Manager">
                                                      Manager
                                                    </option>
                                                    <option value="Software-Developer">
                                                      Software Developer
                                                    </option>
                                                    <option value="Banker">
                                                      Banker
                                                    </option>
                                                    <option value="Driver">
                                                      Driver
                                                    </option>
                                                    <option value="Assistant">
                                                      Assistant
                                                    </option>
                                                    <option value="Other">
                                                      Other
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Number of Children
                                                </label>
                                                <div className="col-lg-8 fv-row">
                                                  <input
                                                    type="Number"
                                                    name="numberChildren"
                                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    placeholder="Number of Children"
                                                    required
                                                    defaultValue={
                                                      userPro?.numberChildren||0
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-lg-6">
                                            <div
                                              className="card mb-5 mb-xl-10"
                                              id="kt_profile_details_view"
                                            >
                                              <div className="card-header cursor-pointer d-flex">
                                                <div
                                                  className="card-title m-0 row"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="col-md-6">
                                                    <h3 className="fw-bold m-0">
                                                      Asset Certificate
                                                    </h3>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        <div className="d-flex gap-3 justifly-center ">
                                                          <input
                                                            name="image"
                                                            type="file"
                                                            accept="image/*"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            ref={imageInputRef}
                                                            onChange={
                                                              handleImageUpload
                                                            }
                                                          />

                                                          <button
                                                            type="button"
                                                            onClick={
                                                              handleEditImage
                                                            }
                                                            className="btn btn-light"
                                                          >
                                                            <i class="fas fa-file-edit fs-101 mx-5"></i>
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="text-center btn btn-light"
                                                            onClick={
                                                              handleUploadToServer
                                                            }
                                                          >
                                                            Update
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="card-body p-9">
                                                {userPro?.landRegistration ? (
                                                  <>
                                                    <img
                                                      src={
                                                        userPro?.landRegistration
                                                      }
                                                      className="d-block"
                                                      style={{
                                                        width: "53%",
                                                        margin: "auto",
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <h1 className="text-center">
                                                      No Assets Registered
                                                    </h1>
                                                    {/* <img src="https://shadi-app.s3.ap-southeast-2.amazonaws.com/abc/doc%20not%20avail-02.png" className='d-block' style={{ width: "53%", margin: "auto" }} /> */}
                                                    {/* <div>
                                  <input
                                    name="image"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    ref={imageInputRef}
                                    onChange={handleImageUpload}
                                  />

                                  {imageFile ? (
                                    <div>
                                      <img src={URL.createObjectURL(imageFile)} alt="Uploaded" className='d-block' style={{ width: "53%", margin: "auto" }} />

                                      <button className="text-center btn btn-light" onClick={handleUploadToServer}>Update</button>
                                    </div>
                                  ) : (
                                    <div className="text-center">
                                     

                                      <button onClick={handleEditImage} className="btn btn-light"><i class="fas fa-file-edit fs-101"></i></button>

                                    </div>
                                  )}
                                </div> */}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>

                                          {/* <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Monthly Income (ETB)
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <input
                                                  type="Number"
                                                  name="monthlyIncome"
                                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                  placeholder="Monthly Income"
                                                  step="any"
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Loan Amount (ETB)
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <input
                                                  type="Number"
                                                  name="loanAmount"
                                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                  placeholder="Loan Amount"
                                                  step="any"
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Repayment method
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="repaymentMethod"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-placeholder="Selected  Repayment Method"
                                                  required
                                                >
                                                  <option value="cash">
                                                    Cash
                                                  </option>
                                                  <option value="bankDeposit">
                                                    Bank Deposit
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Repayment Schedule
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="repaymentSchedule"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-placeholder="Please Select"
                                                  required
                                                  onChange={
                                                    handleRepaymentScheduleChange
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Selected Repayment Schedule
                                                  </option>
                                                  <option value="monthly">
                                                    Monthly
                                                  </option>
                                                  <option value="quarterly">
                                                    Quarterly
                                                  </option>
                                                  <option value="halfYearly">
                                                    Half-Yearly
                                                  </option>
                                                  <option value="Yearly">
                                                    Yearly
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Loan Duration
                                              </label>

                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="loanDuration"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-control="select2"
                                                  data-placeholder="First Selected Repayment
                                                  Schedule"
                                                  required
                                                >
                                                  {repaymentSchedule ==
                                                  "monthly" ? (
                                                    <>
                                                      <option>
                                                        Selected Monthly
                                                      </option>
                                                      <option value="1">
                                                        1
                                                      </option>
                                                      <option value="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                      <option value="13">
                                                        13
                                                      </option>
                                                      <option value="14">
                                                        14
                                                      </option>
                                                      <option value="15">
                                                        15
                                                      </option>
                                                      <option value="16">
                                                        16
                                                      </option>
                                                      <option value="17">
                                                        17
                                                      </option>
                                                      <option value="18">
                                                        18
                                                      </option>
                                                      <option value="19">
                                                        19
                                                      </option>
                                                      <option value="20">
                                                        20
                                                      </option>
                                                      <option value="21">
                                                        21
                                                      </option>
                                                      <option value="22">
                                                        22
                                                      </option>
                                                      <option value="23">
                                                        23
                                                      </option>
                                                      <option value="24">
                                                        24
                                                      </option>
                                                      <option value="25">
                                                        25
                                                      </option>
                                                      <option value="26">
                                                        26
                                                      </option>
                                                      <option value="27">
                                                        27
                                                      </option>
                                                      <option value="28">
                                                        28
                                                      </option>
                                                      <option value="29">
                                                        29
                                                      </option>
                                                      <option value="30">
                                                        30
                                                      </option>
                                                      <option value="31">
                                                        31
                                                      </option>
                                                      <option value="32">
                                                        32
                                                      </option>
                                                      <option value="33">
                                                        33
                                                      </option>
                                                      <option value="34">
                                                        34
                                                      </option>
                                                      <option value="35">
                                                        35
                                                      </option>
                                                      <option value="36">
                                                        36
                                                      </option>
                                                      <option value="37">
                                                        37
                                                      </option>
                                                      <option value="38">
                                                        38
                                                      </option>
                                                      <option value="39">
                                                        39
                                                      </option>
                                                      <option value="40">
                                                        40
                                                      </option>
                                                      <option value="41">
                                                        41
                                                      </option>
                                                      <option value="42">
                                                        42
                                                      </option>
                                                      <option value="43">
                                                        43
                                                      </option>
                                                      <option value="44">
                                                        44
                                                      </option>
                                                      <option value="45">
                                                        45
                                                      </option>
                                                      <option value="46">
                                                        46
                                                      </option>
                                                      <option value="47">
                                                        47
                                                      </option>
                                                      <option value="48">
                                                        48
                                                      </option>
                                                      <option value="49">
                                                        49
                                                      </option>
                                                      <option value="50">
                                                        50
                                                      </option>
                                                    </>
                                                  ) : (
                                                    <option value="36">
                                                      First Selected Repayment
                                                      Schedule
                                                    </option>
                                                  )}
                                                  {repaymentSchedule ==
                                                  "quarterly" ? (
                                                    <>
                                                      <option>
                                                        Selected Quarterly
                                                      </option>

                                                      <option value="3">
                                                        3
                                                      </option>

                                                      <option value="6">
                                                        6
                                                      </option>

                                                      <option value="9">
                                                        9
                                                      </option>

                                                      <option value="12">
                                                        12
                                                      </option>

                                                      <option value="15">
                                                        15
                                                      </option>

                                                      <option value="18">
                                                        18
                                                      </option>

                                                      <option value="21">
                                                        21
                                                      </option>

                                                      <option value="24">
                                                        24
                                                      </option>

                                                      <option value="27">
                                                        27
                                                      </option>

                                                      <option value="30">
                                                        30
                                                      </option>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {repaymentSchedule ==
                                                  "halfYearly" ? (
                                                    <>
                                                      <option>
                                                        Selected Half Yearly
                                                      </option>

                                                      <option value="6">
                                                        6
                                                      </option>

                                                      <option value="12">
                                                        12
                                                      </option>

                                                      <option value="18">
                                                        18
                                                      </option>

                                                      <option value="24">
                                                        24
                                                      </option>

                                                      <option value="30">
                                                        30
                                                      </option>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {repaymentSchedule ==
                                                  "Yearly" ? (
                                                    <>
                                                      <option>
                                                        Selected Yearly
                                                      </option>
                                                      <option value="1">
                                                        1
                                                      </option>
                                                      <option value="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Reason
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <div className="col-lg-8 fv-row">
                                                  <select
                                                    className="form-select border-0 flex-grow-1"
                                                    name="reason"
                                                    placeholder="Reason"
                                                  >
                                                    <option>
                                                      Selected Reason
                                                    </option>
                                                    <option value="Personal-use">
                                                      Personal Use
                                                    </option>
                                                    <option value="Pay-bills">
                                                      Pay Bills
                                                    </option>
                                                    <option value="Farming">
                                                      Farming{" "}
                                                    </option>
                                                    <option value="Business-Use">
                                                      Business Use{" "}
                                                    </option>
                                                    <option value="Other">
                                                      Other
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}

                                          {/* <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Interest Rate
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="interestRate"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-control="select2"
                                                  data-placeholder="Please Select"
                                                  required
                                                >
                                                  {" "}
                                                  <option value="8">
                                                    Selected Interest Rate
                                                  </option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                  <option value="5">5</option>
                                                  <option value="6">6</option>
                                                  <option value="7">7</option>
                                                  <option value="8">8</option>
                                                  <option value="9">9</option>
                                                  <option value="10">10</option>
                                                  <option value="11">11</option>
                                                  <option value="12">12</option>
                                                  <option value="13">13</option>
                                                  <option value="14">14</option>
                                                  <option value="15">15</option>
                                                  <option value="16">16</option>
                                                  <option value="17">17</option>
                                                  <option value="18">18</option>
                                                  <option value="19">19</option>
                                                  <option value="20">20</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div> */}
                                          {/* <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Repayment Schedule
                                              </label>

                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="repaymentSchedule"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-control="select2"
                                                  data-placeholder="Please Select"
                                                >
                                                  <option>
                                                    Selected Repayment Schedule
                                                  </option>
                                                  <option value="Auto Detact">
                                                    Auto Detact
                                                  </option>
                                                  <option value="Cash">
                                                    Cash
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div> */}
                                        </div>

                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                          <button
                                            type="submit"
                                            className="btn btn-light btn-active-light-primary me-2"
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-lg-6">
                            <div className="card card-xxl-stretch mb-5 mb-xl-8">
                              <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                  <span className="card-label fw-bold fs-3 mb-1">
                                    Recent Transaction
                                  </span>
                                  <span className="text-muted mt-1 fw-semibold fs-7"></span>
                                </h3>
                              </div>

                              <div className="card-body py-3">
                                <div className="table-responsive">
                                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                      <tr className="fw-bold text-muted th-title">
                                        <th className="min-w-125px">
                                          Transaction <br /> Date{" "}
                                        </th>
                                        <th className="min-w-150px">
                                          Loan
                                          <br />
                                          Amount
                                        </th>
                                        <th className="min-w-150px">
                                          Loan <br /> Type{" "}
                                        </th>
                                        <th className="min-w-100px">
                                          Total
                                          <br /> Amount{" "}
                                        </th>
                                        <th className="min-w-100px text-center">
                                          Status <br />
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {Addloanhistory.map((item) => (
                                        <tr key={item.id}>
                                          <td>
                                            {moment(item.createdAt).format(
                                              "MMMM Do YYYY, h:mm:ss a"
                                            )}
                                          </td>
                                          <td>{item.totalAmount.toFixed(2)}</td>
                                          <td>{item.type}</td>
                                          <td>{item.remainingAmount.toFixed(2)}</td>
                                          <td>
                                            <span className="text-success">
                                              Success
                                            </span>
                                          </td>
                                        
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-lg-12 mt-2 text-end">
                                  <ReactPaginate
                                    PreviousLabel={"Previous"}
                                    NextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClickpage}
                                    containerClassName={
                                      "pagination justify-content-end gap-sm-3"
                                    }
                                    pageClassName={"page-item mx-2"}
                                    pageLinkClassName={"page-link"}
                                    PreviousClassName={"page-item"}
                                    PreviousLinkClassName={
                                      "page-link pagestyle"
                                    }
                                    NextClassName={"page-item"}
                                    NextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ----------------------------------------------Pay-Loan------------------------------------------------------------ */}
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* details modal  */}

          <Modal
            className="modal-center"
            show={Loanshow}
            onHide={() => setLoanshow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VeriflyLoan}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div> */}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Custometrnsaction;
