import React, { useRef, useState } from "react";
import moment from "moment";
import axios from "axios";
import Jwt from "jsonwebtoken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";

function SharePrice() {
  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const [DateObject, setDateObject] = useState([]);
  const [PriceValue, setPriceValue] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [latestSharePrice, setPrice] = useState("");

  useEffect(() => {
    ;
    SharePriceGraph();
    latestPrice();
  }, []);

  const SharePriceUpdated = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    axios
      .post(`/v1/super-Agent/update-super-share-price`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            // e.target.reset();
            SharePriceGraph();
            latestPrice();
            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const latestPrice = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-super-share-price`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          console.log("ppppp", data);
          if (data.status) {
            console.log("ppppp", data.data);
            setPrice(data.data);
            SharePriceGraph();
            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };

  const SharePriceFilterGraph = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    axios
      .post(`/v1/super-Agent/get-all-share-updates`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;

          if (data.status) {
            // let apiData = data.data.sort((a, b) => b.date - a.date);
            // let apiData = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            let apiData = data.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));


            let price = apiData?.map((item) => item?.newPrice);

            let Datetoshare = apiData.map((item) =>
              moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
            );

            console.log({ apiData, price });

            setDateObject(Datetoshare);
            setPriceValue(price);
            console.log("apiData", apiData);
            toast.success(data.msg);
            return;
          } else {
            toast.error(data.msg);
          }
        }
      });
  };

  const SharePriceGraph = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-all-share-updates`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;

          if (data.status) {
            // let apiData = data.data;
//    let apiData = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
   let apiData = data.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));



            let price = apiData?.map((item) => item?.newPrice);
            let Datetoshare = apiData.map((item) =>
              moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
            );

            console.log({ apiData });

            setDateObject(Datetoshare);
            setPriceValue(price);
            toast.success(data.msg);
            return;
          } else {
            toast.error(data.msg);
          }
        }
      });
  };

  return (
    <>
      <div>
        <ToastContainer />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Share Information</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <div className="d-flex p-9">
                        <label className="col-lg-2 col-form-label fw-semibold fs-6">
                          Current Share Price (ETB)
                        </label>
                        <div className="col-lg-4 fv-row">
                          <input
                            type="text"
                            name="Licenses"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Current Share Price"
                            defaultValue={latestSharePrice}
                            readOnly
                          />
                        </div>
                      </div>
                      <form
                        onSubmit={(e) => SharePriceUpdated(e)}
                        id="kt_account_profile_details_form"
                        className="form"
                      >
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                              Update Share Price (ETB)
                            </label>

                            <div className="col-lg-4 fv-row">
                              <input
                                type="number"
                                min={1}
                                name="price"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Update Share Price"
                              />
                            </div>

                            <div className="col-lg-2 fv-row">
                              <button
                                type="submit"
                                className="btn btn-light btn-sub me-2"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row g-5 g-xl-8">
                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start ">
                          <span className="card-label fw-bold fs-3 mb-1">
                            Latest Share Price (ETB)
                          </span>
                          <span className="text-muted mt-1 fw-semibold fs-7"></span>
                        </h3>
                        <form
                          onSubmit={(e) => SharePriceFilterGraph(e)}
                          className="card-title d-flex justify-content-between align-items-end"
                        >
                          <div className=" d-flex input-container align-items-center mb-3  mb-lg-0">
                            <label className="small_label pb-2">
                              Start Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="fromDate"
                              onChange={(e) => setstartDate(e.target.value)}
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className=" d-flex align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label pb-2">End Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="toDate"
                              min={startDate}
                            />
                          </div>
                          <div className="min-w-150px text-end d-flex">
                            <button
                              type="submit"
                              className="btn btn-light btn-sub "
                              id="kt_advanced_search_button_1"
                            >
                              Search
                            </button>

                            <button
                              type="reset"
                              onClick={SharePriceGraph}
                              className="btn btn-secondary mx-sm-5"
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>
                        </form>
                      </div>

                      <div className="card-body py-3">
                        <div className="bg-white overflow-auto card">
                          <Chart
                            type="area"
                            width={"95%"}
                            height={400}
                            series={[
                              {
                                name: "Transaction",
                                data: PriceValue,
                                fill: {
                                  colors: PriceValue.map(
                                    (value, index, array) =>
                                      value > array[index - 1]
                                        ? "#ff0000"
                                        : "#3c7f8c"
                                  ),
                                },
                              },
                            ]}
                            options={{
                              plotOptions: {
                                area: {
                                  borderRadius: 2,
                                  columnWidth: 50,
                                },
                              },
                              markers: {
                                size: 0,
                              },
                              stroke: {
                                width: 3,
                                curve: "smooth",
                              },
                              colors: PriceValue.map((value, index, array) =>
                                value > array[index - 1] ? "#ff0000" : "#3c7f8c"
                              ),
                              theme: { mode: "light" },
                              xaxis: {
                                tickPlacement: "on",
                                categories: DateObject,
                                title: {
                                  style: { color: "#f90000", fontSize: 0 },
                                },
                              },
                              yaxis: {
                                labels: {
                                  formatter: (val) => {
                                    return `${val}`;
                                  },
                                },
                              },
                              legend: {
                                show: true,
                                position: "right",
                              },
                              dataLabels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                                style: {
                                  colors: PriceValue.map(
                                    (value, index, array) =>
                                      value < array[index - 1]
                                        ? "#ff0000"
                                        : "#3c7f8c"
                                  ),
                                  fontSize: 15,
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* added new progess bar  */}
    </>
  );
}

export default SharePrice;
