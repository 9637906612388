



import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../../Jdc/common/Header";
import Sidebar from "../../Jdc/common/Sidebar";

const JdcPrivatedRouting = () => {
  const isAuthenticated = !!localStorage.getItem("token"); // or use your own authentication logic
  const location = useLocation();

  return isAuthenticated ? 
  <>
  <Header/>
  <Outlet />
  <Sidebar/>
  </>
  
   : <Navigate to="/login-jdc"  />;
};
export default JdcPrivatedRouting;
