

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import SearchComponent from "../../SuperAgent/Products/SearchComponent";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { backend } from "blink-detection";
var jwt = require("jsonwebtoken");

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const TransactionsPage = () => {
    let token = localStorage.getItem("token");
   var decode1 = jwt.decode(token);
  //  let _id = decode1.OrganisationID


   const [Today_date, setToday_date] = useState([]);
   const [Last_Month, setLast_Month] = useState([]);
   const [useractivedata, setuseractivedata] = useState([]);
   const [useractivedatayear, setuseractivedatayear] = useState([]);
   const [useractivedatamonths, setuseractivedatamonths] = useState([]);
   const [useractiveSavingdatayear, setuseractiveSavingdatayear] = useState([]);
   const [useractiveSavingdatamonths, setuseractiveSavingdatamonths] = useState([]);

   const [useractiveStoredatayear, setuseractiveStoredatayear] = useState([]);
   const [useractiveStoredatamonths, setuseractiveStoredatamonths] = useState([]);
   const [section, setSection] = useState([]);
   const [dash, setDash] = useState([]);
   const [frequency, setFrequency] = useState("month")
   const [savingTypeFrequency, setsavingTypeFrequency] = useState("mandatory")
   const [savingFrequency, setSavingFrequency] = useState("month")
   const [storeFrequency, setStoreFrequency] = useState("month")
  const [data1, setData1] = useState([]);
  const [orgdash, setOrgDash] = useState([]);
  const [finduser, setFinduser] = useState([]);
  const [findTrans, setFindTrans] = useState([]);

  const formatNumber = (number) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(1) + "T"; // Trillions
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + "B"; // Billions
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M"; // Millions
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "k"; // Thousands
    } else {
      return number?.toString(); // Less than a thousand
    }
  };

  //  const orgID = _id;



   const userlist = () => {
    axios
      .post(
        `/v1/super-Agent/get-jdc-dash`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        const findLicenseFees = data.findLicenseFees;
        const finduser = data.finduser;
        const findTrans = data.findTrans;
        setData1(data);
        setOrgDash(findLicenseFees);
        setFinduser(finduser);
        setFindTrans(findTrans);
        console.log("@@@", finduser);
        //console.log("@@@",findLicenseFees);
        //console.log(data);
      });
  };


   const UserPermoance = async () => {
       await axios.post(`/v1/super-Agent/superagent-report`, {}, { headers: { "Authorization": `Bearer ${token}` } })
       .then((res) => {
           const userData3 = res.data;
           const userData = res.data.detail;
            setuseractivedata(userData3);
           setDash(userData);
           console.log("++++++++++++++12", userData);
          
       });
   };

   const orgTransectionsSection = async () => {
       await axios.post(`/v1/org/reports/org-detail`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
           const data = resp.data.data;
           // console.log("123", data);
           setSection(data);

       });
   };


   useEffect(() => {
       UserPermoance();
       // UserPermoanceyear();
       orgTransectionsSection();
       userlist()
       // setTimeout(() => {

       // }, 1000);

       ;

   }, []);
   // console.log("123");
   // console.log("test", section);
   const transactionGraph = async (sort) => {
       const body = {  Type: frequency }
       await axios.post(`/v1/super-Agent/super-agent-graph`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
         const userData = res.data.data;
   
       //    console.log("++++++++++++++123", userData);
         const categories = userData.map((item) => item.count);
         const users = userData.map((item) => item.value);
   
         setuseractivedatayear(categories);
         setuseractivedatamonths(users);
         // console.log("$$$", categories)
         // setTranscation(data);
         // setuseractivedatayear(Object.values(userData));
   
         // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
       });
     };




     const savingTransactionGraph = async (sort) => {
        const body = {  Type: savingFrequency, savingType : savingTypeFrequency }
        await axios.post(`/v1/super-Agent/jdc-saving-trans-graph`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
          const userData = res.data.data;
    
        //    console.log("++++++++++++++123", userData);
          const categories = userData.map((item) => item.count);
          const users = userData.map((item) => item.value);
    
          setuseractiveSavingdatayear(categories);
          setuseractiveSavingdatamonths(users);
          // console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
    
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
      };


      const storeTransactionGraph = async (sort) => {
        const body = {  Type: storeFrequency }
        await axios.post(`/v1/super-Agent/jdc-store-trans-graph`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
          const userData = res.data.data;
    
        //    console.log("++++++++++++++123", userData);
          const categories = userData.map((item) => item.totalSendingAmount);
          const users = userData.map((item) => item.value);
    
          setuseractiveStoredatayear(categories);
          setuseractiveStoredatamonths(users);
          // console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
    
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
      };
   // const datafilter = (e) => {

   //     e.preventDefault();
   //     const value = e.target.value;


   //     axios.post(`/v1/org/reports/get_org_cust_data_graph`, { filter: value }, { headers: { "Authorization": `bearer ${token}` } }).then((res) => {
   //         var userData = res.data.obj;

   //         console.log("++++++++++++++graph data", userData);

   //         setuseractivedatayear(Object.values(userData));
   //         setuseractivedatamonths(Object.keys(userData));
   //     });
   // };




   // console.log('====================================');
   // console.log(section);
   // console.log('====================================');

   useEffect(() => {
       transactionGraph();
     }, [frequency])
     
   useEffect(() => {
       savingTransactionGraph()
     }, [savingFrequency])

   useEffect(() => {
       savingTransactionGraph()
     }, [savingTypeFrequency])

   useEffect(() => {
    storeTransactionGraph()
     }, [storeFrequency])

     const handlesorting = (event) => {
       let sort = event.target.value;
       setFrequency(sort);
       transactionGraph(sort);
       console.log(sort)
   
     }
     const handleSavingsorting = (event) => {
        let sort = event.target.value;
        setSavingFrequency(sort);
        savingTransactionGraph(sort);
        console.log(sort)
    
      }
     const handleSavingTypesorting = (event) => {
        let sort = event.target.value;
        setsavingTypeFrequency(sort);
        savingTransactionGraph(sort);
        console.log(sort)
    
      }
     const handleStoreTypesorting = (event) => {
        let sort = event.target.value;
        setStoreFrequency(sort);
        storeTransactionGraph(sort);
        console.log(sort)
    
      }
   return (
       <>

           <div
               className="wrapper d-flex flex-column flex-row-fluid mb-5 back-color"
               id="kt_wrapper"

           >
               <div
                   className="content d-flex flex-column flex-column-fluid mb-0 py-0"
                   id="kt_content"
               >
                   <div className="container-xxl mb-0" id="kt_content_container">
  <div className="card-body pt-9 pb-0">
    <div className="row">
      <div className="col-lg-12">
        <div
          className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover"
          style={{ paddingBottom: "1rem" }}
        >
          <div className="card-body pt-9 pb-5">
            <div className="row">
              {/* Box 1: New Users Registered */}
              <div className="col-md-6 col-lg-3">
                <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <i className="fad fa-users"></i>
                      </span>
                    </span>
                  </div>
                  <div className="">
                    <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                      {formatNumber( data1?.totalIdcount || 0)}
                    </span>
                    <h6 className="nz-2">
                      <span className="text-gray-500 fw-semibold fs-6">
                        Total Customers
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              {/* Box 2: Onboarding Target */}
              <div className="col-md-6 col-lg-3">
                <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <i className="fas fa-bullseye-arrow"></i>
                      </span>
                    </span>
                  </div>
                  <div className="">
                    <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                      {formatNumber(data1?.totalLoan || 0)}
                    </span>
                    <h6 className="nz-2">
                      <span className="text-gray-500 fw-semibold fs-6">
                        Total Loan Amount
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              {/* Box 3: Total Store Sales */}
              <div className="col-md-6 col-lg-3">
                <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <i className="fas fa-dollar-sign"></i>
                      </span>
                    </span>
                  </div>
                  <div className="">
                    <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                      {formatNumber(data1?.totalSales)}
                    </span>
                    <h6 className="nz-2">
                      <span className="text-gray-500 fw-semibold fs-6">
                        Total Store Sales
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              {/* Box 4: Total Saving Amount */}
              <div className="col-md-6 col-lg-3">
                <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <i className="fas fa-piggy-bank"></i>
                      </span>
                    </span>
                  </div>
                  <div className="">
                    <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                      {formatNumber(data1?.totalSaving)}
                    </span>
                    <h6 className="nz-2">
                      <span className="text-gray-500 fw-semibold fs-6">
                        Total Saving Amount
                      </span>
                    </h6>
                  </div>
                </div>
              </div>

              {/* Box 5: Total Customers */}
              {/* <div className="col-md-6 col-lg-3">
                <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                  <div className="symbol symbol-30px me-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <i className="fas fa-users"></i>
                      </span>
                    </span>
                  </div>
                  <div className="">
                    <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                      {"totalCustomers"}
                    </span>
                    <h6 className="nz-2">
                      <span className="text-gray-500 fw-semibold fs-6">
                        Total Customers
                      </span>
                    </h6>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

               </div>

               <div className="container-xxl" id="kt_content_container">
                   <div className="row g-5 g-xl-8">
                       <div className="col-lg-12">
                           <div className="row">
                               <div className="col-lg-12">
                                   <div className="card card-flush h-xl-100  mb-5">
                                       <div className="card-header pt-7">

                                           <h3 className="card-title align-items-start flex-column">
                                               <span className="card-label fw-bold text-gray-800">Unions Activity</span>
                                               <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Unions</span>
                                           </h3>

                                           <div className="card-toolbar">
                                               <div className="row mb-6">


                                                   <div className="col-lg-12 fv-row">


                                                       <form >
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       <select defaultValue={frequency} onChange={handlesorting} className="form-select graph-select" >
                                                                           <option value="day"> Day</option>
                                                                           <option value="week"> Week</option>
                                                                           <option value="month"> Monthly</option>
                                                                           <option value="year">Yearly</option>

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                       </form>
                                                   </div>
                                               </div>


                                           </div>

                                       </div>


                                       <div className="bg-white overflow-auto">
                                           <Chart
                                               type="bar"
                                               width={'100%'}
                                               height={280}
                                               series={[
                                                   {
                                                       name: "User",
                                                       data: useractivedatayear,
                                                   },
                                               ]}
                                               options={{
                                                   plotOptions: {
                                                       bar: {
                                                           borderRadius: 2,
                                                           columnWidth: 50,
                                                       },
                                                   },
                                                   title: {
                                                       
                                                   },

                                                   subtitle: {
                                                       
                                                   },

                                                   colors: ["#3c7f8c"],
                                                   theme: { mode: "light" },

                                                   xaxis: {
                                                       tickPlacement: "on",
                                                       categories: useractivedatamonths,
                                                       
                                                   },

                                                   yaxis: {
                                                       labels: {
                                                           formatter: (val) => {
                                                               return `${val}`;
                                                           },
                                                           style: { fontSize: "15", colors: ["#3c7f8c"] },
                                                       },
                                                       title: {
                                                           
                                                       },
                                                   },

                                                   legend: {
                                                       show: true,
                                                       position: "right",
                                                   },

                                                   dataLabels: {
                                                       formatter: (val) => {
                                                           return `${val}`;
                                                       },
                                                       style: {
                                                           colors: ["white"],
                                                           fontSize: 12,
                                                           position: "top",
                                                       },
                                                   },
                                               }}
                                           ></Chart>
                                       </div>

                                   </div>
                               </div>
                               {/* <div className="col-lg-6">
                 <div className="card card-flush h-xl-100">
                 <div className="card-header pt-7">
                                          
                                          <h3 className="card-title align-items-start flex-column">
                                              <span className="card-label fw-bold text-gray-800">Organisation Transaction Activity</span>
                                              <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Organisation </span>
                                          </h3>
                                         
                                          <div className="card-toolbar">
                                          <div className="row mb-6">
                      

                      <div className="col-lg-12 fv-row">
                    

                        <select
                          className="form-select border-0 flex-grow-1"
                          required
                          data-control="select2"
                          data-placeholder="assetType"
                          data-hide-search="true"
                          name="assetType"
                        >
                          <option value="1" selected="selected">
                            {" "}
                            Data filler
                          </option>
                          <option value="week"> Week</option>
                          <option value="months"> Months</option>
                          <option value="year"> Year</option>
                        
                
                        </select>
                      </div>
                    </div>
                                           
                                            
                                          </div>
                                         
                                      </div>

                 
                     <div className="bg-white overflow-auto">
                       <Chart
                         type="area"
                         width={'100%'}
                         height={300}
                         series={[
                           {
                               name: "Transaction",
                             data: [6578, 6787, 3245, 9876, 2324, 5123, 2435,5698,2563,5896,458,6259],
                           },
                         ]}
                         options={{
                           stroke: {
                             width: 2,
                           },

                           colors: ["#3c7f8c"],
                           theme: { mode: "light" },

                           xaxis: {
                             tickPlacement: "on",
                             categories: [
                               "December",
                               "January",
                               "February",
                               "March",
                               "April",
                               "May",
                               "June",
                               "July",
                               "August",
                               "September",
                               "October",
                               "November",
                             ],
                             title: {
                               style: { color: "#f90000", fontSize: 0 },
                             },
                           },

                           yaxis: {
                             labels: {
                               formatter: (val) => {
                                 return `${val}`;
                               },
                             },
                             //
                           },

                           legend: {
                             show: true,
                             position: "right",
                           },

                           dataLabels: {
                             formatter: (val) => {
                               return `${val}`;
                             },
                             style: {
                               colors: ["#3c7f8c"],
                               fontSize: 15,
                             },
                           },
                         }}
                       ></Chart>
              
                   </div>
                 </div>
               </div> */}
                           </div>
                       </div>
                       <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

                           <div className="container-xxl d-flex flex-column flex-md-row flex-stack">

                               {/* <div className="text-dark order-2 order-md-1">
                                   <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                                   <a href="#" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
                               </div> */}


                               {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">About</a>
                                   </li>
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">Support</a>
                                   </li>

                               </ul> */}

                           </div>

                       </div>
                   </div>
               </div>


               {/* saving managment */}

               <div className="container-xxl" id="kt_content_container">
                   <div className="row g-5 g-xl-8">
                       <div className="col-lg-12">
                           <div className="row">
                               <div className="col-lg-12">
                                   <div className="card card-flush h-xl-100  mb-5">
                                       <div className="card-header pt-7">

                                           <h3 className="card-title align-items-start flex-column">
                                               <span className="card-label fw-bold text-gray-800">Unions Saving Transacctions</span>
                                               <span className="text-gray-400 mt-1 fw-semibold fs-6">Unions Transactions Of Super Agent </span>
                                           </h3>

                                           <div className="card-toolbar">
                                               <div className="row mb-6">


                                                   <div className="col-lg-12 fv-row">


                                                       <form >
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   {/* <select
                                                                       className="form-select border-0 flex"
                                                                       style={{ width: "10vw" }}

                                                                       onChange={datafilter}
                                                                       name="filter"
                                                                   >
                                                                       <option value="1" selected="selected">
                                                                           {" "}
                                                                           Data filter
                                                                       </option>
                                                                       <option value="Day"> Day</option>
                                                                       <option value="Month"> Months</option>
                                                                       <option value="Year"> Year</option>
                                                                   </select> */}
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       {/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
                                                                       <select defaultValue={frequency} onChange={handleSavingsorting} className="form-select graph-select" >
                                                                           <option value="day"> Day</option>
                                                                           <option value="week"> Week</option>
                                                                           <option value="month"> Monthly</option>
                                                                           <option value="year">Yearly</option>

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   {/* <select
                                                                       className="form-select border-0 flex"
                                                                       style={{ width: "10vw" }}

                                                                       onChange={datafilter}
                                                                       name="filter"
                                                                   >
                                                                       <option value="1" selected="selected">
                                                                           {" "}
                                                                           Data filter
                                                                       </option>
                                                                       <option value="Day"> Day</option>
                                                                       <option value="Month"> Months</option>
                                                                       <option value="Year"> Year</option>
                                                                   </select> */}
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       {/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
                                                                       <select defaultValue={savingTypeFrequency} onChange={handleSavingTypesorting} className="form-select graph-select" >
                                                                           <option value="mandatory"> Mandatory</option>
                                                                           <option value="voluantary"> Voluntary</option>
                                                                         

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                       </form>
                                                   </div>
                                               </div>


                                           </div>

                                       </div>


                                       <div className="bg-white overflow-auto">
                                           <Chart
                                               type="bar"
                                               width={'100%'}
                                               height={280}
                                               series={[
                                                   {
                                                       name: "User",
                                                       data: useractiveSavingdatayear,
                                                   },
                                               ]}
                                               options={{
                                                   plotOptions: {
                                                       bar: {
                                                           borderRadius: 2,
                                                           columnWidth: 50,
                                                       },
                                                   },
                                                   title: {
                                                       //   text: "BarChar Developed by DevOps Team",
                                                       //   style: { fontSize: 30 },
                                                   },

                                                   subtitle: {
                                                       //   text: "This is BarChart Graph",
                                                       //   style: { fontSize: 18 },
                                                   },

                                                   colors: ["#3c7f8c"],
                                                   theme: { mode: "light" },

                                                   xaxis: {
                                                       tickPlacement: "on",
                                                       categories: useractiveSavingdatamonths,
                                                       //   title: {
                                                       //     text: "Year BY data",
                                                       //     style: { color: "##3c7f8c", fontSize: 10 },
                                                       //   },
                                                   },

                                                   yaxis: {
                                                       labels: {
                                                           formatter: (val) => {
                                                               return `${val}`;
                                                           },
                                                           style: { fontSize: "15", colors: ["#3c7f8c"] },
                                                       },
                                                       title: {
                                                           // text: "User In (K)",
                                                           // style: { color: "#f90000", fontSize: 15 },
                                                       },
                                                   },

                                                   legend: {
                                                       show: true,
                                                       position: "right",
                                                   },

                                                   dataLabels: {
                                                       formatter: (val) => {
                                                           return `${val}`;
                                                       },
                                                       style: {
                                                           colors: ["white"],
                                                           fontSize: 12,
                                                           position: "top",
                                                       },
                                                   },
                                               }}
                                           ></Chart>
                                       </div>

                                   </div>
                               </div>
                               {/* <div className="col-lg-6">
                 <div className="card card-flush h-xl-100">
                 <div className="card-header pt-7">
                                          
                                          <h3 className="card-title align-items-start flex-column">
                                              <span className="card-label fw-bold text-gray-800">Organisation Transaction Activity</span>
                                              <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Organisation </span>
                                          </h3>
                                         
                                          <div className="card-toolbar">
                                          <div className="row mb-6">
                      

                      <div className="col-lg-12 fv-row">
                    

                        <select
                          className="form-select border-0 flex-grow-1"
                          required
                          data-control="select2"
                          data-placeholder="assetType"
                          data-hide-search="true"
                          name="assetType"
                        >
                          <option value="1" selected="selected">
                            {" "}
                            Data filler
                          </option>
                          <option value="week"> Week</option>
                          <option value="months"> Months</option>
                          <option value="year"> Year</option>
                        
                
                        </select>
                      </div>
                    </div>
                                           
                                            
                                          </div>
                                         
                                      </div>

                 
                     <div className="bg-white overflow-auto">
                       <Chart
                         type="area"
                         width={'100%'}
                         height={300}
                         series={[
                           {
                               name: "Transaction",
                             data: [6578, 6787, 3245, 9876, 2324, 5123, 2435,5698,2563,5896,458,6259],
                           },
                         ]}
                         options={{
                           stroke: {
                             width: 2,
                           },

                           colors: ["#3c7f8c"],
                           theme: { mode: "light" },

                           xaxis: {
                             tickPlacement: "on",
                             categories: [
                               "December",
                               "January",
                               "February",
                               "March",
                               "April",
                               "May",
                               "June",
                               "July",
                               "August",
                               "September",
                               "October",
                               "November",
                             ],
                             title: {
                               style: { color: "#f90000", fontSize: 0 },
                             },
                           },

                           yaxis: {
                             labels: {
                               formatter: (val) => {
                                 return `${val}`;
                               },
                             },
                             //
                           },

                           legend: {
                             show: true,
                             position: "right",
                           },

                           dataLabels: {
                             formatter: (val) => {
                               return `${val}`;
                             },
                             style: {
                               colors: ["#3c7f8c"],
                               fontSize: 15,
                             },
                           },
                         }}
                       ></Chart>
              
                   </div>
                 </div>
               </div> */}
                           </div>
                       </div>
                       <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

                           <div className="container-xxl d-flex flex-column flex-md-row flex-stack">

                               {/* <div className="text-dark order-2 order-md-1">
                                   <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                                   <a href="#" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
                               </div>


                               <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">About</a>
                                   </li>
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">Support</a>
                                   </li>

                               </ul> */}

                           </div>

                       </div>
                   </div>
               </div>



               <div className="container-xxl" id="kt_content_container" >
                   <div className="row g-5 g-xl-8" >
                       <div className="col-lg-12" >
                           <div className="row" >
                               <div className="col-lg-12">
                                   <div className="card card-flush h-xl-100  mb-5">
                                       <div className="card-header pt-7">

                                           <h3 className="card-title align-items-start flex-column">
                                               <span className="card-label fw-bold text-gray-800">Unions Store Transacctions</span>
                                               <span className="text-gray-400 mt-1 fw-semibold fs-6">Unions  Transactions  </span>
                                           </h3>

                                           <div className="card-toolbar">
                                               <div className="row mb-6">


                                                   <div className="col-lg-12 fv-row">


                                                       <form >
                                                           <div className="d-flex align-items-center filterCss gap-1 ">
                                                               <div>
                                                                   {/* <select
                                                                       className="form-select border-0 flex"
                                                                       style={{ width: "10vw" }}

                                                                       onChange={datafilter}
                                                                       name="filter"
                                                                   >
                                                                       <option value="1" selected="selected">
                                                                           {" "}
                                                                           Data filter
                                                                       </option>
                                                                       <option value="Day"> Day</option>
                                                                       <option value="Month"> Months</option>
                                                                       <option value="Year"> Year</option>
                                                                   </select> */}
                                                                   <div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

                                                                       {/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
                                                                       <select defaultValue={storeFrequency} onChange={handleStoreTypesorting} className="form-select graph-select" >
                                                                           <option value="day"> Day</option>
                                                                           <option value="week"> Week</option>
                                                                           <option value="month"> Monthly</option>
                                                                           <option value="year">Yearly</option>

                                                                       </select>
                                                                   </div>
                                                               </div>


                                                           </div>
                                                           
                                                       </form>
                                                   </div>
                                               </div>


                                           </div>

                                       </div>


                                       <div className="bg-white overflow-auto">
                                           <Chart
                                               type="bar"
                                               width={'100%'}
                                               height={280}
                                               series={[
                                                   {
                                                       name: "User",
                                                       data: useractiveStoredatayear,
                                                   },
                                               ]}
                                               options={{
                                                   plotOptions: {
                                                       bar: {
                                                           borderRadius: 2,
                                                           columnWidth: 50,
                                                       },
                                                   },
                                                   title: {
                                                       //   text: "BarChar Developed by DevOps Team",
                                                       //   style: { fontSize: 30 },
                                                   },

                                                   subtitle: {
                                                       //   text: "This is BarChart Graph",
                                                       //   style: { fontSize: 18 },
                                                   },

                                                   colors: ["#3c7f8c"],
                                                   theme: { mode: "light" },

                                                   xaxis: {
                                                       tickPlacement: "on",
                                                       categories: useractiveStoredatamonths,
                                                       //   title: {
                                                       //     text: "Year BY data",
                                                       //     style: { color: "##3c7f8c", fontSize: 10 },
                                                       //   },
                                                   },

                                                   yaxis: {
                                                       labels: {
                                                           formatter: (val) => {
                                                               return `${val}`;
                                                           },
                                                           style: { fontSize: "15", colors: ["#3c7f8c"] },
                                                       },
                                                       title: {
                                                           // text: "User In (K)",
                                                           // style: { color: "#f90000", fontSize: 15 },
                                                       },
                                                   },

                                                   legend: {
                                                       show: true,
                                                       position: "right",
                                                   },

                                                   dataLabels: {
                                                       formatter: (val) => {
                                                           return `${val}`;
                                                       },
                                                       style: {
                                                           colors: ["white"],
                                                           fontSize: 12,
                                                           position: "top",
                                                       },
                                                   },
                                               }}
                                           ></Chart>
                                       </div>

                                   </div>
                               </div>
                               {/* <div className="col-lg-6">
                 <div className="card card-flush h-xl-100">
                 <div className="card-header pt-7">
                                          
                                          <h3 className="card-title align-items-start flex-column">
                                              <span className="card-label fw-bold text-gray-800">Organisation Transaction Activity</span>
                                              <span className="text-gray-400 mt-1 fw-semibold fs-6">Activity Of Organisation </span>
                                          </h3>
                                         
                                          <div className="card-toolbar">
                                          <div className="row mb-6">
                      

                      <div className="col-lg-12 fv-row">
                    

                        <select
                          className="form-select border-0 flex-grow-1"
                          required
                          data-control="select2"
                          data-placeholder="assetType"
                          data-hide-search="true"
                          name="assetType"
                        >
                          <option value="1" selected="selected">
                            {" "}
                            Data filler
                          </option>
                          <option value="week"> Week</option>
                          <option value="months"> Months</option>
                          <option value="year"> Year</option>
                        
                
                        </select>
                      </div>
                    </div>
                                           
                                            
                                          </div>
                                         
                                      </div>

                 
                     <div className="bg-white overflow-auto">
                       <Chart
                         type="area"
                         width={'100%'}
                         height={300}
                         series={[
                           {
                               name: "Transaction",
                             data: [6578, 6787, 3245, 9876, 2324, 5123, 2435,5698,2563,5896,458,6259],
                           },
                         ]}
                         options={{
                           stroke: {
                             width: 2,
                           },

                           colors: ["#3c7f8c"],
                           theme: { mode: "light" },

                           xaxis: {
                             tickPlacement: "on",
                             categories: [
                               "December",
                               "January",
                               "February",
                               "March",
                               "April",
                               "May",
                               "June",
                               "July",
                               "August",
                               "September",
                               "October",
                               "November",
                             ],
                             title: {
                               style: { color: "#f90000", fontSize: 0 },
                             },
                           },

                           yaxis: {
                             labels: {
                               formatter: (val) => {
                                 return `${val}`;
                               },
                             },
                             //
                           },

                           legend: {
                             show: true,
                             position: "right",
                           },

                           dataLabels: {
                             formatter: (val) => {
                               return `${val}`;
                             },
                             style: {
                               colors: ["#3c7f8c"],
                               fontSize: 15,
                             },
                           },
                         }}
                       ></Chart>
              
                   </div>
                 </div>
               </div> */}
                           </div>
                       </div>
                       <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

                           <div className="container-xxl d-flex flex-column flex-md-row flex-stack">

                               <div className="text-dark order-2 order-md-1">
                                   <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                                   <a href="#" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
                               </div>


                               <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">About</a>
                                   </li>
                                   <li className="menu-item">
                                       <a href="#" className="menu-link px-2">Support</a>
                                   </li>

                               </ul>

                           </div>

                       </div>
                   </div>
               </div>

           </div>
       </>
   );
}

export default TransactionsPage