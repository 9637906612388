import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import { useHistory, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
// import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import { CSVLink } from "react-csv";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import { Modal, Button, Form } from "react-bootstrap";

// import CanvasJSReact from './canvasjs.react';
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var jwt = require("jsonwebtoken");

const AccountDetail = () => {
  const { _id } = useParams();

  console.log("_id", _id)
  const navigate = useNavigate();

  let token = localStorage.getItem("SuperAgentChromepayToken");

  const [data, setData] = useState([]);
  const [document, setdocument] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [translist, settranslist] = useState([]);
  const [Query, setQuery] = useState("");
  const [searching, setsearching] = useState("");
  const [show, setshow] = useState(false);
  const [photo, setphoto] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [csvLoanPayData, setCsvLoanPayData] = useState([]);
  const [interstDistributionData, setInterstDistributionData] = useState([]);

  const [Orguselistdata, setOrguselistdata] = useState([]);
  const [allData, setAllData] = useState([]);
  const [accData, setAccData] = useState([]);
  const [mainCsv, setMainCsvData] = useState([]);
  const [interestDistributionCSV, setInterestDistributionCSV] = useState([]);
  const [accId, setAccId] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [openingBalance, setOpeningBalance] = useState("");

  console.log("---->>>>", { accData });

  const limit = 5;

  console.log("check-acc-name", _id);

  const userlist = () => {
    const { orgID } = { orgID: _id };
    axios
      .get(`/v1/admin/orgDashSection/${orgID}`)
      .then((resp) => {
        let data = resp.data.data;
        setData(data);
      })
      .catch(error);
  };
  const userdoc = () => {
    const { orgID } = { orgID: _id };
    axios
      .post(`/v1/admin/viewDoc/${orgID}`)
      .then((resp) => {
        let data = resp.data.findDoc;
        setdocument(data);
        console.log("data in org documeny 4444444", data);
      })
      .catch(error);
  };

  const findOrguser = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    axios
      .post(
        `/v1/super-Agent/get-account-detail`,
        { accName: _id, ...Formvlaues },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setOrguselistdata(data);
        setAllData(resp.data);
        setCSVData(resp?.data?.findLoanDisBursedTransCSV || []);
        setCsvLoanPayData(resp?.data?.findLoanPayTransCSV || []);
        setInterstDistributionData(resp?.data?.findInterestDistribution || []);
        setMainCsvData(resp?.data?.csvData || []);
        console.log("test-csv-data", resp?.data?.findInterestDistributionCSVData)
        setInterestDistributionCSV(resp?.data?.findInterestDistributionCSVData || []);
        console.log("test-res-data", resp.data);

        console.log("test-res-data", resp.data);
        setAccData(resp.data);
        console.log("Org list usedata  filler data ", data);
      });
  };

  // first Pagination start

  const Orguselist = async () => {
    setShowLoader(true);

    try {
      const resp = await axios.post(
        `/v1/super-Agent/get-account-detail`,
        { accName: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const data = resp.data.data;
      setOrguselistdata(data);
      setAccData(resp.data);
      setOrguselistdata(data);
      setAllData(resp.data);
      setCSVData(resp?.data?.findLoanDisBursedTransCSV || []);
      setInterstDistributionData(resp?.data?.findInterestDistribution || []);
      setCsvLoanPayData(resp?.data?.findLoanPayTransCSV || []);
      setMainCsvData(resp?.data?.csvData || []);
      setInterestDistributionCSV(resp?.data?.findInterestDistributionCSVData || []);

      console.log("test-csv-data", resp?.data?.findInterestDistributionCSVData)

      console.log("test-res-datatt", resp.data);
      setAccId(resp.data?.findAccount._id);

      const total = resp.data.count;
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);

      // Call the find-account-balance API
      const balanceResp = await axios.post(
        `/v1/super-Agent/find-account-balance`,
        { accId: resp.data?.findAccount._id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (!balanceResp.data.data) {
        // Open modal if account has no opening balance
        setPopupVisible(true);
      }
    } catch (error) {
      console.error("Error in Orguselist:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleAddBalance = async () => {
    setShowLoader(true);

    try {
      const response = await axios.post(
        `/v1/super-Agent/open-account-balance`,
        { accId, balance: openingBalance },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.status) {
        // alert("Opening balance added successfully!");
        setPopupVisible(false);
        Orguselist();
        setShowLoader(false);
        // Close the modal
      } else {
        Orguselist();
        setShowLoader(false);

        // alert("Failed to add opening balance.");
      }
    } catch (error) {
      console.error("Error adding opening balance:", error);
      alert("An error occurred while adding opening balance.");
    }
  };

  const fetchComments = async (page) => {
    const senData = { page: page, accName: _id };
    setShowLoader(true)

    axios
      .post(`/v1/super-Agent/get-account-detail`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setOrguselistdata(data);
        setAccData(resp?.data);
        setAllData(resp?.data);
        setCSVData(resp?.data?.findLoanDisBursedTransCSV || []);
        setMainCsvData(resp?.data?.csvData || []);
        setInterstDistributionData(resp?.data?.findInterestDistribution || []);
        setInterestDistributionCSV(resp?.data?.findInterestDistributionCSVData || []);
        setCsvLoanPayData(resp?.data?.findLoanPayTransCSV || []);
        setShowLoader(false)
      });
    return data;
  };
  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  // end first Pagination

  // start secondes page

  const Transactionuselist = () => {
    const { orgID } = { orgID: _id };
    axios
      .post(`/v1/admin/get_transctions/${orgID}`)
      .then((resp) => {
        let data11 = resp.data.findCust;
        settranslist(data11);
        const total = resp.data.totlaRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        console.log(
          "usettransaction findCust4564654654654654564654654654",
          totalPage
        );
      })
      .catch(error);
  };

  const fetchCommentspage = async (page) => {
    const { orgID } = { orgID: _id };
    setShowLoader(true)
    const senData = { page: page };
    axios.post(`/v1/admin/get_transctions/${orgID}`, senData).then((resp) => {
      let data11 = resp.data.findCust;
      settranslist(data11);
      setShowLoader(false)

    });
    return data;
  };
  const handlePageClickpage = async (data) => {
    const page = data.selected + 1;

    const commentsFormServer = await fetchCommentspage(page);
  };

  // end  seconded page

  // graph use transction

  const Transactionuse = () => {
    axios
      .post(
        `/v1/super-Agent/get-account-detail`,
        { accName: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.obj;
        settransmonths(Object.keys(data));
        settransvalue(Object.values(data));
        setCSVData(resp?.data?.findLoanDisBursedTransCSV || []);
        setCsvLoanPayData(resp?.data?.findLoanPayTransCSV || []);
        setInterstDistributionData(resp?.data?.findInterestDistribution || []);
        setInterestDistributionCSV(resp?.data?.findInterestDistributionCSVData || []);
        setAllData(resp.data);
        setMainCsvData(resp?.data?.csvData || []);
      })
      .catch(error);
    console.log("usettransaction", Object.keys(data), Object.values(data));
  };

  // document show function

  const imageview = (image) => {
    setphoto(image);
    setshow(true);
  };

  // api call
  useEffect(() => {
    userlist();
    userdoc();
    Transactionuse();
    Transactionuselist();
    ;
    Orguselist();
    //   findOrguser()
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              {/* <!--begin: Pic--> */}
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={
                                      "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/images.png"
                                    }
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              {/* <!--end::Pic--> */}

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  {/* <!--begin::User--> */}
                                  <div className="d-flex flex-column">
                                    {/* <!--begin::Name--> */}
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {accData?.findAccount?.accountName}{" "}
                                        Account
                                      </a>

                                      {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                    </div>
                                    {/* <!--end::Name--> */}

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {accData?.findAccount?.accountCode}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {accData?.findAccount?.subAccount}
                                      </a>
                                    </div>
                                    {/* <!--end::Info--> */}
                                  </div>
                                  {/* <!--end::User--> */}
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                opacity="0.5"
                                                x="13"
                                                y="6"
                                                width="13"
                                                height="2"
                                                rx="1"
                                                transform="rotate(90 13 6)"
                                                fill="currentColor"
                                              />
                                              <path
                                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>

                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >{`${accData?.totalBalance?.toFixed(
                                            2
                                          )}`}</div>
                                        </div>

                                        <div className="fw-semibold fs-6 -400 text-white">
                                          {_id === "Loans"
                                            ? "Loan Disbursed"
                                            : "Account Balance"}
                                        </div>
                                      </div>

                                      {_id == "Loans" && (
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div className="d-flex align-items-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  opacity="0.5"
                                                  x="13"
                                                  y="6"
                                                  width="13"
                                                  height="2"
                                                  rx="1"
                                                  transform="rotate(90 13 6)"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>

                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >{`${accData?.totalInterestReceived?.toFixed(
                                              2
                                            )}`}</div>
                                          </div>

                                          <div className="fw-semibold fs-6 -400 text-white">
                                            Total Interest Received
                                          </div>
                                        </div>
                                      )}

                                      {_id == "Loans" && (
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div className="d-flex align-items-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  opacity="0.5"
                                                  x="13"
                                                  y="6"
                                                  width="13"
                                                  height="2"
                                                  rx="1"
                                                  transform="rotate(90 13 6)"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>

                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >{`${allData?.accBalance?.toFixed(
                                              2
                                            )}`}</div>
                                          </div>

                                          <div className="fw-semibold fs-6 -400 text-white">
                                            Account Balance
                                          </div>
                                        </div>
                                      )}

                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                opacity="0.5"
                                                x="13"
                                                y="6"
                                                width="13"
                                                height="2"
                                                rx="1"
                                                transform="rotate(90 13 6)"
                                                fill="currentColor"
                                              />
                                              <path
                                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>

                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`$${Math.max(
                                              0,
                                              allData?.superAccBalance || 0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>

                                        <div className="fw-semibold fs-6 -400 text-white">
                                          Overall Balance
                                        </div>
                                      </div>

                                      {_id == "Shares" && (
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div className="d-flex align-items-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  opacity="0.5"
                                                  x="13"
                                                  y="6"
                                                  width="13"
                                                  height="2"
                                                  rx="1"
                                                  transform="rotate(90 13 6)"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>

                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >{`${
                                              accData?.totalShares || 23348
                                            }`}</div>
                                          </div>

                                          <div className="fw-semibold fs-6 -400 text-white">
                                            Total Shares Quantity
                                          </div>
                                        </div>
                                      )}

                                      {_id == "Loans" && (
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div className="d-flex align-items-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  opacity="0.5"
                                                  x="13"
                                                  y="6"
                                                  width="13"
                                                  height="2"
                                                  rx="1"
                                                  transform="rotate(90 13 6)"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>

                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >{`${accData?.totalInterest?.toFixed(
                                              2
                                            )}`}</div>
                                          </div>

                                          <div className="fw-semibold fs-6 -400 text-white">
                                            Total Interest Amount
                                          </div>
                                        </div>
                                      )}

                                      {_id == "Loans" && (
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div className="d-flex align-items-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  opacity="0.5"
                                                  x="13"
                                                  y="6"
                                                  width="13"
                                                  height="2"
                                                  rx="1"
                                                  transform="rotate(90 13 6)"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>

                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >{`${accData?.repayLoan?.toFixed(
                                              2
                                            )}`}</div>
                                          </div>

                                          <div className="fw-semibold fs-6 -400 text-white">
                                            Total Repayment Loan
                                          </div>
                                        </div>
                                      )}

                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                          <span className=" me-2">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns=""
                                            >
                                              <rect
                                                opacity="0.5"
                                                x="11"
                                                y="18"
                                                width="13"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-90 11 18)"
                                                fill="currentColor"
                                              />
                                              <path
                                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >{`${accData?.findAccount?.category?.title}`}</div>
                                        </div>

                                        <div className="fw-semibold fs-6 -400 text-white">
                                          Account Type{" "}
                                        </div>
                                      </div>
                                      {/* <!--end::Stat--> */}
                                      {/* <!--begin::Stat--> */}

                                      {/* <!--end::Stat--> */}
                                    </div>
                                    <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"></div>
                                  </div>

                                  {/* <!--begin::Progress--> */}

                                  {/* <!--end::Progress--> */}
                                </div>
                              </div>
                              {/* <!--end::Info--> */}
                            </div>
                            {/* <!--end::Details--> */}
                            {/* <!--begin::Navs--> */}

                            {/* <!--begin::Navs--> */}
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            {/* <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                                Overview
                              </a>
                            </li> */}

                            {/* <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                              Organisation Documents
                              </a>
                            </li> */}

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#land"
                                className="btn  active  btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1  "
                              >
                                Transactions
                              </a>
                            </li>

                            {_id == "Loans" && (
                              <li className="nav-item my-1">
                                <a
                                  role="tab"
                                  aria-selected="true"
                                  data-bs-toggle="tab"
                                  href="#finance"
                                  className="btn btn-color-gray-600 btn-active-secondary  btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                                >
                                  EMi Pay Transaction's
                                </a>
                              </li>
                            )}

{(_id === "Mandatory saving" || _id === "Voluntary saving" || _id === "Children saving" || _id === "Non-interest saving") && (
                              <li className="nav-item my-1">
                                <a
                                  role="tab"
                                  aria-selected="true"
                                  data-bs-toggle="tab"
                                  href="#interestDistribution"
                                  className="btn btn-color-gray-600 btn-active-secondary  btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 "
                                >
                                  Interest Distribution
                                </a>
                              </li>
                            )}

                            {/* <li className="nav-item my-1">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#finance"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1"
                              >
                               Organisation   Transactions
                              </a>
                            </li> */}
                            {/* <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#account"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-7 mx-1 text-uppercase"
                              >
                                Bank Account
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane fade"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-title m-0">
                                <h3 className="fw-bold m-0">
                                  Organisation Details
                                </h3>
                              </div>
                            </div>

                            <div className="card-body p-9">
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Organisation Name
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-bold fs-6 -800">
                                    {data.name}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Organisation Type
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-bold fs-6 -800">
                                    {data.orgType}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Code
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {data.code}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Joining Date
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {moment(data.joining_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Email Address
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {/* {Moment(data.dateOfBirth).format(
                                      "DD/MM/YYYY"
                                    )} */}
                                    {data.email}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Contact No.
                                  <i
                                    className="fas fa-exclamation-circle ms-1 fs-7"
                                    data-bs-toggle="tooltip"
                                    title="Phone number must be active"
                                  ></i>
                                </label>

                                <div className="col-lg-8 d-flex align-items-center">
                                  <span className="fw-bold fs-6 -800 me-2">
                                    +{data.phone}
                                  </span>
                                  <span className="badge badge-success">
                                    Verified
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Country
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.country}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  City
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.city}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Area/Post code
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.postCode}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Address
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.address}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card card-flush overflow-hidden h-md-100">
                            <div className="card-header py-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">
                                  Transaction Activities
                                </span>
                                <span className="-400 mt-1 fw-semibold fs-6"></span>
                              </h3>
                            </div>

                            <div className="card-body d-flex  flex-column pb-1 px-0">
                              <div className="px-9 mb-5">
                                <div className="d-flex align-items-center mb-2">
                                  <span className="fs-4 fw-semibold -400 align-self-start me-1">
                                    $
                                  </span>

                                  <span className="fs-2hx fw-bold -800 me-2 lh-1 ls-n2">
                                    {data.totalTransection_amount}
                                  </span>

                                  <span className="badge badge-light-success fs-base">
                                    <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.5"
                                          x="11"
                                          y="18"
                                          width="13"
                                          height="2"
                                          rx="1"
                                          transform="rotate(-90 11 18)"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </div>

                                <span className="fs-6 fw-semibold -400">
                                  Transaction Amount This year
                                </span>
                              </div>
                              <div className="bg-white overflow-auto  card">
                                <Chart
                                  type="area"
                                  width={"100%"}
                                  height={400}
                                  series={[
                                    {
                                      name: "Transaction",
                                      data: transvalue,
                                    },
                                  ]}
                                  options={{
                                    plotOptions: {
                                      bar: {
                                        borderRadius: 2,
                                        columnWidth: 50,
                                      },
                                    },
                                    markers: {
                                      size: 0,
                                    },
                                    stroke: {
                                      width: 3,
                                      curve: "smooth",
                                    },

                                    colors: ["#3c7f8c"],
                                    theme: { mode: "light" },

                                    xaxis: {
                                      tickPlacement: "on",
                                      categories: transmonths,
                                      title: {
                                        style: {
                                          color: "#f90000",
                                          fontSize: 0,
                                        },
                                      },
                                    },

                                    yaxis: {
                                      labels: {
                                        formatter: (val) => {
                                          return `{val}`;
                                        },
                                      },
                                      //
                                    },

                                    legend: {
                                      show: true,
                                      position: "right",
                                    },

                                    dataLabels: {
                                      formatter: (val) => {
                                        return `${val}`;
                                      },
                                      style: {
                                        colors: ["#3c7f8c"],
                                        fontSize: 15,
                                      },
                                    },
                                  }}
                                ></Chart>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="documents"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h3 className="fw-bold my-2">
                            User Documents
                            <span className="fs-6 -400 fw-semibold ms-1"></span>
                          </h3>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(
                                    document?.Certificate_of_Incorporation
                                  )
                                }
                              >
                                <a className="img_div">
                                  <img
                                    src={document?.Certificate_of_Incorporation}
                                  />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    data-toggle="lightbox"
                                    data-gallery="gallery"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5> Identification Document Image</h5>
                                <div>
                                  <a
                                    href={
                                      document?.Certificate_of_Incorporation
                                    }
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(document?.Proof_of_Company_Address)
                                }
                              >
                                <a className="img_div">
                                  <img
                                    src={document?.Proof_of_Company_Address}
                                  />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Company Profile Brochure</h5>
                                <div>
                                  <a
                                    href={document?.Company_Profile_Brochure}
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(document?.Proof_of_Company_Address)
                                }
                              >
                                <a className="img_div">
                                  <img
                                    src={document?.Proof_of_Company_Address}
                                  />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Proof of Company Address</h5>
                                <div>
                                  <a
                                    href={document?.Proof_of_Company_Address}
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(document?.Customer_Agreement)
                                }
                              >
                                <a className="img_div">
                                  <img src={document?.Customer_Agreement} />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Customer Agreement</h5>
                                <div>
                                  <a
                                    href={data?.Customer_Agreement}
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade active show"
                      id="land"
                      role="tabpanel"
                    >
                      <div className="row g-5 g-xl-8">
                        {/* <div className="col-lg-12">
                          <div className="card filter_bg rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                            <div className="card-body container-xxl pt-10 pb-8">
                              <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            Filter
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                          Organization Customers
                          </span>
                        </div>

                              <div className="d-flex flex-column">
                                <div className="d-lg-flex align-lg-items-center">
                                  <form onSubmit={findOrguser}>
                                    <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body w-xxl-850px  me-lg-10 my-5">
                                      <div className="row flex-grow-1 mb-5 mb-lg-0 h-lg-60px">
                                        <div className="col-lg-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                                          <label className="small_label">
                                            Start Date
                                          </label>
                                          <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                            <i className="fad fa-calendar fs-2"></i>
                                          </span>
                                          <input
                                            type="date"
                                            className="form-control unstyled form-control-flush flex-grow-1"
                                            name="fromDate"
                                            placeholder="User Name.."
                                          />
                                          <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                        </div>

                                        <div className="col-lg-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                                          <label className="small_label">
                                            End Date
                                          </label>
                                          <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                            <i className="fad fa-calendar fs-2"></i>
                                          </span>
                                          <input
                                            type="date"
                                            className="form-control unstyled form-control-flush flex-grow-1"
                                            name="toDate"
                                            placeholder="User Name.."
                                          />
                                        </div>

                                        <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                          <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                          <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                x="2"
                                                y="2"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                              <rect
                                                opacity="0.3"
                                                x="13"
                                                y="2"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                              <rect
                                                opacity="0.3"
                                                x="13"
                                                y="13"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                              <rect
                                                opacity="0.3"
                                                x="2"
                                                y="13"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>
                                          <select
                                            className="form-select border-0 flex-grow-1"
                                            data-control="select2"
                                            data-placeholder="Status"
                                            data-hide-search="true"
                                            name="status"
                                          >
                                            <option value=""></option>
                                            <option
                                              value="1"
                                              selected="selected"
                                            >
                                              Category
                                            </option>
                                            <option value="pending">
                                              Pending
                                            </option>

                                            <option value="verified">
                                              verified
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div className="min-w-150px text-center">
                                        <button
                                          type="submit"
                                          className="btn btn-dark"
                                          id="kt_advanced_search_button_1"
                                        >
                                          Search
                                        </button>
                                        <button
                                          type="reset"
                                          className="btn btn-secondary me-2"
                                          id="kt_advanced_search_button_1"
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                              <h5>Transactions List</h5>
                              <CSVLink
                                data={mainCsv}
                                filename={"table_data.csv"}
                                className="btn btn-success mb-3"
                                target="_blank"
                              >
                                Export Data to CSV
                              </CSVLink>
                              {/* <div class="card-title">
                                <div class="d-flex align-items-center position-relative my-1">
                                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x="17.0365"
                                        y="15.1223"
                                        width="8.15546"
                                        height="2"
                                        rx="1"
                                        transform="rotate(45 17.0365 15.1223)"
                                        fill="currentColor"
                                      ></rect>
                                      <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <input
                                    type="text"
                                    data-kt-customer-table-filter="search"
                                    class="form-control form-control-solid w-250px ps-15"
                                    placeholder="Search Customers"
                                    onChange={(e) => setQuery(e.target.value)}
                                  />
                                </div>
                              </div> */}
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">
                                <table
                                  class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                  id="kt_customers_table"
                                >
                                  <thead>
                                    <tr class="fw-bold text-muted th-title text-center">
                                      <th class="min-w-250px text-center">Name</th>
                                      {/* <th class="min-w-250px">ID Photo</th> */}
                                      <th class="min-w-100px">
                                        Bruh-ID Ref Number
                                      </th>
                                      <th class="min-w-150px">
                                        Transaction Id
                                      </th>
                                      <th class="min-w-150px">Date</th>
                                      <th class="min-w-100px">Amount</th>
                                      {_id == "Shares" && (
                                        <th class="min-w-100px">Share Price</th>
                                      )}
                                      {_id == "Shares" && (
                                        <th class="min-w-100px">
                                          Share Quantity
                                        </th>
                                      )}
                                      <th class="min-w-100px">Interest Rate</th>
                                      <th class="min-w-100px">Interest</th>
                                      <th class="min-w-100px">
                                        Account Balance
                                      </th>
                                      <th class="min-w-100px">Description</th>

                                      <th class="min-w-70px ">Status</th>
                                      <th class="min-w-100px text-end">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {Orguselistdata?.map((item) => (
                                      <tr>
                                        <td>
                                          <div class="d-flex align-items-center justify-content-center">
                                            {/* <div class="symbol symbol-45px me-5">
                                              <img
                                                src={item?.customers?.IDphoto}
                                                alt=""
                                              />
                                            </div> */}
                                            <div class="d-flex justify-content-center flex-column">
                                              <a
                                                href="#"
                                                class="usertitle Acolor mb-2"
                                              >
                                                {" "}
                                                {item?.customers?.fullname}
                                              </a>
                                              {/* <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-phone-volume"></i>
                                                </span>
                                                +{item?.customers?.phone}
                                              </span> */}

                                              {/* <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-envelope"></i>
                                                </span>
                                                {item?.customers?.email}{" "}
                                              </span> */}
                                            </div>
                                          </div>
                                        </td>

                                        <td className="text-center">
                                          {item?.customers?.digitalrefID}
                                        </td>
                                        <td className="text-center">
                                          {item?._id ? item._id.slice(16) : ""}
                                        </td>
                                        <td className="text-center">
                                          {moment(item.createdAt).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {item?.loanAmount ||
                                            item?.amount ||
                                            item?.totalAmount}
                                        </td>
                                        {_id == "Shares" && (
                                          <td className="text-center">
                                            {item?.sharePrice}
                                          </td>
                                        )}
                                        {_id == "Shares" && (
                                          <td className="text-center">
                                            {item?.quantity}
                                          </td>
                                        )}
                                        <td className="text-center">
                                          {item?.interestRate || 0}
                                        </td>
                                        <td className="text-center">
                                          {item?.totalInterestAmount ||
                                            item?.totalInterestReceived?.toFixed(
                                              2
                                            ) ||
                                            0}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{
                                            color:
                                              item?.accountBalance?.includes(
                                                "Credit"
                                              )
                                                ? "green"
                                                : item?.accountBalance?.includes(
                                                    "Debit"
                                                  )
                                                ? "red"
                                                : "black",
                                          }}
                                        >
                                          {item?.accountBalance || ""}
                                        </td>

                                        <td className="text-center">
                                          {item?.description || 0}
                                        </td>
                                        <td className="text-center">
                                          <span class="badge badge-light-info fs-5 capitalize-text">
                                            {"Confirmed"}
                                          </span>
                                        </td>

                                        {/* <td data-order="Invalid date">
                                          <div class="d-flex justify-content-end flex-shrink-0">
                                            <a
                                              href={`/customer-view-admin/${item._id}`}
                                              class="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span class="svg-icon svg-icon-3">
                                                <i class="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}

                                        <td>
                                          <button
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              navigate(
                                                `/transactions-invoice`,
                                                {
                                                  state: {...item,TypeByInvoice:_id},
                                                }
                                              );
                                            }}
                                          >
                                            <i className="bi bi-printer me-1"></i>
                                            Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2 text-end">
                              <ReactPaginate
                                PreviousLabel={"Previous"}
                                NextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item mx-2"}
                                pageLinkClassName={"page-link"}
                                PreviousClassName={"page-item"}
                                PreviousLinkClassName={"page-link pagestyle"}
                                NextClassName={"page-item"}
                                NextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="finance" role="tabpanel">
                      <div className="row g-5 g-xl-8">
                        {/* <div className="col-lg-12">
                          <div className="card filter_bg rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                            <div className="card-body container-xxl pt-10 pb-8">
                              <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            Filter
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                          Organization Customers
                          </span>
                        </div>

                              <div className="d-flex flex-column">
                                <div className="d-lg-flex align-lg-items-center">
                                  <form onSubmit={findOrguser}>
                                    <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body w-xxl-850px  me-lg-10 my-5">
                                      <div className="row flex-grow-1 mb-5 mb-lg-0 h-lg-60px">
                                        <div className="col-lg-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                                          <label className="small_label">
                                            Start Date
                                          </label>
                                          <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                            <i className="fad fa-calendar fs-2"></i>
                                          </span>
                                          <input
                                            type="date"
                                            className="form-control unstyled form-control-flush flex-grow-1"
                                            name="fromDate"
                                            placeholder="User Name.."
                                          />
                                          <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                        </div>

                                        <div className="col-lg-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                                          <label className="small_label">
                                            End Date
                                          </label>
                                          <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                            <i className="fad fa-calendar fs-2"></i>
                                          </span>
                                          <input
                                            type="date"
                                            className="form-control unstyled form-control-flush flex-grow-1"
                                            name="toDate"
                                            placeholder="User Name.."
                                          />
                                        </div>

                                        <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                          <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                          <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                x="2"
                                                y="2"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                              <rect
                                                opacity="0.3"
                                                x="13"
                                                y="2"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                              <rect
                                                opacity="0.3"
                                                x="13"
                                                y="13"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                              <rect
                                                opacity="0.3"
                                                x="2"
                                                y="13"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>
                                          <select
                                            className="form-select border-0 flex-grow-1"
                                            data-control="select2"
                                            data-placeholder="Status"
                                            data-hide-search="true"
                                            name="status"
                                          >
                                            <option value=""></option>
                                            <option
                                              value="1"
                                              selected="selected"
                                            >
                                              Category
                                            </option>
                                            <option value="pending">
                                              Pending
                                            </option>

                                            <option value="verified">
                                              verified
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div className="min-w-150px text-center">
                                        <button
                                          type="submit"
                                          className="btn btn-dark"
                                          id="kt_advanced_search_button_1"
                                        >
                                          Search
                                        </button>
                                        <button
                                          type="reset"
                                          className="btn btn-secondary me-2"
                                          id="kt_advanced_search_button_1"
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                              <h5>Payable Transactions List</h5>
                              <CSVLink
                                data={csvLoanPayData}
                                filename={"table_data.csv"}
                                className="btn btn-success mb-3"
                                target="_blank"
                              >
                                Export Data to CSV
                              </CSVLink>
                              {/* <div class="card-title">
                                <div class="d-flex align-items-center position-relative my-1">
                                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x="17.0365"
                                        y="15.1223"
                                        width="8.15546"
                                        height="2"
                                        rx="1"
                                        transform="rotate(45 17.0365 15.1223)"
                                        fill="currentColor"
                                      ></rect>
                                      <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <input
                                    type="text"
                                    data-kt-customer-table-filter="search"
                                    class="form-control form-control-solid w-250px ps-15"
                                    placeholder="Search Customers"
                                    onChange={(e) => setQuery(e.target.value)}
                                  />
                                </div>
                              </div> */}
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">
                                <table
                                  class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                  id="kt_customers_table"
                                >
                                  <thead>
                                    <tr class="fw-bold text-muted th-title text-center">
                                      {/* <th class="min-w-250px">ID Photo</th> */}
                                      <th class="min-w-250px text-center">Name</th>
                                      <th class="min-w-100px">
                                        Bruh-ID Ref Number
                                      </th>
                                      <th class="min-w-150px">Date</th>
                                      <th class="min-w-100px">Payment Type</th>
                                      <th class="min-w-100px">Amount</th>
                                      {_id == "Shares" && (
                                        <th class="min-w-100px">Share Price</th>
                                      )}
                                      {_id == "Shares" && (
                                        <th class="min-w-100px">
                                          Share Quantity
                                        </th>
                                      )}
                                      {/* <th class="min-w-100px">Interest Rate</th> */}
                                      {/* <th class="min-w-100px">Interest</th> */}
                                      <th class="min-w-100px">
                                        Account Balance
                                      </th>
                                      <th class="min-w-100px">Description</th>
                                      <th class="min-w-70px ">Status</th>
                                      {/* <th class="min-w-100px text-end">
                                        Actions
                                      </th> */}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {allData?.findLoanPayTrans?.map((item) => (
                                      <tr>
                                        <td>
                                          <div class="d-flex align-items-center justify-content-center">
                                            {/* <div class="symbol symbol-45px me-5">
                                              <img
                                                src={item?.customers?.IDphoto}
                                                alt=""
                                              />
                                            </div> */}
                                            <div class="d-flex justify-content-center flex-column">
                                              <a
                                                href="#"
                                                class="usertitle Acolor mb-2"
                                              >
                                                {" "}
                                                {item?.customers?.fullname}
                                              </a>
                                              {/* <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-phone-volume"></i>
                                                </span>
                                                +{item?.customers?.phone}
                                              </span>

                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-envelope"></i>
                                                </span>
                                                {item?.customers?.email}{" "}
                                              </span> */}
                                            </div>
                                          </div>
                                        </td>

                                        <td className="text-center">
                                          {item?.customers?.digitalrefID}
                                        </td>
                                        <td className="text-center">
                                          {moment(item.createdAt).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {item?.type || "Principle"}
                                        </td>

                                        {item?.type === "Interest" && (
                                          <td className="text-center">
                                            {item?.interestPayAmount?.toFixed() ||
                                              0}
                                          </td>
                                        )}

                                        {item?.type === "Principle" && (
                                          <td className="text-center">
                                            {item?.principalRepayment || 0}
                                          </td>
                                        )}

                                        {_id == "Shares" && (
                                          <td className="text-center">
                                            {item?.sharePrice}
                                          </td>
                                        )}
                                        {_id == "Shares" && (
                                          <td className="text-center">
                                            {item?.quantity}
                                          </td>
                                        )}
                                        {/* <td className="text-center">
                                          {item?.interestRate ||
                                            item?.interestPayAmount?.toFixed(
                                              2
                                            ) ||
                                            0}
                                        </td> */}
                                        {/* <td className="text-center">
                                          {item?.totalInterestAmount ||
                                            item?.totalInterestReceived?.toFixed(
                                              2
                                            ) ||
                                            item?.interestPayAmount?.toFixed(
                                              2
                                            ) ||
                                            0}
                                        </td> */}
                                        <td
                                          className="text-center"
                                          style={{
                                            color:
                                              item?.accountBalance?.includes(
                                                "Credit"
                                              )
                                                ? "green"
                                                : item?.accountBalance?.includes(
                                                    "Debit"
                                                  )
                                                ? "red"
                                                : "black",
                                          }}
                                        >
                                          {item?.accountBalance || ""}
                                        </td>
                                        <td className="text-center">
                                          {item?.description || 0}
                                        </td>
                                        <td className="text-center">
                                          <span class="badge badge-light-info fs-5 capitalize-text">
                                            {"Confirmed"}
                                          </span>
                                        </td>

                                        {/* <td data-order="Invalid date">
                                          <div class="d-flex justify-content-end flex-shrink-0">
                                            <a
                                              href={`/customer-view-admin/${item._id}`}
                                              class="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span class="svg-icon svg-icon-3">
                                                <i class="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2 text-end">
                              <ReactPaginate
                                PreviousLabel={"Previous"}
                                NextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item mx-2"}
                                pageLinkClassName={"page-link"}
                                PreviousClassName={"page-item"}
                                PreviousLinkClassName={"page-link pagestyle"}
                                NextClassName={"page-item"}
                                NextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="interestDistribution" role="tabpanel">
                      <div className="row g-5 g-xl-8">
                     

                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                              <h5>Interest Distribution Data</h5>
                              <CSVLink
                                data={interestDistributionCSV}
                                filename={"table_data.csv"}
                                className="btn btn-success mb-3"
                                target="_blank"
                              >
                                Export Data to CSV
                              </CSVLink>
                              {/* <div class="card-title">
                                <div class="d-flex align-items-center position-relative my-1">
                                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x="17.0365"
                                        y="15.1223"
                                        width="8.15546"
                                        height="2"
                                        rx="1"
                                        transform="rotate(45 17.0365 15.1223)"
                                        fill="currentColor"
                                      ></rect>
                                      <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <input
                                    type="text"
                                    data-kt-customer-table-filter="search"
                                    class="form-control form-control-solid w-250px ps-15"
                                    placeholder="Search Customers"
                                    onChange={(e) => setQuery(e.target.value)}
                                  />
                                </div>
                              </div> */}
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">
                                <table
                                  class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                  id="kt_customers_table"
                                >
                                  <thead>
                                    <tr class="fw-bold text-muted th-title text-center">
                                      <th class="min-w-250px text-center">Name</th>
                                      <th class="min-w-100px">
                                        Bruh-ID Ref Number
                                      </th>
                                      {/* <th class="min-w-150px">Date</th> */}
                                      <th class="min-w-150px text-center">From Date</th>
                                      <th class="min-w-150px text-center">To Date</th>
                                      <th class="min-w-100px text-center">Interest Amount</th>
                                      <th class="min-w-100px text-center">Saving Amount</th>
                                      {_id == "Shares" && (
                                        <th class="min-w-100px text-center">Share Price</th>
                                      )}
                                      {_id == "Shares" && (
                                        <th class="min-w-100px">
                                          Share Quantity
                                        </th>
                                      )}
                                      {/* <th class="min-w-100px">Interest Rate</th> */}
                                      {/* <th class="min-w-100px">Interest</th> */}
                                      <th class="min-w-100px">
                                        Account Balance
                                      </th>
                                      <th class="min-w-100px text-center">Description</th>
                                      <th class="min-w-70px text-center ">Status</th>
                                      {/* <th class="min-w-100px text-end">
                                        Actions
                                      </th> */}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {interstDistributionData?.map((item) => (
                                      <tr>
                                        <td>
                                          <div class="d-flex align-items-center justify-content-center">
                                           
                                            <div class="d-flex justify-content-center flex-column">
                                              <a
                                                href="#"
                                                class="usertitle Acolor mb-2"
                                              >
                                                {" "}
                                                {item?.fullname}
                                              </a>
                                              
                                              
                                            </div>
                                          </div>
                                        </td>

                                        <td className="text-center">
                                          {item?.digitalrefID || "N/A"}
                                        </td>
                                        <td className="text-center">
                                          {moment(item.fromDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {moment(item.toDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {item?.IntrestAmount?.toFixed(2) || 0}
                                        </td>
                                        <td className="text-center">
                                          {item?.totalAmount?.toFixed(2) || 0}
                                        </td>

                                    
                                        {/* <td className="text-center">
                                          {item?.interestRate ||
                                            item?.interestPayAmount?.toFixed(
                                              2
                                            ) ||
                                            0}
                                        </td> */}
                                        {/* <td className="text-center">
                                          {item?.totalInterestAmount ||
                                            item?.totalInterestReceived?.toFixed(
                                              2
                                            ) ||
                                            item?.interestPayAmount?.toFixed(
                                              2
                                            ) ||
                                            0}
                                        </td> */}
                                        <td
                                          className="text-center"
                                          style={{
                                            color:
                                              item?.accountBalance?.includes(
                                                "Credit"
                                              )
                                                ? "green"
                                                : item?.accountBalance?.includes(
                                                    "Debit"
                                                  )
                                                ? "red"
                                                : "black",
                                          }}
                                        >
                                          {item?.accountBalance || ""}
                                        </td>
                                        <td className="text-center">
                                          {item?.description || 0}
                                        </td>
                                        <td className="text-center">
                                          <span class="badge badge-light-info fs-5 capitalize-text">
                                            {"Confirmed"}
                                          </span>
                                        </td>

                                        {/* <td data-order="Invalid date">
                                          <div class="d-flex justify-content-end flex-shrink-0">
                                            <a
                                              href={`/customer-view-admin/${item._id}`}
                                              class="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span class="svg-icon svg-icon-3">
                                                <i class="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2 text-end">
                              <ReactPaginate
                                PreviousLabel={"Previous"}
                                NextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item mx-2"}
                                pageLinkClassName={"page-link"}
                                PreviousClassName={"page-item"}
                                PreviousLinkClassName={"page-link pagestyle"}
                                NextClassName={"page-item"}
                                NextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                    <div className="tab-pane fade" id="account" role="tabpanel">
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h3 className="fw-bold my-2">
                            User Bank Documents
                            <span className="fs-6 -400 fw-semibold ms-1"></span>
                          </h3>
                        </div>

                        <div className="row mt-4 text-hover-primary">
                          {/* {account.map((item) => (
                            <div className="col-lg-3 basicdetail">
                              <div className="carduser animestion-bank">
                                <div className="text-center">
                                  <Link
                                    to={`/custmer-bank-detail/${item._id}/${custID} `}
                                    className="img_div"
                                  >
                                 
                                    <i class="fad fa-university fs-45"></i>
                                  </Link>
                                  <a
                                    href="../assets_new/images/largepreview.png"
                                    className="viewimg1"
                                  >
                                
                                  </a>
                                </div>
                                <div className="d-flex justify-content-between p-3">
                                  <h5>{item.Bankname}</h5>
                                  <div>
                                    <a href="#" className="ml-2">
                                      <i
                                        className="fad fa-university"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Created by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={() => setshow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>DOCUMENT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <img src={photo} alt="document" style={{ width: "100%" }} />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={popupVisible}
            onHide={() => setPopupVisible(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Opening Balance Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                This account does not have an opening balance. Please enter one
                to proceed.
              </p>
              <Form>
                <Form.Group className="mb-3" controlId="openingBalance">
                  <Form.Label>Enter Opening Balance</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter opening balance"
                    value={openingBalance}
                    onChange={(e) => setOpeningBalance(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setPopupVisible(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleAddBalance}>
                Add Balance
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <Modal show={profile_View} onHide={() => setprofile_View(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <img src={data.IDphoto} className="profile_photo" alt="profile photo"/>
              </div>
            </Modal.Body>
          </Modal> */}
        </div>
      </div>
    </>
  );
};

export default AccountDetail;
