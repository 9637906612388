


import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../../Organization/Header";
import Sidebar from "../../Organization/Sidebar";

const OrgPrivatedRouting = () => {
  const isAuthenticated = !!localStorage.getItem("organizationToken"); // or use your own authentication logic
  const location = useLocation();

  return isAuthenticated ?
  
  <>
<Header/>

  <Outlet /> 
  <Sidebar/>
  
  </>
  : <Navigate to="/loginorg" />;
};
export default OrgPrivatedRouting;
