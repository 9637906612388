import React, { useState } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Header from "./Header";
import Sidebar from "./Sidebar";
import PhoneInput from "react-phone-input-2";
import { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function AdminEdit() {
  const { _id } = useParams();
   let token = localStorage.getItem("organizationToken");
  const navigate = useNavigate();
  const [Image, setImage] = useState();
  const [data, setData] = useState();
  const [phone, setPhone] = useState('');
  console.log("12345" + { phone })
  const userlist = () => {
    const { adminID } = { adminID: _id }
    axios.post(`/v1/org/Agent/view-admin-detail`, { adminID }, { headers: { "Authorization": `Bearer ${token}` } })
      .then(resp => {
        let data = resp.data.data;
        let number = data.phone
        setPhone(number)

        setData(data);
        console.log({ data, number });
      })

  }
  const handlerimage = (data) => {
    console.log("image data", data)
    const token = localStorage.getItem("token");
    let formdata = new FormData
    formdata.append('image', data)
    console.log("image first datav ", formdata)
    axios.post(`/v1/DID/ImageUploader`, formdata, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const imgurl = res.data.data
        console.log(" imagr url ", imgurl)
        setImage(imgurl)




      }).catch()

  }



  const AddFormData = async (e) => {







    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formdata = Formvlaues;
    console.log(Formvlaues);
    // let dataToSend2 = new FormData();
    // dataToSend2.append("Image", Image);
    // dataToSend2.append("firstName", Formvlaues.firstName);
    // dataToSend2.append("lastName", Formvlaues.lastName);
    // dataToSend2.append("phone", phone);
    // dataToSend2.append("email", Formvlaues.email);
    // dataToSend2.append("address", Formvlaues.address);
    // dataToSend2.append("city", Formvlaues.city);
    // dataToSend2.append("password", Formvlaues.password);
    // dataToSend2.append("confPassword", Formvlaues.confPassword);
    axios.post(`/v1/org/Agent/update-admin`, formdata, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {

        let data = res.data;
        if (data) {

          toast.success(data.msg);
           navigate("/Viewadmin");

        } else {
          toast.error(data.msg);
        }

      });
  };



  useEffect(() => {
    userlist();
    
  }, [])

  return (
    <>

      <ToastContainer />
      <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div className="container-xxl" >
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Edit Admin Profile</h3>
                    </div>
                  </div>
                  <div id="kt_account_settings_profile_details" className="collapse show">
                    <form id="kt_account_profile_details_form" className="form" onSubmit={AddFormData} >
                      <div className="card-body border-top p-9">

                        {/* <div class="row mb-6">
                        
                        <label class="col-lg-4 col-form-label fw-semibold fs-6">ID Photo</label>
                        
                        
                        <div class="col-lg-8">
                         
                          <div class="image-input image-input-outline" data-kt-image-input="true" 
                          
                          style={{
                            backgroundImage:
                              "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                          }}
                          
                          >
                        
                         
                            <div class="image-input-wrapper w-125px h-125px" 

                
                            
                            style={{
                            backgroundImage:
                              "url('/metronic8/demo4/assets/media/avatars/300-1.jpg')",
                          }}>
                            </div>
                          
                            
                            <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Upload Image">
                              <i class="bi bi-pencil-fill fs-7"></i>
                         
                              <input type="file" name="Image" accept=".png, .jpg, .jpeg" 
                              
                              onChange={(e)=>handlerimage(e.target.files[0])}
                              />
                              <input type="hidden" name="avatar_remove" />
                             
                            </label>
                            
                       
                            <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                              <i class="bi bi-x fs-2"></i>
                            </span>
                            
                            <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                              <i class="bi bi-x fs-2"></i>
                            </span>
                          
                          </div>
                        
                          <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
                          
                        </div>
                        
                      </div> */}


                        {/* {/ <input type="file" name="image"  /> /} */}


                        <div className="row mb-6">

                          <label className="col-lg-4 col-form-label fw-semibold fs-6">First Name</label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">
                                <input type="hidden" name="adminId" defaultValue={data?._id} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Full Name" />
                                <input type="text" name="firstName" defaultValue={data?.firstName} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First Name" />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row mb-6">

                          <label className="col-lg-4 col-form-label fw-semibold fs-6">Last Name</label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">

                                <input type="text" name="lastName" defaultValue={data?.lastName} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Last Name" />
                              </div>
                            </div>
                          </div>

                        </div>






                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="required">Contact No.</span>
                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              inputProps={{
                                name: "phone",
                                required: true,

                                 value:phone,

                              }}
                              //  value={phone}
                              onChange={(phone) => setPhone(phone)}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Contact No."
                            //  defaultValue={phone}
                            />


                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">Email Address</label>
                          <div className="col-lg-8 fv-row">
                            <input type="email" name="email" defaultValue={data?.email} className="form-control form-control-lg form-control-solid" placeholder="Email Address" />
                          </div>
                        </div>

                        {/* <div className="row mb-6">
                                         <label className="col-lg-4 col-form-label  fw-semibold fs-6">Gender</label>
                                         <div className="col-lg-8 fv-row">
                                             <div className="d-flex align-items-center mt-3">

                                                 <label className="form-check form-check-inline form-check-solid me-5">
                                                     <input className="form-check-input" name="gender" type="radio" value="Male"  />
                                                     <span className="fw-semibold ps-2 fs-6">Male</span>
                                                 </label>
                                                 <label className="form-check form-check-inline form-check-solid">
                                                     <input className="form-check-input" name="gender" type="radio" value="Fmale"   />
                                                     <span className="fw-semibold ps-2 fs-6">Female</span>
                                                 </label>
                                             </div>
                                         </div>
                                     </div> */}






                        {/* <AutoAddress style={{width : "100% !important"}} /> */}

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">Address</label>
                          <div className="col-lg-8 fv-row">
                            <input type="text" name="address" defaultValue={data?.address} className="form-control form-control-lg form-control-solid" placeholder="Address" />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">City</label>
                          <div className="col-lg-8 fv-row">
                            <input type="text" name="city" defaultValue={data?.city} className="form-control form-control-lg form-control-solid" placeholder="city" />
                          </div>
                        </div>
                        {/* <div className="row mb-6">
                                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">Password</label>
                                                    <div className="col-lg-8 fv-row">
                                                        <input type="text" name="password" className="form-control form-control-lg form-control-solid" placeholder="Password" />
                                                    </div>
                                                </div>
                                                <div className="row mb-6">
                                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">Confirm Password</label>
                                                    <div className="col-lg-8 fv-row">
                                                        <input type="text" name="confPassword" className="form-control form-control-lg form-control-solid" placeholder="Confirm Password" />
                                                    </div>
                                                </div> */}



                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">

                        <button type="submit" className="btn btn-primary btn-sub me-2">Update</button>


                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}


export default AdminEdit