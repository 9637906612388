import { useNavigate, useParams, useLocation } from "react-router-dom";
import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
var jwt = require("jsonwebtoken");

export default function OrgProductStoreManagementList() {
  const navigate = useNavigate();
  let token = localStorage.getItem("organizationToken");
  const { _id } = useParams();
  const custId = _id;
  const [pageCount, setpageCount] = useState("");

  const [total, settotal] = useState([]);
  const [ShowPassword, setShowPassword] = useState(false);

  const [ProductDetails, setProductDetails] = useState([]);
  const [productID, setProductID] = useState("");
  const [userCartCount, setUserCartCount] = useState("");
  const [userinfo, setUserinfo] = useState([]);

  const [quantities, setQuantities] = useState({});

  const updateQuantityFunction = (itemId, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemId]: Math.max(0, newQuantity),
    }));
  };

  const updateQuantity = (itemId, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemId]: Math.max(0, newQuantity),
    }));
  };

  const limit = 12;

  const ProductList = async () => {
    await axios
      .post(
        `/v1/org/CRM/org-list-product`,
        { page: 1, limit: 12 },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        console.log(data);
        let filter = data.data;
        setProductDetails(filter?.list);

        const total = resp.data?.data?.count;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        console.log("data===== filter", filter, total);
      });
  };

  const VeriflyAfterDelete = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;
      if (data.status) {
        handleDeleteProduct();
        setShowPassword(false);
      }
    } catch (error) {}
  };

  //----------------------------user-detail---------------------------------------------------------------------------------

  const custumerDetails = () => {
    axios
      .post(
        `/v1/org/CRM/get-super_agent-details`,
        { agentId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data?.list;
        setUserinfo(data);

        console.log("data===== filter", data);
      });
  };
  //----------------------------user-detail---------------------------------------------------------------------------------

  const cartCount = () => {
    axios
      .post(
        `/v1/org/CRM/superagent-cart-item-count`,
        { superAgentId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        setUserCartCount(data);
      });
  };

  const addToCart = async (Product) => {
    const quantity = quantities[Product] || 0;

    axios
      .post(
        `/v1/org/CRM/superagent-add-to-cart`,
        { productId: Product, superAgentId: _id, quantity },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        let data = resp.data;
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            cartCount();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const fetchComments = async (page) => {
    await axios
      .post(
        `/v1/org/CRM/org-list-product`,

        { page: page, limit: 12 },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        let filter = data.data;
        setProductDetails(filter?.list);

        const total = resp.data?.data?.count;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        console.log("data===== filter", filter, total);
      });
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  ///////////////pagenestion///////////////

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/org/CRM/org-list-product`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        let data = resp.data;
        let filter = data.data;
        setProductDetails(filter?.list);

        const total = resp.data?.data?.count;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        console.log("data===== filter", filter, total);
      });
  };

  /////////////unblocked user api call ////////////

  /////////////blocked user api call ////////////

  useEffect(() => {
    ;
    ProductList();
    custumerDetails();
    cartCount();
  }, []);

  const handleDeleteProduct = () => {
    let productId = productID;

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/v1/super-Agent/product-delete`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { productId },
          })
          .then((response) => {
            if (response?.data?.status) {
              Swal.fire(
                "Deleted!",
                "Product has been deleted successfully",
                "success"
              );
              ProductList();
            }
          });
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card rounded-15 mb-12 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                  <div className="card-body  pt-9 pb-0 ">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="me-2 mb-1">
                        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                          <img
                            //   onClick={() => setprofile_View(true)}
                            src={
                              userinfo?.Image ||
                              "/assets_new/images/lodingwallet.gif"
                            }
                            alt="image"
                          />
                          <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                        </div>
                      </div>

                      <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                              <a
                                href="#"
                                className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                              >
                                {userinfo?.firstName || ""}
                              </a>
                              <a href="#">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                                      fill="white"
                                    />
                                  </svg>
                                </span>
                              </a>
                            </div>

                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                              <a
                                href="#"
                                className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                              >
                                <span className="svg-icon svg-icon-4 me-1">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                      fill="currentColor"
                                    />
                                    <rect
                                      x="7"
                                      y="6"
                                      width="4"
                                      height="4"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {userinfo?.professoin ||"Super Agent"}
                              </a>
                              <a
                                href="#"
                                className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                              >
                                <span className="svg-icon svg-icon-4 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {userinfo?.country}
                              </a>
                              <a
                                href="#"
                                className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                              >
                                <span className="svg-icon svg-icon-4 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {userinfo?.email}
                              </a>
                            </div>
                          </div>
                        </div>

                   
                      </div>
                    
                      <div className="card-title m-0 me-2">
                        <button
                          type="button"
                          onClick={() => navigate(`/Org/add/products`)}
                          className="btn btn-success btn-sm d-flex align-items-center"
                          id="kt_advanced_search_button_1"
                        >
                          <i className="fas fa-plus me-1"></i> Create New
                          Product
                        </button>
                      </div>

                      <div className="card-title m-0 me-2">
                        <button
                          onClick={(e) => {
                            navigate(`/Org/My/cartlist/${custId}`);
                          }}
                          className="btn btn-primary btn-sm position-relative"
                          style={{
                            borderColor: "#28a745",
                            overflow: "visible",
                          }}
                        >
                          <span className="d-flex align-items-center">
                            <i className="fas fa-cart-plus me-1"></i> Go To Cart
                          </span>
                          {userCartCount >= 0 && (
                            <span
                              className="badge bg-secondary text-dark"
                              style={{
                                position: "absolute",
                                top: "-8px",
                                right: "-5px",
                                fontSize: "0.9em",
                                borderRadius: "50%",
                                padding: "4px 6px",
                                border: "2px solid orange", // Green circular border
                              }}
                            >
                              {userCartCount}
                            </span>
                          )}
                        </button>
                      </div>
                      <div className="card-title m-0 me-2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                style={{ marginTop: "2rem" }}
              >
                <form onSubmit={(e) => formsave(e)}>
                  <div className="rounded d-flex flex-column col-md-6 flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-5 ms-5 my-5">
                    <div className="row flex-grow-1 mb-5 mb-lg-0">
                      <div className="col-lg-7  col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>

                        <select
                          className="form-select  flex-grow-1"
                          data-placeholder="Please Select Category"
                          data-hide-search="true"
                          name="search"
                          required
                        >
                          <option value="" selected disabled>
                            Please Select Category
                          </option>
                          <option value="Seeds">Seeds</option>
                          <option value="Fertilizer">Fertilizer</option>
                          <option value="chemicals">Chemicals</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-sm-6 d-flex align-items-center gap-1">
                        <button
                          type="submit"
                          className="btn btn-dark  mx-sm-3"
                          id="kt_advanced_search_button_1"
                        >
                          Search
                        </button>
                        <button
                          type="reset"
                          onClick={() => ProductList()}
                          className="btn btn-secondary"
                          id="kt_advanced_search_button_1"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="card-body py-3">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                  {ProductDetails?.map((item, index) => (
                    <div className="col mb-4" key={index}>
                      <div
                        className="card h-100"
                        style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                      >
                        <img
                          src={item?.image}
                          className="card-img-top"
                          alt="product Image"
                          style={{ objectFit: "cover", height: "200px" }}
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">{item?.productName}</h5>
                          <p className="card-text">
                            <span style={{ fontWeight: "bold" }}>
                              Price: {item?.pricePerKg} ETB Per{" "}
                              {item?.unit || "KG"}
                            </span>
                          </p>
                          <p className="card-text">
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              Available Quantity : {item?.availableQuantity}
                            </span>
                          </p>
                          <p className="card-text">
                            <span
                              style={{ fontWeight: "bold", color: "#6c757d" }}
                            >
                              Brand: {item?.companyName}
                            </span>
                          </p>
                          <div className="row g-2">
                            <div className="col-md-12">
                              <div className="row d-flex justify-content-center">
                                <button
                                  className="btn btn-secondary btn-sm col-3"
                                  onClick={() =>
                                    updateQuantityFunction(
                                      item._id,
                                      (quantities[item._id] || 0) - 1
                                    )
                                  }
                                >
                                  <i className="fas fa-minus"></i>
                                </button>
                                <input
                                  type="number"
                                  value={quantities[item._id] || 0}
                                  onChange={(e) =>
                                    updateQuantityFunction(
                                      item._id,
                                      parseInt(e.target.value)
                                    )
                                  }
                                  style={{
                                    width: "50px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                  className="col-4 mx-3"
                                />
                                <button
                                  className="btn btn-secondary btn-sm col-3"
                                  onClick={() =>
                                    updateQuantity(
                                      item._id,
                                      (quantities[item._id] || 0) + 1
                                    )
                                  }
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="col-md-12 d-flex align-item-center justify-content-center">
                              <button
                                onClick={(e) => {
                                  addToCart(item._id);
                                }}
                                className="btn btn-primary btn-sm position-relative "
                                style={{
                                  borderColor: "#28a745",
                                  overflow: "visible",
                                }}
                              >
                                <span className="d-flex align-items-center">
                                  <i className="fas fa-cart-plus me-1"></i> Add
                                  to Cart
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="col-lg-12 mt-2 text-end">
                  <ReactPaginate
                    PreviousLabel={"Previous"}
                    NextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-end gap-2"}
                    pageClassName={"page-item mx-2"}
                    pageLinkClassName={"page-link"}
                    PreviousClassName={"page-item"}
                    PreviousLinkClassName={"page-link pagestyle"}
                    NextClassName={"page-item"}
                    NextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-center"
        show={ShowPassword}
        onHide={() => setShowPassword(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VeriflyAfterDelete}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Verify
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
