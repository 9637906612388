import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../../Admin/Header";
import Sidebar from "../../Admin/Sidebar";

const PrivateRoute = () => {
  const isAuthenticated = !!localStorage.getItem("ChromePayAdmitToken"); // or use your own authentication logic
  const location = useLocation();

  return isAuthenticated ? (
    <>
      <Header />
      <Outlet />
      <Sidebar />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
