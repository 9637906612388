import React from "react";
import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import { CSVLink } from "react-csv";
import SearchComponent from "../../SuperAgent/Products/SearchComponent";
const StroreFrontTrans = () =>  {
    const navigate = useNavigate();
    const { _id } = useParams();
    let token = localStorage.getItem("ChromePayAdmitToken");
  
    const [pageCount, setpageCount] = useState("");
  
    const [total, settotal] = useState([]);
    const [ShowPassword, setShowPassword] = useState(false);
  
    const [ProductDetails, setProductDetails] = useState([]);
    const [productID, setProductID] = useState('');
    const [cooperativeFilter, setCooperativeFilter] = useState([]);
    const [ShowLoader, setShowLoader] = useState(true);
    const [csvData, setCSVData] = useState([]);
  
  
    const limit = 10;
  
  
    const cooperativeFilterlist = async () => {
      setShowLoader(true)
  
      await axios.post(`/v1/super-Agent/get-cooperative-for-filter`, { page: 1, limit: 10 },
        { headers: { Authorization: `Bearer ${token}` } }).then((resp) => {
          const data = resp.data.getCooperative;
          console.log("-->", data)
          setCooperativeFilter(data)
  
  
        });
    };
    useEffect(() => {
      cooperativeFilterlist();
    }, []);
  
    const ProductList = async () => {
      setShowLoader(true)
      await axios
        .post(
          `/v1/super-Agent/get-admin-transaction`,
          { page: 1, limit: 10},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((resp) => {
          let data = resp.data;
          console.log(
            "data===== ActiveCustomer pending list totalPagetotalPagetotalPagetotalPagetotalPagetotalPage",
            data.data
          );
          let filter = data.data;
          const csvData = resp.data.data.csvData;
          setProductDetails(filter?.list);
          setCSVData(csvData)
  
          const total = resp.data?.data?.count;
          settotal(total);
          setFormData({
            search: "",
            category: "",
            superAgent: "",
            organization: ""
          });
  
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          setShowLoader(false)
  
          console.log("data===== filter", filter, total);
        });
    };
  
    const VeriflyAfterDelete = async (e) => {
      e.preventDefault();
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = Formvlaues;
      try {
        const response = await axios.post(
          `/v1/super-Agent/match-agent-pass`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        let data = response.data;
        if (data.status) {
          handleDeleteProduct()
          setShowPassword(false);
        }
      } catch (error) { }
    };
  
    const fetchComments = async (page) => {
  
      setShowLoader(true)
      await axios
        .post(
          `/v1/super-Agent/get-admin-transaction`,
  
          { page: page, limit: 10, ...formData },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((resp) => {
          let data = resp.data;
          let filter = data.data;
          setProductDetails(filter?.list);
  
          const total = resp.data?.data?.count;
          settotal(total);
          setFormData({
            search: "",
            category: "",
            superAgent: "",
            organization: ""
          });
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          setShowLoader(false)
          console.log("data===== filter", filter, total);
  
        });
    };
  
    const handlePageClick = async (data) => {
      const page = data.selected + 1;
      const commentsFormServer = await fetchComments(page);
  
    };
  
  
    ///////////////pagenestion///////////////
  
    const formsave = (e, page) => {
      setShowLoader(true)
  
      e.preventDefault();
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = Formvlaues;
      console.log("Formvlaues === ", Formvlaues);
      axios
        .post(`/v1/super-Agent/get-admin-transaction`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          let data = resp.data;
          let filter = data.data;
          setProductDetails(filter?.list);
  
          const total = resp.data?.data?.count;
          settotal(total);
  
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          setShowLoader(false)
  
          console.log("data===== filter", filter, total);
        });
    };
  
    /////////////unblocked user api call ////////////
  
    /////////////blocked user api call ////////////
  
    useEffect(() => {
        ;
      ProductList()
      
    }, []);
  
  
  
    const handleDeleteProduct = () => {
  
      let productId = productID;
  
  
  
  
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this product?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/v1/super-Agent/product-delete`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { productId }
          })
            .then((response) => {
              if (response?.data?.status) {
                Swal.fire("Deleted!", "Product has been deleted successfully", "success");
                ProductList();
              }
  
  
  
            })
  
        }
      });
  
  
    };
  
  
  
    const [formData, setFormData] = useState({
      search: "",
      category: "",
      superAgent: "",
      organization: "",
    });
  
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const resetForm = () => {
      // setFormData({
      //   search: "",
      //   category: "",
      //   superAgent: "",
      //   organization: ""
      // });
      ProductList(); 
      fetchComments();
    };
  
  
    return (
      <>
        <ToastContainer />
        <CustomerLoader loader={ShowLoader} />
  
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div
                    className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                    style={{ marginTop: "2rem" }}
                  >
                    <div className="card-body container-xxl pt-10 pb-8">
                      <div className="d-flex align-items-center">
                        <h1 className="fw-semibold me-3 text-white">Filter</h1>
                        <span className="fw-semibold text-white opacity-50">
                          Transactions List
                        </span>
                      </div>
                      <form onSubmit={(e) => formsave(e)}>
                        <div className="rounded d-flex flex-column col-md-12 flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                          <div className="row flex-grow-1 mb-5 mb-lg-0">
                            <SearchComponent lgColSize = {2}/>
                            <div className="col-lg-2 d-flex align-items-center mb-5 mb-lg-0">
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              <select
                                name="category"
                                className="form-select border-0 flex-grow-1"
                                data-control="select2"
                                data-placeholder="orgName"
                                data-hide-search="true"
                                value={formData.category}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Select Category
                                </option>
                                <option value="Seeds">Seeds</option>
                                <option value="Fertilizer">Fertilizer</option>
                                <option value="chemicals">Chemicals</option>
                                <option value="Veterinary Drugs">Veterinary Drugs</option>
                                <option value="mechanazaionService">Mechanazaion Service</option>
                                <option value="technology">Technology</option>
                              </select>
                            </div>
  
                            <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              <select
                                name="superAgent"
                                className="form-select border-0 flex-grow-1"
                                data-control="select2"
                                data-placeholder="superAgent"
                                data-hide-search="true"
                                value={formData.superAgent}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Select Super Agent
                                </option>
                                {cooperativeFilter?.map((item) => (
                                  <option key={item._id} value={item._id}>
                                    {item?.firstName}
                                  </option>
                                ))}
                              </select>
                            </div>
  
                            <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              <select
                                name="organization"
                                className="form-select border-0 flex-grow-1"
                                data-control="select2"
                                data-placeholder="organization"
                                data-hide-search="true"
                                value={formData.organization}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Select Organization
                                </option>
                                <option value="64f563b134c2ff8e4a84117a">
                                  Yenetsanet Fana Saving and Credit Cooperatives Union
                                </option>
                                <option value="65804231802e922f610eb2db">
                                  Kessem Multipurpose Union
                                </option>
                                <option value="668b9e579132244ba2768d31">
                                  Walta Farmers Cooperative Union
                                </option>
                              </select>
                            </div>
  
                            <div className="col-lg-2 col-sm-4 d-flex align-items-center gap-1 justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-dark mx-sm-3"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={ProductList}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
  
                    </div>
                  </div>
                </div>
  
                <div className="col-lg-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div class="card-header border-0 pt-6 d-flex align-items-center">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                          Transactions List
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7">
                          {" "}
                          Over {total} Transactions
                        </span>
                      </h3>
                      {/* <div class="card-title">
                          <button
                            onClick={() => navigate("/add/products")}
                            className="btn btn-light btn-active-light-primary me-2"
                          >
                            Add Product
                          </button>
                        </div> */}
                        <div className="text-end">
                         <CSVLink
          data={csvData}
          filename={"table_data.csv"}
          className="btn btn-success mb-3"
          target="_blank"
        >
          Export Data to CSV
        </CSVLink>
                      </div>
                    </div>
  
                    <div className="card-body py-3">
                      <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                          <thead>
                            <tr className="fw-bold text-muted th-title">
                              <th className="min-w-125px">Product Photo</th>
                              <th className="min-w-125px">Product Name</th>
                              <th className="min-w-125px">Customer D-ID</th>
                              <th className="min-w-125px">Product Category</th>
                              <th className="min-w-125px">
                                Product Price Per (Kg)
                              </th>
                              <th className="min-w-125px">
                                Super Agent
                              </th>
                              <th className="min-w-125px">
                                Organization
                              </th>
                              <th className="min-w-125px">
                                order Id
                              </th>
                              <th className="min-w-125px">
                                Quantity
                              </th>
                              <th className="min-w-125px">
                                Total Amount(ETB)
                              </th>
                              <th className="min-w-125px">
                                Chekout Date
                              </th>
  
                              <th className="min-w-100px text-center">Payment Status</th>
                            </tr>
                          </thead>
  
                          <tbody className="text-center">
                            {ProductDetails?.map((item) => (
                              <tr>
                                <td>
                                  {" "}
                                  <div class="d-flex align-items-center">
                                    <div class="symbol symbol-45px me-5">
                                      <img
                                        src={item?.product_detail?.image || "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/images%20%281%29.png"}
                                        alt="product Image"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/images%20%281%29.png"; // set fallback image
                                        }}
                                      />
                                    </div>
                                  </div>
  
                                </td>
                                <td className="fw-semibold">{item?.product_detail?.productName}</td>
                                <td className="fw-semibold">{item?.customerData?.digitalrefID || "Loading..."}</td>
                                <td className="fw-semibold">{item?.product_detail?.category}</td>
                                <td className="fw-semibold">{item?.product_detail?.pricePerKg}</td>
                                <td className="fw-semibold">{item?.superData?.firstName || item?.superData?.lastName}</td>
                                <td className="fw-semibold">{item?.superData?.orgData?.name}</td>
                                <td className="fw-semibold">{item?._id?.slice(14) || 2713645}</td>
                                <td className="fw-semibold">{item?.quantity}</td>
                                <td className="fw-semibold">{item?.amount}</td>
                                <td className="fw-semibold"> {Moment(item?.createdAt).format("DD/MM/YYYY")}</td>
  
  
  
                                <td>
                                  <div className="d-flex justify-content-center flex-shrink-0">
  
                                    <img style={{ height: "50px", width: "50px", marginRight: "40px" }} src="https://chromepaybucket.blr1.digitaloceanspaces.com/DID/11601272.png" />
  
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
  
                      <div className="col-lg-12 mt-2 text-end">
                        <ReactPaginate
                          PreviousLabel={"Previous"}
                          NextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-end gap-2"
                          }
                          pageClassName={"page-item mx-2"}
                          pageLinkClassName={"page-link"}
                          PreviousClassName={"page-item"}
                          PreviousLinkClassName={"page-link pagestyle"}
                          NextClassName={"page-item"}
                          NextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-center"
          show={ShowPassword}
          onHide={() => setShowPassword(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Verify Password</Modal.Title>
          </Modal.Header>
          <form onSubmit={VeriflyAfterDelete}>
            <Modal.Body>
              <div className="col-lg-12 ">
                <div className="row">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    {" "}
                    Password
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="password"
                      name="password"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Enter password"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit" className="center">
                {" "}
                Verify
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
  

export default StroreFrontTrans