import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerLoader from "../../CRM/Modal/CustomerLoder";

function AddCity() {
    let token = localStorage.getItem("SuperAgentChromepayToken");

    const [cities, setCities] = useState([]);
    const [citiesInfo, setcitiesInfo] = useState('');
    const [city, setCity] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isEditing, setIsEditing] = useState(null);
    const [editCity, setEditCity] = useState("");
    const [showLoader, setShowLoader] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        ;
    }, []);

    const handleAddCity = () => {
        const trimmedCity = city.trim().toLowerCase();
        if (trimmedCity === "") {
            toast.error("Please enter a valid city name");
        } else if (cities.includes(trimmedCity)) {
            toast.error("City already exists");
        } else {
            setCities([...cities, trimmedCity]);
            setCity("");
            setIsEditing(null);
        }
    };

    const handleRemoveCity = (cityName) => {
        const updatedCities = cities.filter((name) => name.toLowerCase() !== cityName.toLowerCase());
        setCities(updatedCities);
    };

    const handleEditCity = (cityName) => {
        setIsEditing(cityName);
        setEditCity(cityName);
    };

    const handleSaveEdit = () => {
        const trimmedEditCity = editCity.trim().toLowerCase();
        if (trimmedEditCity === "") {
            toast.error("Please enter a valid city name");
        } else if (cities.includes(trimmedEditCity) && isEditing !== trimmedEditCity) {
            toast.error("City already exists");
        } else {
            const updatedCities = cities.map((cityName) =>
                cityName === isEditing ? trimmedEditCity : cityName
            );
            setCities(updatedCities);
            setIsEditing(null);
            setEditCity("");
        }
    };

    const handleAddCityBySuperAgent = (e) => {
        e.preventDefault();
        axios.post(`v1/super-Agent/update-city`, { cities, cityId: citiesInfo?._id }, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((resp) => {
            let data = resp.data;
            if (resp.status) {
                toast.success(data.msg);
                fetchCityList();
            } else {
                toast.error(data.msg);
            }
        });
    };

    const fetchCityList = () => {
        setShowLoader(true);
        axios.post(`v1/super-Agent/get-super-city`, {}, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((resp) => {
            let data = resp.data?.data;
            if (resp.status) {
                setCities(data?.cities);
                setShowLoader(false);
                setcitiesInfo(data);
            } else {
                setShowLoader(false);
                toast.error(data.msg);
            }
        });
    };

    useEffect(() => {
        fetchCityList();
    }, []);

    return (
        <div>
           
            <CustomerLoader loader={showLoader} />
            <ToastContainer />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="container-xxl" id="kt_content_container">
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-12">
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card shadow">
                                        <div className="card-header d-flex align-items-center bg-primary text-white">
                                            <h3 className="mb-0 text-white">Manage Cities</h3>
                                        </div>
                                        <form onSubmit={handleAddCityBySuperAgent}>
                                            <div className="card-body">
                                                <div className="mb-4">
                                                    <label className="fw-bold mb-2">City Operations</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            value={city}
                                                            onChange={(e) => setCity(e.target.value)}
                                                            className="form-control"
                                                            placeholder="Enter City"
                                                        />
                                                        <button type="button" onClick={handleAddCity} className="btn btn-success">
                                                            <i className="fas fa-plus"></i> Add
                                                        </button>
                                                        <input
                                                            type="text"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            className="form-control ms-2"
                                                            placeholder="Search City"
                                                        />
                                                        <span className="input-group-text">
                                                            <i className="fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <h5 className="fw-bold mt-4">Added Cities</h5>
                                                    <ul className="list-group">
                                                        {cities?.length > 0 ? (
                                                            cities
                                                                .filter((cityName) =>
                                                                    cityName.toLowerCase().includes(searchTerm.toLowerCase())
                                                                )
                                                                .map((cityName, index) => (
                                                                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                                        {isEditing === cityName ? (
                                                                            <>
                                                                                <input
                                                                                    type="text"
                                                                                    value={editCity}
                                                                                    onChange={(e) => setEditCity(e.target.value)}
                                                                                    className="form-control me-2"
                                                                                />
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-outline-primary btn-sm"
                                                                                    onClick={handleSaveEdit}
                                                                                >
                                                                                    <i className="fas fa-save"></i> Save
                                                                                </button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {cityName}
                                                                                <div>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-secondary text-black  btn-sm me-2"
                                                                                        onClick={() => handleEditCity(cityName)}
                                                                                    >
                                                                                        <i className="fas fa-edit text-black"></i> Edit
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-danger btn-sm"
                                                                                        onClick={() => handleRemoveCity(cityName)}
                                                                                    >
                                                                                        <i className="fas fa-trash"></i> Remove
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </li>
                                                                ))
                                                        ) : (
                                                            <li className="list-group-item text-muted">No cities found</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-footer text-end">
                                                <button type="submit" className="btn btn-primary">
                                                    <i className="fas fa-save"></i> Save Cities
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCity;

