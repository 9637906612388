import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CustomerLoader from "./Modal/CustomerLoder";

var jwt = require("jsonwebtoken");

export default function SuperAgentCustomerBlock() {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgType = localStorage.getItem("orgType");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;

  const [custID, setcustID] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [notereason, setnotereason] = useState("");
  const [total, settotal] = useState([]);
  const [Query, setQuery] = useState("");

  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);
  const [paymenthandler, setpaymenthandler] = useState(false);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/super-Agent/block-customers`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        const total = resp.data.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const userlist = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/block-customers`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);

        const total = resp.data.totlaRow;
        settotal(total);
        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++++++++",
          data
        );
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    userlist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    axios
      .post(`/v1/super-Agent/block-customers`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  /////////////unblocked user api call ////////////

  const handleunblocked = (blocked, _id) => {
    let custId = _id;
    let status = blocked;
    let body = { custId: custId, status: status };
    axios
      .post(`/v1/super-Agent/block-unblock-user`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to UnBlock selected Customer ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, UnBlock it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              "Unblocked!",
              "Customer UnBlocked   successfully",
              "success"
            );
            userlist();
          }
        });
      })
      .catch();
  };

  /////////////blocked user api call ////////////
  const handlesuspend = (_id) => {
    const { ID } = { ID: _id };
    Swal.fire({
      title: "Reason for blocking ",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Ok Block",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return axios
          .post(
            `/v1/org/ActiveDID/Block-cust/${ID}`,
            { note: login },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            Swal.fire({
              title: "Are you sure?",
              text: "Are you sure you want to block selected Customer ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Block it!",
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire(
                  "Blocked!",
                  "Customer blocked   successfully",
                  "success"
                );
                userlist();
              }
            });
          })
          .catch();
      },
    });
  };

  /////////////delete user api call ////////////
  const handledelete = (_id) => {
    const { custId } = { custId: _id };

    axios
      .post(
        `/v1/super-Agent/delete-user`,
        { custId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to Delete selected Customer ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire("Block!", "Customer Delete   successfully", "success");
            userlist();
          }
        });
      })
      .catch();
  };
  const paymentsendprogessuser = (_id) => {
    setcustID(_id);
    setpaymenthandler(true);
  };

  const userpay = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    axios.post(`/Chrome_pay_transection/${custID}`, formData).then((res) => {
      if (res.status) {
        setpaymenthandler(false);
        let data = res.data;
        {
          data.status
            ? Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.msg}`,
                showConfirmButton: false,
                timer: 3500,
              })
            : Swal.fire({
                icon: "error",
                // title: 'Oops...',
                text: `${data.msg}`,
              });
        }
      }
    });
  };
  const handleView = (_id) => {
    console.log(_id);
    // window.location = `/org-customer-view/${_id}`;
    return false;
  };
  const orgwallet = (_id) => {
    console.log(_id);
    window.location = `/OrgWallet/${_id}`;
    return false;
  };
  const userfacescan = (_id) => {
    window.location = `/Orgwebcamera/${_id}`;
  };
  const handleBruhId = (_id) => {
    console.log(_id);
    const { custID } = { custID: _id };
    axios.post(`/updateBruhID/${custID}`).then((resp) => {
      if (resp.status) {
        let data = resp.data;
        toast.success(data.msg);
        return userlist();
      }
    });
  };

  const handleVerfiy = (_id) => {
    const { custId } = { custId: _id };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to verify selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, verify it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `/v1/super-Agent/verify-customers`,
            { custId },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire(
                  "verified!",
                  "Customer verified successfully",
                  "success"
                );
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
      }
    });
  };
  useEffect(() => {
    ;
  }, []);
  return (
    <>
      <CustomerLoader loader={ShowLoader} />
      <ToastContainer />
      <div className="wrapper d-flex flex-column flex-row-fluid">
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        SUPER AGENT CUSTOMERS BLOCK LIST
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            {orgType === "Union" ? (
                              <>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="BruhrefID"
                                  placeholder="Bruh IDs ref"
                                />
                              </>
                            ) : (
                              <>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="BruhrefID"
                                  placeholder="Bruh IDs ref"
                                />
                              </>
                            )}
                          </div>

                          <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-phone"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="phone"
                              placeholder="Contact No"
                            />
                          </div>

                          <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="2"
                                  y="2"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="13"
                                  y="2"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="13"
                                  y="13"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="13"
                                  width="9"
                                  height="9"
                                  rx="2"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <select
                              className="form-select border-0 flex-grow-1"
                              name="status"
                              data-control="select2"
                              data-placeholder="Category"
                              data-hide-search="true"
                            >
                              <option defaultValue="" selected disabled>
                                Status
                              </option>

                              <option defaultValue="verified">Verified</option>
                              <option defaultValue="pending">pending</option>
                            </select>
                          </div>

                          <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fas fa-globe-americas fs-1"></i>
                            </span>
                            <select
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="Country"
                              data-hide-search="true"
                              name="nationality"
                            >
                              <option value="" selected disabled>
                                Country
                              </option>
                              <option value="ETHOPIAN">Ethiopia</option>
                              <option value="India">India</option>
                              <option value="USA">USA</option>
                              <option value="Uganda">Uganda</option>
                            </select>
                          </div>

                          <div className="col-lg-2 col-sm-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                            <label className="small_label">Start Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="fromDate"
                              placeholder="from Date.."
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-lg-2 d-flex align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label">End Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="toDate"
                              placeholder="to Date.."
                            />
                          </div>
                        </div>

                        <div className="min-w-175px d-flex gap-1 gap-sm-5">
                          <button
                            type="submit"
                            className="btn btn-dark"
                            id="kt_advanced_search_button_1"
                          >
                            Search
                          </button>
                          <button
                            type="reset"
                            onClick={userlist}
                            className="btn btn-secondary"
                            id="kt_advanced_search_button_1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        SUPER AGENT CUSTOMERS BLOCK LIST
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Customer
                      </span>
                    </h3>
                    <div class="card-title">
                      {/* <div class="d-flex align-items-center position-relative my-1">
                                    
                                              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                </svg>
                                              </span>
                                           
                                              <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Search Customers" onChange={(e)=>setQuery(e.target.value)}/>
                                            </div> */}
                    </div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-300px">ID Photo</th>
                            {orgType === "Union" ? (
                              <>
                                <th className="min-w-150px">
                                  Bruh IDs Ref No.
                                </th>
                              </>
                            ) : (
                              <>
                                <th className="min-w-150px">
                                  Union-IDs Ref No.
                                </th>
                              </>
                            )}
                            <th className="min-w-150px">
                              {" "}
                              Date of Registration
                            </th>
                            <th className="min-w-100px">Country</th>
                            <th className="min-w-100px">Biometrics</th>
                            {/* <th className="min-w-100px">
                              facial Identification
                            </th> */}
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data
                            .filter((user) =>
                              user.fullname.toLowerCase().includes(Query)
                            )
                            .map((item) => (
                              <tr>
                                <td>
                                  {" "}
                                  <div class="d-flex align-items-center">
                                    <div class="symbol symbol-45px me-5">
                                      <img src={item?.IDphoto} alt="" />
                                    </div>
                                    <div class="d-flex justify-content-start flex-column">
                                      <a
                                        //   href={`/org-customer-view/${item._id}`}
                                        class="usertitle Acolor mb-2"
                                      >
                                        {" "}
                                        {item.fullname}
                                      </a>
                                      <span class="font-icon mb-1">
                                        <span class="">
                                          <i class="fal fa-phone-volume"></i>
                                        </span>
                                        +{item.phone}
                                      </span>

                                      <span class="font-icon mb-1">
                                        <span class="">
                                          <i class="fal fa-envelope"></i>
                                        </span>
                                        {item.email}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td>{item.BruhrefID}</td>
                                <td>
                                  {Moment(item.createdAt).format("DD/MM//YYYY")}
                                </td>

                                <td>{item.nationality}</td>

                                {/*                               
                              {item.biometric == 0 ? (
                                <td class="text-center">
                                  <span
                                    class="svg-icon svg-icon-muted svg-icon-2hx faild"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                        fill="currentColor"
                                      ></path>
                                      <rect
                                        x="9"
                                        y="13.0283"
                                        width="7.3536"
                                        height="1.2256"
                                        rx="0.6128"
                                        transform="rotate(-45 9 13.0283)"
                                        fill="currentColor"
                                      ></rect>
                                      <rect
                                        x="9.86664"
                                        y="7.93359"
                                        width="7.3536"
                                        height="1.2256"
                                        rx="0.6128"
                                        transform="rotate(45 9.86664 7.93359)"
                                        fill="currentColor"
                                      ></rect>
                                    </svg>
                                  </span>
                                </td>
                              ) : (
                                <td class="text-center ">
                                  <span
                                    class="svg-icon svg-icon-muted svg-icon-2hx success"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </td>
                              )} */}

                                {item.facialIdentification == 0 ? (
                                  <td class="text-center">
                                    <span
                                      class="svg-icon svg-icon-muted svg-icon-2hx faild"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      data-kt-initialized="1"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                          fill="currentColor"
                                        ></path>
                                        <rect
                                          x="9"
                                          y="13.0283"
                                          width="7.3536"
                                          height="1.2256"
                                          rx="0.6128"
                                          transform="rotate(-45 9 13.0283)"
                                          fill="currentColor"
                                        ></rect>
                                        <rect
                                          x="9.86664"
                                          y="7.93359"
                                          width="7.3536"
                                          height="1.2256"
                                          rx="0.6128"
                                          transform="rotate(45 9.86664 7.93359)"
                                          fill="currentColor"
                                        ></rect>
                                      </svg>
                                    </span>
                                  </td>
                                ) : (
                                  <td class="text-center ">
                                    <span
                                      class="svg-icon svg-icon-muted svg-icon-2hx success"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      data-kt-initialized="1"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                          fill="currentColor"
                                        ></path>
                                        <path
                                          d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </span>
                                  </td>
                                )}
                                {item.status == "inactive" ? (
                                  <>
                                    <td>
                                      <span className="capitalize-text badge badge-light-pending fs-5">
                                        {item.status?.charAt(0).toUpperCase() +
                                          item.status?.slice(1)}
                                      </span>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <span className="badge badge-light-info fs-5 capitalize-text">
                                        {item.status?.charAt(0).toUpperCase() +
                                          item.status?.slice(1)}
                                      </span>
                                    </td>
                                  </>
                                )}
                                <td>
                                 <div className="d-flex justify-content-center flex-shrink-0">
                                    {/* {item.status == "pending" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleVerfiy(item._id);
                                        }}
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Pending "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                         

                                          <img
                                            src="/assets_new/images/pendding1.png"
                                            width="30px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                    
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Verified"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                        

                                          <img
                                            src="/assets_new/images/verify.png"
                                            width="28px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )} */}
                                    {item.blocked == "0" ? (
                                      <>
                                        <button
                                          onClick={(e) => {
                                            handleunblocked(
                                              (item.blocked = "1"),
                                              item._id
                                            );
                                          }}
                                          className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-trigger="hover"
                                          title="Block "
                                        >
                                          <span className="svg-icon svg-icon-3">
                                            <img
                                              src="/assets_new/images/blocked.png"
                                              width="20px"
                                            />
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          onClick={(e) => {
                                            handleunblocked(
                                              (item.blocked = "0"),
                                              item._id
                                            );
                                          }}
                                          className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-title="Password Reset "
                                          data-bs-trigger="hover"
                                          title="Suspend "
                                        >
                                          <span className="svg-icon svg-icon-3">
                                            <img
                                              src="/assets_new/images/suspand.png"
                                              width="20px"
                                            />
                                          </span>
                                        </button>
                                      </>
                                    )}
                                    {/* <button
                                    onClick={(e) => {
                                      paymentsendprogessuser(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Pay"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="\assets_new\images\pay-btn.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button> */}

                                    {/* <button
                                    onClick={(e) => {
                                      orgwallet(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="wallet"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="\assets_new\images\wallet.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button> */}
                                    <button
                                      onClick={(e) => {
                                        handleView(item._id);
                                      }}
                                      className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      title="View Detail"
                                    >
                                      <span className="svg-icon svg-icon-3">
                                        <i className="fad fa-eye fs-4"></i>
                                      </span>
                                    </button>
                                    {/* <button
                                    onClick={(e) => {
                                      userfacescan(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="face scan"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                 
                                      <img
                                        className="Face_logo bg-white"
                                        src="\assets_new\images\face.png"
                                        alt=""
                                      />
                                    </span>
                                  </button> */}
                                    {/* <button
                                    onClick={(e) => {
                                      handledelete(item._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Disable OTP"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye-slash"></i>
                                    </span>
                                  </button> */}
                                    {/* <button
                                    onClick={(e) => {
                                      handleBruhId(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Bruh ID "
                                  >
                                    <span className="svg-icon svg-icon-3">
                                    <i class="fab fa-Bruh-ocean"></i>
                                    </span>
                                  </button> */}
                                    {/* <button
                                 
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Reset Otp "
                                  >
                                    <span className="svg-icon svg-icon-3">
                                    <i class="far fa-repeat-alt"></i>
                                    </span>
                                  </button> */}
                                  </div>
                                </td>
                              </tr>
                            ))}

                          {/* <tr>
                                                        <td>##41e8bd3f3ca</td>
                                                        <td> Virendra Shilpkar </td>
                                                        <td> virendra@gmail.com </td>
                                                        <td><span>+221 123-456-7840</span> </td>
                                                          <td>05/06/1980</td>
                                                        <td>05/08/2020</td>
                                                        <td>Ethiopia</td>
                                                        <td>
                                                        <td><span className="badge badge-light-info fs-5 capitalize-text">Verified</span></td> 
                                                        </td>
                                                        <td>
                                                           <div className="d-flex justify-content-center flex-shrink-0">
                                                                <button
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Suspend ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-minus-circle fs-4"></i>
                                                                    </span>
                                                                </a>
                                                                <button
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Password Reset ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fas fa-repeat-alt"></i>
                                                                    </span>
                                                                </a>
                                                                <a href='organization_detail.html'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="View Detail">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Delete">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-trash-alt"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Disable OTP">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye-slash"></i>
                                                                    </span>

                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr> */}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-1"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                show={paymenthandler}
                onHide={() => setpaymenthandler(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Payment details</Modal.Title>
                </Modal.Header>
                <form onSubmit={userpay}>
                  <Modal.Body>
                    <div className="col-lg-12 ">
                      <div className="row">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          {" "}
                          User Number
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="receiver_phone"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Number"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type="submit" className="center">
                      {" "}
                      Pay
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
