import Sidebar from "./Sidebar";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");

function AgentDIDApprovel({ hadleragent }) {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");

  const limit = 10;
   let token = localStorage.getItem("CromepayAgentToken");
  var decode1 = jwt.decode(token);
  let agentID = decode1.agentID;


  const userlist = async () => {
    await axios.post(`/v1/view/DID/awaitingList`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
      let data = resp.data.filter;

      setData(data);
      console.log("dghffdf", data);
      const total = resp.data.totlaRow;
      console.log(total);
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    });
  };

  useEffect(() => {
    userlist();
    ;
  }, []);
  ///////////////pagenestion///////////////
  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };

    axios.post(`/v1/view/DID/awaitingList`, senData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
      const data = resp.data.filter;
      setData(data);
    });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    console.log("pushpak", page);
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  ////////////////delete api call /////////////////
  const handleVerfiy = (_id) => {
    const { custID } = { custID: _id };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to verify selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, verify it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/customerVerify/${custID}/${agentID}`).then((res) => {
          if (res.status) {
            let data = res.data;
            if (data.status) {
              Swal.fire(
                "verified!",
                "Customer verified successfully",
                "success"
              );
              return userlist();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
      }
    });
  };

  const handleView = (_id) => {
    console.log(_id);
    navigate(`/Agent-customer-view/${_id}`);
    return false;
  };



  return (
    <div>

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Digital ID
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <form>
                        <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                          <div className="row flex-grow-1 mb-5 mb-lg-0">
                            <div className="col-lg-6 d-flex align-items-center mb-3 mb-lg-0">
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-phone"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control form-control-flush flex-grow-1"
                                name="custID"
                                placeholder="D-ID"
                              />
                            </div>
                          </div>

                          <div className="min-w-150px text-end">
                            <button
                              type="submit"
                              className="btn btn-dark"
                              id="kt_advanced_search_button_1"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Digital Id List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Digital ID</th>
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Full Name</th>
                            <th className="min-w-125px">Email</th>
                            <th className="min-w-125px">Date of Birth</th>
                            <th className="min-w-125px">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((item) => (
                            <tr>
                              <td>{item.digitalID} </td>
                              <td>{item._id}</td>
                              <td> {item.fullname}</td>
                              <td> {item.email}</td>

                              <td>
                                {moment(item.dateOfBirth).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                <span className="capitalize-text badge badge-light-pending fs-5">
                                  {item.status}
                                </span>
                              </td>
                              <td>
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  {/* {item.status == "pending" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleVerfiy(item._id);
                                        }}
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Pending "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                       

                                          <img
                                            src="/assets_new/images/pendding1.png"
                                            width="30px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleVerfiy(item._id);
                                        }}
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Verify"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                         

                                          <img
                                            src="/assets_new/images/verify.png"
                                            width="28px"
                                          />
                                        </span>
                                      </button>
                                 
                                    </>
                                  )} */}

                                  <button
                                    onClick={(e) => {
                                      handleView(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>



                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentDIDApprovel;
