import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AutoAddress from "../../Mapcomponents/AutoAddress";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function RegisterCustomer2() {
    const navigate = useNavigate();
    const [residances, setresidance] = useState("");
  const [locaDocument, setlocaDocument] = useState("");
  const [landRegistrations, setlandRegistrations] = useState("");
  const [Phone, setPhone] = useState("");
      let token = localStorage.getItem("SuperAgentChromepayToken");
    const phone =localStorage.getItem("phone")
    console.log("@" + phone)
  

    useEffect(() => {
        ;
    }, []);


    const handleresidance = (data)=>{

        console.log("image data",data)
          let token = localStorage.getItem("SuperAgentChromepayToken");
        
            let formdata = new FormData
            formdata.append('image',data)
          
          console.log("image first datav ",formdata)
          axios.post(`/v1/DID/ImageUploader`,formdata,{ headers: {"Authorization" : `Bearer ${token}`} })
          .then((res)=>{
          const imgurl = res.data.data
          console.log(" imagr url ",imgurl)
          setresidance(imgurl)
          
          
          
          
          })
          
          }
          
          
          const handlerlocaDocument = (data)=>{
              let token = localStorage.getItem("SuperAgentChromepayToken");
          console.log("token",token)
            let formdata = new FormData
            formdata.append('image',data)
          
        
          axios.post(`/v1/DID/ImageUploader`,formdata,{ headers: {"Authorization" : `Bearer ${token}`} })
          .then((res)=>{
          const imgurl = res.data.data
          console.log(" imagr url ",imgurl)
          setlocaDocument(imgurl)
          
          
          
          
          })
          
          }
        
        
          const handlelandRegistration = (data)=>{
        
            console.log("file handle faya",data)
              let token = localStorage.getItem("SuperAgentChromepayToken");
          
            let formdata = new FormData
            formdata.append('image',data)
          
        
          axios.post(`/v1/DID/ImageUploader`,formdata,{ headers: {"Authorization" : `Bearer ${token}`} })
          .then((res)=>{
          const imgurl = res.data.data
          console.log(" imagr url ",imgurl)
          setlandRegistrations(imgurl)
          
          
          
          
          })
          }

         const AddFormData = async (e) => {


            e.preventDefault();
            const data = new FormData(e.target);
            const Formvlaues = Object.fromEntries(data.entries());
            console.log(Formvlaues);
        
            //   let token = localStorage.getItem("SuperAgentChromepayToken");
            // const decode1 = Jwt.decode(token);
            // const orgID = decode1.orgID;
            // console.log(orgID);
        
            let dataToSend2 = new FormData();
        
        
        
        
            // setPhone( Formvlaues.phone);
            dataToSend2.append("landSize", Formvlaues.landSize);
            dataToSend2.append("assetType", Formvlaues.assetType);
            dataToSend2.append("assetID", Formvlaues.assetID);
            dataToSend2.append("residace", residances);
            dataToSend2.append("local", locaDocument);
            dataToSend2.append("land", landRegistrations);
            dataToSend2.append("city", Formvlaues.city);
            dataToSend2.append("phone", phone);
        
          
        
            // localStorage.setItem("phone", Formvlaues.phone);
        
            axios
              .post(`/v1/super-Agent/registerDID2_demo`, dataToSend2, { headers: {"Authorization" : `Bearer ${token}`} })
              .then((res) => {
                if (res.status || res.service) {
                  let data = res.data;
                  console.log("first data ", data)
                  if (data.status) {
                    toast.success(data.msg);
                 
                    navigate("/Super-agent/otp-verfiy")
                   
                  } else if (data.service) {
                   
                    Swal.fire({
                      title: "This Customer Alredy Register You Want To Linked Service",
                      imageUrl: "../assets_new/images/handshake.png",
                      imageWidth: 100,
                      imageHeight: 60,
                      imageAlt: "Custom image",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire("Saved!", "", "success");
                        navigate("/agent-customer-links");
                      } else if (result.isDenied) {
                        Swal.fire("Changes are not saved", "", "info");
                      }
                    });
                  } else {
                    toast.error(data.msg);
                  }
                } else {
                  toast.error(data.msg);
                }
              });
          };


    return (
        <>
        <ToastContainer position="top-right" />
            <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
            >
                <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                >
                    <div class="container-xxl" >

                        <div class="row g-5 g-xl-8">
                            <div class="col-lg-12">

                                <div class="card mb-5 mb-xl-10">

                                    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                        <div class="card-title m-0">
                                            <h3 class="fw-bold m-0">Add Customer</h3>
                                        </div>

                                    </div>


                                    <div id="kt_account_settings_profile_details" class="collapse show">

                                        <form onSubmit={(e)=>AddFormData(e)}>

                                            <div class="card-body border-top p-9">

                                            <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            City
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="city"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="City"
                            />
                          </div>
                        </div>
                        
                       
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Land Size (HAC.)</span>
                            {/* <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="tel"
                              name="landSize"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Land Size"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Asset Types
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                            <select
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="assetType"
                              data-hide-search="true"
                              name="assetType"
                            >
                              <option value="1" selected="selected">
                                {" "}
                                Select Asset
                              </option>
                              <option value="Land"> Land</option>
                              <option value="House"> House</option>
                              <option value="Car"> Car</option>
                              <option value="Store"> Store</option>
                              {/* {listorg.map((item)=>(
                            <option key={item._id} value={item._id}>{item.name}</option>
                        ))} */}
                            </select>
                          </div>
                        </div>
                        {/* <LandMap style={{width : "100% !important"}} /> */}
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            ID Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                            <select
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="assetID"
                              data-hide-search="true"
                              name="assetID"
                            >
                              <option value="1" selected="selected">
                                {" "}
                                Select ID
                              </option>
                              <option value="National"> National ID</option>
                              <option value="Passport"> Passport</option>
                              <option value="Drivers"> Drivers Licence</option>
                              <option value="Notarised">
                                {" "}
                                Notarised Document
                              </option>
                              {/* {listorg.map((item)=>(
                                                                        <option key={item._id} value={item._id}>{item.name}</option>
                                                                    ))} */}
                            </select>
                          </div>
                        </div>
                        
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Identification Document Image
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              name="local"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Residance Image"
                              onChange={(e)=>handleresidance(e.target.files[0])}
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Proof of Residence
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              name="residace"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Local Document"
                              onChange={(e)=>handlerlocaDocument(e.target.files[0])}
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Assets Ownership Certificate
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              name="land"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Land Registration"
                              onChange={(e)=>handlelandRegistration(e.target.files[0])}
                            />
                          </div>
                          </div>




                                            </div>
                                            <div class="card-footer d-flex justify-content-end py-6 px-9">
                                                {/* <button type="reset" class="btn btn-light btn-active-light-primary me-2">Reset</button> */}
                                                <button type="submit"  class="btn btn-primary" id="kt_account_profile_details_submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default RegisterCustomer2