import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

function AdminLogsList({ hadleradmin }) {
  let token = localStorage.getItem("ChromePayAdmitToken");
  const [pageCount, setpageCount] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);
  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);

  const limit = 10;

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/admin/chrome_pay_logs`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        console.log("@@@@@", data);
        const total = resp.data.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const userlist = async () => {
    await axios
      .post(
        `/v1/admin/chrome_pay_logs`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        console.log("@@@@@", data);
        const total = resp.data.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };

    axios
      .post(`/v1/admin/chrome_pay_logs`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  /////////////unblocked user api call ////////////
  const handleunblocked = (_id) => {
    axios.put(`/v1/admin/UnBlockCustomer/${_id}`).then((res) => {
      if (res.status) {
        let data = res.data;
        toast.success(data.msg);
        return userlist();
      }
    });
  };

  /////////////blocked user api call ////////////
  const handlesuspend = (_id) => {
    axios.put(`/v1/admin/BlockCustomer/${_id}`).then((res) => {
      if (res.status) {
        let data = res.data;
        toast.success(data.msg);
        return userlist();
      }
    });
  };

  /////////////delete user api call ////////////
  const handledelete = (_id) => {
    axios.delete(`/v1/admin/Delete/${_id}`).then((res) => {
      if (res.status) {
        let data = res.data;
        toast.success(data.msg);
        return userlist();
      }
    });
  };

  useEffect(() => {
    userlist();
    ;
  }, []);

  return (
    <>
      <CustomerLoader loader={ShowLoader} />
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                 
                    {/* <form onSubmit={(e) => formsave(e)}>
                      <div className="d-flex flex-column">
                        <div className="d-lg-flex align-lg-items-center">
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="IP"
                                  placeholder="Enter IP"
                                />
                              </div>

                              <div className="col-lg-2 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0  flex-grow-1"
                                  name="status"
                                  data-control="select2"
                                  data-placeholder="status"
                                  data-hide-search="true"
                                >
                                  <option value="" selected disabled>
                                    Status
                                  </option>
                                  <option value="Success">Success</option>
                                  <option value="Failed">Failed</option>
                                </select>
                              </div>

                              <div className="col-lg-2 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  name="Category"
                                  className="form-select border-0 flex-grow-1"
                                  data-control="select2"
                                  data-placeholder="Category"
                                  data-hide-search="true"
                                >
                                  <option value="" selected disabled>
                                    Category
                                  </option>
                                  <option value="Agent">Agent</option>
                                  <option value="Organzation">
                                    Organzation
                                  </option>
                                
                                </select>
                              </div>

                              <div className="col-lg-2 d-flex input-container align-items-center mb-3  mb-lg-0">
                                <label className="small_label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="fromDate"
                                  placeholder="User Name.."
                                />
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              </div>

                              <div className="col-lg-2 d-flex align-items-center mb-3 input-container mb-lg-0">
                                <label className="small_label">End Date</label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="toDate"
                                  placeholder="User Name.."
                                />
                              </div>
                            </div>

                            <div className="min-w-150px text-end d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark mr-3"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={userlist}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Chromepay Logs list
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Throw</th>
                            <th className="min-w-150px">IP Address</th>
                            <th className="min-w-150px">Api </th>

                            <th className="min-w-100px">Date</th>
                            <th className="min-w-100px ">Status</th>
                            {/* <th className="min-w-100px text-center">Actions</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((item) => (
                            <tr>
                              <td className="text-center">{item._id}</td>
                              <td className="text-center">{item.BY}</td>
                              <td className="text-center">{item.IPAdress}</td>
                              <td className="text-center">
                                <span>{item.url}</span>{" "}
                              </td>

                              <td className="text-center">{moment(item.time).format("DD/MM/YYYY")}</td>
                              <td className="text-center">
                                <span className="badge badge-light-info fs-5 capitalize-text">
                                  {item.status}
                                </span>
                              </td>
                              {/* <td className="text-center">
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  {item.blocked == "1" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleunblocked(item._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/blocked.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handlesuspend(item._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Password Reset "
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/suspand.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )}

                             

                                  <button
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      handledelete(item._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-trash-alt"></i>
                                    </span>
                                  </button>
                               
                                </div>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogsList;
