import React, { useRef, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Jwt from "jsonwebtoken";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Moment from 'moment';

function LoanTypes() {

    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [license, setLicense] = useState([]);
    const [Paymentprocess, setPaymentprocess] = useState(false)
    const [custom, setCustom] = useState('')
    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState('');
    const focusPoint = useRef(null);
    const [pageCount, setpageCount] = useState("");
    const [typeId, settypeId] = useState(true);
    const [loanType, setLoanType] = useState("");
    const [loanInterestRate, setInterestRate] = useState("");
    const [mandatoryAmount, setMandatory] = useState([]);
    const [volantoryAmount, setVolantory] = useState([]);
    const [Blockhandler, setBlockhandler] = useState(false);
    const [Blockhandler1, setBlockhandler1] = useState(false);
    const [imagepassword, setimagepassword] = useState(-1);
    const [userinfo, setUserinfo] = useState([]);
    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const fileInputRef1 = useRef(null);


    const currentFont = localStorage.getItem('liesenapply');
    let token = localStorage.getItem("SuperAgentChromepayToken")



    let getSavingAccounts = () => {
        axios.post('/v1/super-Agent/get-saving-Accs', {}, { headers: { "Authorization": `Bearer ${token}` } })
            .then((resp) => {
                let mandatoryAmount = resp.data.mandatoryAmount;
                let volantoryAmount = resp.data.volantoryAmount

                setMandatory(mandatoryAmount);
                setVolantory(volantoryAmount);


            })
    }


    const addactive = () => {

        focusPoint.current.classList.toggle("processactive");
    }
    const focusPointpayment = useRef(null);
    const addactivepayment = () => {

        focusPoint.current.classList.toggle("processactivepayment");
    }

    const handleEditClick1 = () => {
        // Trigger file input click when edit icon is clicked
        fileInputRef1.current.click();
    };


    const AddFormData1 = async (e) => {
        e.preventDefault();

        let data = new FormData(e.target);
        let Formvlaues = Object.fromEntries(data.entries());
        let formData = Formvlaues;
        formData.typeId = typeId;

        console.log(Formvlaues);

        axios
            .post(`/v1/super-Agent/update-loans-type-with-interest`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((resp) => {
                const data = resp.data;
                console.log(data);
                if (data.status) {
                    toast.success(data.msg);
                    Licenseslist()
                    settypeId(null)
                    setShow1(false);
                    //   return custumerDetails();
                } else {
                    toast.error(data.msg);
                }
            });
    };


    const AddFormData2 = async (e) => {
        e.preventDefault();

        let data = new FormData(e.target);
        let Formvlaues = Object.fromEntries(data.entries());
        let formData = Formvlaues;


        console.log(Formvlaues);

        axios
            .post(`/v1/super-Agent/add-loan-types-with-int`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((resp) => {
                const data = resp.data;
                console.log(data);
                if (data.status) {
                    toast.success(data.msg);
                    Licenseslist()

                    setShow2(false);
                    setShow1(false);

                    //   return custumerDetails();
                } else {
                    toast.error(data.msg);
                }
            });
    };


    const VerfiyBlock = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const FormValues = Object.fromEntries(data.entries());

        try {
            const response = await axios.post(
                `/v1/super-Agent/match-agent-pass`,
                FormValues,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setBlockhandler(false);
            let responseData = response.data; // Renamed variable
            console.log("+++++typeId+++++++", { typeId });

            if (responseData.status) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${responseData.message}`,
                    showConfirmButton: false,
                    timer: 3500,
                });
                if (typeId) {
                    setShow2(true);
                } else {
                    setShow1(true);
                }

            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${responseData.message}`,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${error.response.data.message}`,
                    showConfirmButton: false,
                    timer: 3500,
                });
                setBlockhandler(false);
            }
        }
    };

    const VerfiyBlock1 = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const FormValues = Object.fromEntries(data.entries());

        try {
            const response = await axios.post(
                `/v1/super-Agent/match-agent-pass`,
                FormValues,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setBlockhandler1(false);
            let responseData = response.data; // Renamed variable
            console.log("++++++++++++", { responseData });

            if (responseData.status) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${responseData.message}`,
                    showConfirmButton: false,
                    timer: 3500,
                });
                if (imagepassword == 0) {
                    return handleEditClick();
                } else if (imagepassword == 1) {
                    return handleEditClick1();
                } else if (imagepassword == 2) {
                    return handleEditClick2();
                } else if (imagepassword == 3) {
                    setTimeout(() => {
                        // setShow1(true);
                        setShow2(true);
                    }, 4000);
                } else {
                    setTimeout(() => {
                        handleShow(userinfo._id);
                    }, 200);
                }
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${responseData.message}`,
                    showConfirmButton: false,
                    timer: 3500,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${error.response.data.message}`,
                    showConfirmButton: false,
                    timer: 3500,
                });
                setBlockhandler1(false);
            }
        }
    };

    const options = [
        { value: '1000', label: '1K' },
        { value: '10000', label: '10K' },
        { value: '50000', label: '50K' },
        { value: '100000', label: '100K' },
        { value: '500000', label: '500K' },
        { value: '1000000', label: '1M' },
        { value: '2000000', label: '2M' },
        { value: '5000000', label: '5M' },
        { value: 'option2', label: 'Custom' },

    ];

    const Paymentprocessbank = async () => {
        await axios.post(`/v1/org/dash/LicenseProcess`, {}, { headers: { "Authorization": `Bearer ${token}` } })
            .then(resp => {
                let data = resp.data.status;
                setPaymentprocess(data);
                console.log("+++++++++++++++++++", data);
            })
    }

    const handleEditClick = () => {
        // Trigger file input click when edit icon is clicked
        fileInputRef.current.click();
    };


    const handleEditClick2 = () => {
        // Trigger file input click when edit icon is clicked
        fileInputRef2.current.click();
    };

    function handleShow(_id) {
        console.log("idddd", _id);
        setShow1(true);
    }

    useEffect(() => {
        Paymentprocessbank();
        getSavingAccounts();
        if (currentFont) {
            addactive();
        }

    }, [])
    useEffect(() => {

        if (Paymentprocess) {
            addactivepayment()
        }

    }, [Paymentprocess])
    useEffect(() => {
        ;
        userlist();
    }, [])

    console.log("jjjjjjjjjjjjjjjjjjj", Paymentprocess);



    const AddLicense = (e) => {

        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        console.log("@@@" + Formvlaues.Licenses)
        axios.post(`/v1/super-Agent/send-request`, formData, { headers: { "Authorization": `Bearer ${token}` } })
            .then(resp => {
                if (resp.status) {
                    let data = resp.data
                    if (data.status) {
                        Swal.fire(
                            'SuccessFully!',
                            data.msg,
                            'success'
                        )
                        toast.success();

                        e.target.reset();
                        return Licenseslist();


                    } else {
                        toast.error(data.msg)
                    }
                } else {
                    toast.error(data.msg)
                }

            })

    };

    const userlist = () => {
        axios.post(`/v1/org/dash/detail`, {}, { headers: { "Authorization": `Bearer ${token}` } })
            .then((resp) => {
                let data = resp.data;

                setLicense(data);

            });
    }


    const limit = 5;

    const formsave = (e, page) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        console.log("Formvlaues === ", Formvlaues);
        axios.post(`/v1/super-Agent/get-loans-type-with-interest`, formData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
            const total = resp.data.totalRow;
            console.log({ data });
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
        });
    };
    const Licenseslist = async () => {
        await axios.post(`/v1/super-Agent/get-loans-type-with-interest`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
            console.log(data);
            const total = resp.data.totalRow;
            console.log(total);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
        });
    };
    useEffect(() => {
        Licenseslist();
        
    }, []);
    const fetchComments = async (page) => {
        const senData = { page: page };
        // const cosole = Fromvalue;
        // console.log(Fromvalue);
        axios.post(`/v1/super-Agent/get-loans-type-with-interest`, senData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
        });
        return data;
    };

    const handlePageClick = async (data) => {
        // console.log(data.selected);
        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
        setData(commentsFormServer);
    };
    return (
        <>
            <div>




                <ToastContainer />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" >
                            <div className="row g-5 g-xl-8">


                            </div>
                            <div className="col-lg-12">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-lg-12">
                                        <div
                                            className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                                            style={{ marginTop: "2rem" }}
                                        >
                                            <div className="card-body container-xxl pt-10 pb-8">
                                                <div className="d-flex align-items-center">
                                                    <h1 className="fw-semibold me-3 text-white">LOAN'S LIST</h1>
                                                    <span className="fw-semibold text-white opacity-50">
                                                        {" "}
                                                    </span>
                                                </div>

                                                {/* <div className="d-flex flex-column">
                                                    <div className="d-lg-flex align-lg-items-center">
                                                        <form onSubmit={(e) => formsave(e)}>
                                                            <div className="d-flex flex-column">
                                                                <div className="d-lg-flex align-lg-items-center">
                                                                    <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                                                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                                                                            

                                                                            <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <svg
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <rect
                                                                                            x="2"
                                                                                            y="2"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="13"
                                                                                            y="2"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="13"
                                                                                            y="13"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="2"
                                                                                            y="13"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                                <select
                                                                                    name="status"
                                                                                    className="form-select border-0 flex-grow-1"
                                                                                    data-control="select2"
                                                                                    data-placeholder="Status"
                                                                                    data-hide-search="true"
                                                                                >
                                                                                    <option value="" selected disabled>
                                                                                        Status
                                                                                    </option>
                                                                                    <option value="PENDING">Pending</option>
                                                                                    <option value="PASS">Approved</option>
                                                                                    <option value="PASS">Pass</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className="col-lg-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                                <label className="small_label">
                                                                                    Start Date
                                                                                </label>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                                </span>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                                    name="fromDate"
                                                                                    placeholder="Date"
                                                                                />
                                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                            </div>

                                                                            <div className="col-lg-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                                <label className="small_label">End Date</label>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                                </span>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                                    name="toDate"
                                                                                    placeholder="Date"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="min-w-150px text-end d-flex">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-dark mr-3"
                                                                                id="kt_advanced_search_button_1"
                                                                            >
                                                                                Search
                                                                            </button>
                                                                            <button
                                                                                type="reset"
                                                                                onClick={Licenseslist}
                                                                                className="btn btn-secondary"
                                                                                id="kt_advanced_search_button_1"
                                                                            >
                                                                                Reset
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">

                                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                            <div className='d-flex align-item center justify-content-end'>
                                                <button onClick={(e) => {
                                                    setBlockhandler(true)
                                                }} type="button" className="btn btn-primary ms-3">Add Loan</button>


                                            </div>
                                            <div className="card-header border-0 pt-5">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold fs-3 mb-1">
                                                    </span>
                                                    <span className="text-muted mt-1 fw-semibold fs-7"></span>
                                                </h3>
                                            </div>

                                            <div className="card-body py-3">
                                                <div className="table-responsive">
                                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                                        <thead>
                                                            <tr className="fw-bold text-muted th-title">
                                                                <th className="min-w-125px">Loan Icon</th>
                                                                <th className="min-w-150px">Type</th>
                                                                <th className="min-w-150px">Interest Rate</th>
                                                                <th className="min-w-150px"> Last Update</th>
                                                                <th className="min-w-100px">Action</th>
                                                                {/*  <th className="min-w-100px">Status</th> */}
                                                                {/* <th className="min-w-100px">
                              facial Identification
                            </th> */}
                                                                {/* <th className="min-w-100px ">Approve Status</th> */}
                                                                {/* <th className="min-w-100px text-center">Actions</th> */}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {data.map((item) => (
                                                                <tr>
                                                                    <td>
                                                                        {" "}
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-45px me-5">
                                                                                <img src="https://chromepay.s3.ap-south-1.amazonaws.com/abc/loan-line-icon-png_233984.jpg" alt="" />

                                                                            </div>
                                                                            {/* <div className="d-flex justify-content-start flex-column">
                                                                                <a
                                                                                    href={`/org-customer-view/${item._id}`}
                                                                                    className="usertitle Acolor mb-2"
                                                                                >
                                                                                    {" "}
                                                                                    {item.ord_detail?.name}
                                                                                </a>
                                                                                <span className="font-icon mb-1">
                                                                                    <span className="">
                                                                                        <i className="fal fa-phone-volume"></i>
                                                                                    </span>
                                                                                    +{item.phone}
                                                                                </span>

                                                                                <span className="font-icon mb-1">
                                                                                    <span className="">
                                                                                        <i className="fal fa-envelope"></i>
                                                                                    </span>
                                                                                    {item.ord_detail?.email}{" "}
                                                                                </span>
                                                                            </div> */}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        {item.loanType}
                                                                    </td>
                                                                    <td>
                                                                        {item.interestRate}
                                                                    </td>
                                                                    <td>
                                                                        {Moment(item.updatedAt).format("DD/MM/YYYY")}
                                                                    </td>

                                                                    {/* <td>{item.reason}</td> */}


                                                                    {/*                               
                              {item.biometric == 0 ? (
                                <td className="text-center">
                                  <span
                                    className="svg-icon svg-icon-muted svg-icon-2hx faild"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                        fill="currentColor"
                                      ></path>
                                      <rect
                                        x="9"
                                        y="13.0283"
                                        width="7.3536"
                                        height="1.2256"
                                        rx="0.6128"
                                        transform="rotate(-45 9 13.0283)"
                                        fill="currentColor"
                                      ></rect>
                                      <rect
                                        x="9.86664"
                                        y="7.93359"
                                        width="7.3536"
                                        height="1.2256"
                                        rx="0.6128"
                                        transform="rotate(45 9.86664 7.93359)"
                                        fill="currentColor"
                                      ></rect>
                                    </svg>
                                  </span>
                                </td>
                              ) : (
                                <td className="text-center ">
                                  <span
                                    className="svg-icon svg-icon-muted svg-icon-2hx success"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </td>
                              )} */}


                                                                    {/* {item.status == "PENDING" ? (
                                                                        <>
                                                                            <td>
                                                                                <span className="badge badge-light-pending text-danger fs-5">
                                                                                    {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <td>
                                                                                <span className="badge badge-light-info fs-5 capitalize-text">
                                                                                    {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                    {item.status == "PENDING" ? (
                                                                        <>
                                                                            <td>
                                                                                <span className="badge badge-light-pending  text-danger fs-5">
                                                                                    {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    ) : (

                                                                        <>
                                                                            {item.status == "Reject" ?
                                                                                (
                                                                                    <>
                                                                                        <td>
                                                                                            <span className="badge badge-light-pending text-danger  fs-5">
                                                                                                {item.status}
                                                                                            </span>
                                                                                        </td>
                                                                                    </>) : (
                                                                                    <>
                                                                                        <td>
                                                                                            <span className="badge badge-light-info text-success fs-5">
                                                                                                {item.status}
                                                                                            </span>
                                                                                        </td>
                                                                                    </>)}

                                                                        </>
                                                                    )} */}

                                                                    <td>
                                                                        <button onClick={(e) => {
                                                                            setBlockhandler(true)
                                                                            settypeId(item._id)
                                                                            setLoanType(item.loanType)
                                                                            setInterestRate(item.interestRate)
                                                                        }} className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4" data-bs-trigger="hover" title="Update Loan">
                                                                            <span className="svg-icon svg-icon-3"><i className="fas fa-pencil-alt"></i></span></button>
                                                                    </td>

                                                                </tr>
                                                            ))}

                                                            {/* <tr>
                                                        <td>##41e8bd3f3ca</td>
                                                        <td> Virendra Shilpkar </td>
                                                        <td> virendra@gmail.com </td>
                                                        <td><span>+221 123-456-7840</span> </td>
                                                          <td>05/06/1980</td>
                                                        <td>05/08/2020</td>
                                                        <td>Ethiopia</td>
                                                        <td>
                                                        <td><span className="badge badge-light-info fs-5 capitalize-text">Verified</span></td> 
                                                        </td>
                                                        <td>
                                                           <div className="d-flex justify-content-center flex-shrink-0">
                                                                <button
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Suspend ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-minus-circle fs-4"></i>
                                                                    </span>
                                                                </a>
                                                                <button
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Password Reset ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fas fa-repeat-alt"></i>
                                                                    </span>
                                                                </a>
                                                                <a href='organization_detail.html'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="View Detail">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Delete">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-trash-alt"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Disable OTP">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye-slash"></i>
                                                                    </span>

                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="col-lg-12 mt-2 text-end">
                                                    <ReactPaginate
                                                        PreviousLabel={"Previous"}
                                                        NextLabel={"Next"}
                                                        breakLabel={"..."}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination justify-content-end"}
                                                        pageClassName={"page-item"}
                                                        pageLinkClassName={"page-link"}
                                                        PreviousClassName={"page-item mx-2"}
                                                        PreviousLinkClassName={"page-link pagestyle"}
                                                        NextClassName={"page-item"}
                                                        NextLinkClassName={"page-link"}
                                                        breakClassName={"page-item"}
                                                        breakLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>


            <Modal
                className="modal-center"
                show={Blockhandler}
                onHide={() => setBlockhandler(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Verify Password</Modal.Title>
                </Modal.Header>
                <form onSubmit={VerfiyBlock}>
                    <Modal.Body>
                        <div className="col-lg-12 ">
                            <div className="row">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    {" "}
                                    Password
                                </label>
                                <div className="col-lg-8 fv-row">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        placeholder="Enter password"
                                    />
                                </div>
                            </div>
                            {/* <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="center">
                            {" "}
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>






            <Modal
                show={show1}
                onHide={() => setShow1(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Loan</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => AddFormData1(e)}>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-lg-6 mt-3">
                                <label className="mb-2">Loan Type</label>
                                <input
                                    className="form-control"
                                    placeholder="Loan Type"
                                    type="text"
                                    name="loanType"
                                    defaultValue={loanType}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mt-3 ">
                                <label className=" fs-6">
                                    <span className="">Interest Rate</span>
                                </label>

                                <input
                                    type="number"
                                    name="interestRate"
                                    defaultValue={loanInterestRate}
                                    pattern="[0-9]{10}"
                                    title=" Only number allow  Ten digits code"
                                    required
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Interest Rate"
                                    step='any'
                                />
                            </div>

                        </div>





                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow1(false)}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                        >
                            {" "}
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>


            <Modal
                show={show2}
                onHide={() => setShow2(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Loan</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => AddFormData2(e)}>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-lg-6 mt-3">
                                <label className="mb-2">Loan Type</label>
                                <input
                                    className="form-control"
                                    placeholder="Loan Type"
                                    type="text"
                                    name="loanType"
                                    defaultValue={loanType}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mt-3 ">
                                <label className=" fs-6">
                                    <span className="">Interest Rate</span>
                                </label>

                                <input
                                    type="number"
                                    name="interestRate"
                                    defaultValue={loanInterestRate}
                                    pattern="[0-9]{10}"
                                    title=" Only number allow  Ten digits code"
                                    required
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Interest Rate"
                                    step='any'
                                />
                            </div>

                        </div>





                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow2(false)}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                        >
                            {" "}
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>







            {/* added new progess bar  */}



        </>
    )
}


export default LoanTypes
