import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Jwt from "jsonwebtoken";
import CustomerLoader from "../../../SuperAgent/CRM/Modal/CustomerLoder";

function AddCategorySuperAgent() {
  const navigate = useNavigate();
  const [ShowLoader, setShowLoader] = useState(false);

  let token = localStorage.getItem("SuperAgentChromepayToken");

  const AddFormData = async (e) => {
    e.preventDefault();
    setShowLoader(true)
    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData.entries());

    try {
      // Send POST request
      const response = await axios.post(
        `/v1/super-Agent/add-accounts-category`,
        formValues,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { data } = response;
      if (data.status) {
        toast.success(data.msg);
        navigate("/super-agent-category-list");
      } else {
        toast.error(data.msg);  
          setShowLoader(false)

      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    ;
  }, []);
  return (
    <>
      <ToastContainer position="top-right" />
<CustomerLoader loader={ShowLoader}/>
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Category</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9 d-flex align-items-center justify-content-center">
                        <div className="row w-100">
                          {/* Title Field */}
                          <div className="col-12 col-md-8 mb-6">
                            <div className="row">
                              <label className="col-12 col-form-label fw-semibold fs-6 mb-2">
                                Title
                              </label>
                              <div className="col-12 fv-row">
                                <input
                                  type="text"
                                  name="title"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Enter category title"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          {/* Description Field */}
                          <div className="col-12 col-md-8 mb-6">
                            <div className="row">
                              <label className="col-12 col-form-label fw-semibold fs-6 mb-2">
                                Description
                              </label>
                              <div className="col-12 fv-row">
                                <input
                                  type="text"
                                  name="description"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Enter a brief description"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategorySuperAgent;
