import axios from "axios";
import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
export default function Applyforloan() {
  const { _id } = useParams();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const [loanlist, setloanlist] = useState([]);

  const Submitloanapply = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const Formvlaues = Object.fromEntries(data.entries());
    console.log("Formvlaues", Formvlaues);
    axios
      .post(`v1/super-Agent/create-loan`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        let data = resp.data;
        console.log("data ", data);
        if (data.status) {
          toast.success(data.msg);
        } else {
          toast.error(data.msg);
        }
      })
      .catch(console.error());
  };

  useEffect(() => {
    ;
  }, []);
  return (
    <>
      <ToastContainer position="top-right" />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" >
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Loan Detalis</h3>
                    </div>
                  </div>

                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form onSubmit={Submitloanapply}>
                      <div className="card-body border-top p-9">
                        <input
                          name="custId"
                          defaultValue={_id}
                          style={{ display: "none" }}
                        />

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Loan Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="loanType"
                              aria-label="Default select example"
                            >
                              <option selected>Select Loan Type</option>
                              <option value="Money">Money</option>
                              <option value="Fertilizer">Fertilizer</option>
                              <option value="Chemicals">Chemicals</option>
                              <option value="Goods">Goods</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            interest
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="interest"
                              aria-label="Default select example"
                            >
                              <option selected>Select interest</option>
                              <option value="12">12%</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            {" "}
                            Number Of EMI
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="noOfEmis"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of EMI</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            {" "}
                            LoanAmount
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="loanAmount"
                              aria-label="Default select example"
                            >
                              <option selected>Select loan Amount</option>
                              <option value="30000">30000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="Submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
