import React from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import { useSelector } from "react-redux";


var jwt = require("jsonwebtoken");

function JdcSubadminlist({ hadlerjdc }) {
  const [ShowLoader, setShowLoader] = useState(true);
  const [ShowPassword, setShowPassword] = useState(false);
  const [productID, setProductID] = useState("");
  const { authType } = useSelector((state) => state.auth);



  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;
  const [pageCount, setpageCount] = useState("");

  const [total, settotal] = useState([]);
  const [Query, setQuery] = useState("");

  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);

  const limit = 10;

  const formsave = (e, page) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/super-Agent/get-jdc-admins`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        const total = resp.data.totalRecords;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  const deleteAdmin = async (id) => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/delete-jdc-admin`,
        {id},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        Subadmin()
      });
  };

  const Subadmin = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-jdc-admins`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        console.log("sub admin", { data });
        const total = resp.data.totalRecords;
        settotal(total);
        setShowLoader(false);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };
  
  useEffect(() => {
    Subadmin();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    setShowLoader(true);
    axios
      .post(`/v1/super-Agent/get-jdc-admins`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        setShowLoader(false);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  const handleEdit = (row) => {
    navigate(`/${authType}/update-subadmin`, { state: row });
  };

  useEffect(() => {
    ;
  }, []);

  function FingRightImage(item) {
    const imageUrl = item?.image;

    function isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

    if (imageUrl && isValidUrl(imageUrl)) {
      return imageUrl;
    } else {
      if (item?.gender === "MALE") {
        return "/assets_new/images/defaultmale.jpg";
      } else {
        return "/assets_new/images/femaledefault.png";
      }
    }
  }

  function handleImageError(e, item) {
    e.target.src =
      item?.gender === "MALE"
        ? "/assets_new/images/defaultmale.jpg"
        : "/assets_new/images/femaledefault.png";
  }

  const UpdatedadminStatus = (item) => {
    const senData = {
      id: item?._id,
      status: item?.status == "active" ? "inactive" : "active",
    };

    setShowLoader(true);
    axios
      .post(`/v1/super-Agent/update-admin-status`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        if (data?.status) {
          Subadmin();
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Sub Admin
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="d-flex flex-column">
                        <div className="d-lg-flex align-lg-items-center">
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-12 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="search"
                                  placeholder="Search..."
                                />
                              </div>
                            </div>

                            <div className="min-w-150px  gap-1 text-end d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark mr-3"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={Subadmin}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        SUB ADMIN LIST
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Customer
                      </span>
                    </h3>
                    <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="currentColor"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>

                        <input
                          type="text"
                          data-kt-customer-table-filter="search"
                          class="form-control form-control-solid w-250px ps-15"
                          placeholder="Search Customers"
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-300px">ID Photo</th>

                            <th className="min-w-150px">Address</th>
                            <th className="min-w-150px"> Date of Creation</th>
                            <th className="min-w-100px text-center">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data
                            ?.filter((user) =>
                              user?.fullName?.toLowerCase()?.includes(Query)
                            )
                            ?.map((item) => (
                              <tr>
                                <td>
                                  {" "}
                                  <div class="d-flex align-items-center">
                                    <div class="symbol symbol-45px me-5">
                                      <img
                                        src={FingRightImage(item)}
                                        alt=""
                                        onError={(e) =>
                                          handleImageError(e, item)
                                        }
                                      />
                                    </div>
                                    <div class="d-flex justify-content-start flex-column">
                                      <a
                                        href={`/org-customer-view/${item?._id}`}
                                        class="usertitle Acolor mb-2"
                                      >
                                        {" "}
                                        {item?.fullName}
                                      </a>
                                      <span class="font-icon mb-1">
                                        <span class="">
                                          <i class="fal fa-phone-volume"></i>
                                        </span>
                                        +{item?.phone}
                                      </span>

                                      <span class="font-icon mb-1">
                                        <span class="">
                                          <i class="fal fa-envelope"></i>
                                        </span>
                                        {item?.email}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td>{item?.address}</td>
                                <td>
                                  {Moment(item?.createdAt).format("DD/MM/YYYY")}
                                </td>

                                <td>
                                  <div className="d-flex justify-content-center flex-shrink-0">
                                    <button
                                      onClick={(e) => {
                                        UpdatedadminStatus(item);
                                      }}
                                      className={`btn btn-detail btn-active-color-primary me-1 mr-4 ${
                                        item?.status === "active"
                                          ? "btn-success"
                                          : "btn-danger"
                                      } text-white text-uppercase`}
                                    >
                                      {item?.status}
                                    </button>
                                  </div>
                                </td>

                                <td>
                                  <div className="d-flex justify-content-center flex-shrink-0">
                                    <button
                                      onClick={(e) => {
                                        handleEdit(item);
                                      }}
                                      className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      title="View Detail"
                                    >
                                      <span className="svg-icon svg-icon-3">
                                        <i className="fas fa-edit fs-4"></i>
                                      </span>
                                    </button>
                                    <button
                                    onClick={() => {
                                      deleteAdmin(item?._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete Product"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fas fa-trash-alt"></i>
                                    </span>
                                  </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
}

export default JdcSubadminlist;
