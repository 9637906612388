



import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

export default function OrgRejectLoanforSuperAgentList() {
    let token = localStorage.getItem("organizationToken")
  var decode1 = jwt.decode(token);

const navigate = useNavigate()
  const [pageCount, setpageCount] = useState("");
  const [notereason, setnotereason] = useState("");
  const [total, settotal] = useState([]);

  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);
  const [paymenthandler, setpaymenthandler] = useState(false);
  const [userdetails, setuserdetails] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  const LoanRejectedCustomerList = async () => {
    setShowLoader(true)
    await axios
      .post(
        `/v1/org/CRM/get-superAgent-loan-Details`,
        { page: "1" , status: "reject" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
  
        let filter = data.data?.list;
        setuserdetails(filter);

        const total = resp.data.totalRow;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        setShowLoader(false)
      });
  };

  const fetchComments = async (page) => {
    const senData = { page: page,  status: "reject", };
    setShowLoader(true)

    await axios
      .post(
        `/v1/org/CRM/get-superAgent-loan-Details`,

        { senData },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        let filter = data.data?.list;
        console.log({ filter });
        setuserdetails(filter);
        setShowLoader(false)

      });
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);

  };

  useEffect(() => {
    LoanRejectedCustomerList();
  }, []);

  ///////////////pagenestion///////////////

  const formsave = (e, page) => {
    e.preventDefault();
    setShowLoader(true)

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/org/CRM/get-superAgent-loan-Details`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {

        let filter = data.data?.list;
        setData(filter);
        const total = resp.data.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false)

      });
  };




  useEffect(() => {
    ;
  }, []);
  return (
    <>


<CustomerLoader loader={ShowLoader} />
<ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        REJECT LOAN List
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-4 col-sm-4   d-flex input-container align-items-center mb-3  mb-lg-0">
                            <label className="small_label">Start Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="fromDate"
                              placeholder="from Date.."
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-lg-4 col-sm-4   d-flex align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label">End Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="toDate"
                              placeholder="to Date.."
                            />
                          </div>
                        </div>

                        <div className="min-w-175px d-flex gap-1">
                          <button
                            type="submit"
                            className="btn btn-dark  mx-sm-3"
                            id="kt_advanced_search_button_1"
                          >
                            Search
                          </button>
                          <button
                            type="reset"
                            onClick={() => LoanRejectedCustomerList()}
                            className="btn btn-secondary"
                            id="kt_advanced_search_button_1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                      REJECT LOAN  
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Customer
                      </span>
                    </h3>
                    <div class="card-title"></div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-300px">ID Photo</th>
                            <th className="min-w-125px">Loan Amount</th>

                            <th className="min-w-150px">Interest</th>
                            <th className="min-w-150px"> Interest Rate</th>
                            <th className="min-w-100px">Time Period</th>
                            <th className="min-w-100px">Total Amount</th>
                            <th className="min-w-100px">
                            Reject Reason
                            </th>
                            <th className="min-w-100px ">Status</th>
                                                      </tr>
                        </thead>

                        <tbody>
                          {userdetails?.map((item) => (
                            <tr>
                              <td>
                                {" "}
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-45px me-5">
                                    <img src={item?.agent_details?.Image} alt="" />
                                  </div>
                                  <div class="d-flex justify-content-start flex-column">
                                    <a
                                      //   href={`/org-customer-view/${item._id}`}
                                      class="usertitle Acolor mb-2"
                                    >
                                      {" "}
                                      {item?.agent_details?.firstName}
                                    </a>
                                    <span class="font-icon mb-1">
                                      <span class="">
                                        <i class="fal fa-phone-volume"></i>
                                      </span>
                                      +{item?.agent_details?.phone}
                                    </span>

                                    <span class="font-icon mb-1">
                                      <span class="">
                                        <i class="fal fa-envelope"></i>
                                      </span>
                                      {item?.agent_details?.email}{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>{item?.loanAmount}</td>
                              <td>{item?.totalInterestAmount}</td>
                              <td>{item?.interestRate} %</td>
                              <td>{item?.loanDuration}</td>
                              <td>{item?.totalAmount}</td>
                              <td>{item?.rejectReason}</td>

                              {item.status == "pending" ? (
                                <>
                                  <td>
                                    <span className="badge capitalize-text badge-light-pending fs-5">
                                      {item.status}
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <span className="badge capitalize-text badge-light-info fs-5">
                                      {item.status}
                                    </span>
                                  </td>
                                </>
                              )}

                        
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-2"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
