import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
var jwt = require("jsonwebtoken");

function LoginSuperAgent() {

    const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);



  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");

  const login = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
   
    axios.post(`/v1/super-Agent/Login`, formData).then((res) => {
      let result = res.data;
    //   console.log("###" + result)
      if (result.status) {
        localStorage.setItem("SuperAgentChromepayToken", result.data);
        //localStorage.setItem("Agent", result.token);
      
        toast.success(result.msg);
        // navigate('/agent-dashbord')
        window.location="/Super-agent-dashbord";
        //;
        console.log(result);
      } else {
        toast.error(result.msg);
      }
    });
  };
  //-------model---------------///
  function handleShow() {
    setShow(true);
  }

  const AddFormData = async (e) => {
    e.preventDefault();
      let token = localStorage.getItem("SuperAgentChromepayToken");
    var decode1 = jwt.decode(token);
    let agentID = decode1.agentID;

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.post(`/v1/auth/ForgotPassword`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
   
      } else {
        toast.error(data.msg);
      }
    });
  };



  const AddFormData2 = async (e) => {
    e.preventDefault();
      let token = localStorage.getItem("SuperAgentChromepayToken");
    var decode1 = jwt.decode(token);
    let agentID = decode1.agentID;

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.post(`/v1/auth/ForgetPassVerifyOtp`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
         setShow1(true)
      } else {
        toast.error(data.msg);
      }
    });
  };

  ///-------------------------------------////

  const AddFormData1 = async (e) => {
    e.preventDefault();
      let token = localStorage.getItem("SuperAgentChromepayToken");
    var decode1 = jwt.decode(token);
    let agentID = decode1.agentID;

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.put(`/v1/auth/ForgotchangePass`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
      } else {
        toast.error(data.msg);
      }
    });
  };
  return (
    <div style={{backgroundImage: "url(assets_new/images/login.jpg)",backgroundPosition:"center", backgroundRepeat: "no-repeat",backgroundSize: "cover",height:"100vh"}}>
      <ToastContainer position="top-right" />
        <div class="super container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto" >
            
    <div class="card super-card0 border-0">
        <div class="row d-flex">
            <div class="col-lg-6">
                <div class="card1 pb-5">
                    <div class="row">
                        <img src="assets_new/images/logo.png" class="super-logo" />
                    </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 super border-line">
                        <img src="https://i.imgur.com/uNGdWHi.png" class="super-image" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="super-card2 card border-0 px-4 py-5 mt-5">
                    <div class="row mb-5 px-3">
                        <h2 class="mb-0 mr-4 mt-2">Log in </h2>
                        
                    </div>
                    <form  onSubmit={login}>
                  
                    <div class="row px-3 mb-5">
                        <label class="mb-3"><h6 class="mb-0 super text-sm">Email Address</h6></label>
                        <input class="mb-5" className='form-control' placeholder="Enter your email"
                          name="userName"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div class="row px-3 mb-5">
                        <label class="mb-3"><h6 class="mb-0 super text-sm">Password</h6></label>
                        <input type="password" className="form-control"
                          placeholder="Enter your password"
                          name="password"
                        
                          value={password}
                          onChange={(e) => setPass(e.target.value)} />
                    </div>
                    <div class="row px-3 mb-4 mt-5">
                        <div className='col-sm-6'>
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input id="chk1" type="checkbox" name="chk" class="custom-control-input" /> 
                            <label for="chk1" class="custom-control-label super text-sm" style={{marginLeft:"3px"}}>Remember me</label>
                        </div>
                        </div>
                        <div className='col-sm-6 text-end'>
                            
                        <a href="#" class="ml-auto mb-0 super text-sm" onClick={() => handleShow()}>Forgot Password ?</a>
                        </div>
                       
                    </div>
                    <div class="row mb-3 px-3">
                        <button type="submit" class="btn btn-blue text-center">Login</button>
                    </div>
                    <div class="row mb-4 px-3">
                      
                    </div>
                    </form>
                </div>
            </div>
        </div>
       
    </div>
</div>
<Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Recover Password </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="Submit"
              onClick={() => [setShow(false), setShow2(true)]}
            >
            
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>






      <Modal show={show2} onHide={() => setShow2(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Recover Password </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData2(e)}>
          <Modal.Body>

          <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>


            <div className="col-lg-12 ">
              <label className="mb-2">OTP</label>
              <input
                className="form-control"
                placeholder="Enter your OTP"
                type="number"
                name="otp"
             
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="Submit"
              onClick={() => [setShow(false),setShow2(false)]}
            >
            
          Otp 
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* //--------------------------- */}
      <Modal show={show1} onHide={() => setShow1(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Password Reset</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData1(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="col-lg-12 mt-3 ">
              <label className="mb-2" style={{ float: "left" }}>
                New Password
              </label>
              <input
                className="form-control"
                type="password"
                placeholder="Enter your password"
                name="newPassword"
              />
            </div>
            <div className="col-lg-12 mt-3 ">
              <label className="mb-2" style={{ float: "left" }}>
                {" "}
                Confirm Password
              </label>
              <input
                className="form-control"
                type="password"
                placeholder="Enter your password"
                name="confirmPassword"
              />
            </div>
         
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => setShow1(false)}
            >
              {" "}
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default LoginSuperAgent