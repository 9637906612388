import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../../SuperAgent/Header";
import Sidebar from "../../SuperAgent/Sidebar";
const SuperAgentPrivateRoute = () => {
  const isAuthenticated = !!localStorage.getItem("SuperAgentChromepayToken");
  const location = useLocation();

  return isAuthenticated ? (
    <>
      <Header />
      <Outlet />
      <Sidebar />
    </>
  ) : (
    <Navigate to="/superlogin" />
  );
};

export default SuperAgentPrivateRoute;
