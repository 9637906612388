import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  toPng,
  toJpeg,
  toSvg,
  toCanvas,
  toBlob,
  getFontEmbedCSS,
} from "html-to-image";
var jwt = require("jsonwebtoken");

function MyPaidloanDetails() {
  const { _id } = useParams("");
  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;

  //  console.log("@",_id)
  const [pageCount, setpageCount] = useState("");

  let ID = localStorage.getItem("ID");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [data, setData] = useState([]);
  const [Paidloan, setPaidLoan] = useState([]);

  const [Details, setDetails] = useState("");

  const [emidata, setEmiData] = useState("");
  const [selectedOption, setSelectedOption] = useState("ALL");

  const limit = 10;
  function handleShow(item) {
    console.log("+++++" + item);
    setEmiData(item);
    setShow(true);
  }
  const [email, setEmail] = useState("");

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    let body = { loanId: _id, formData };
    console.log("Formvlaues === ", body);
    axios
      .post(`v1/super-Agent/get-superAgentLoanEmiDetails`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;

        return PaidloanDetail();
        const total = resp.data.totalRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const PaidloanDetail = async () => {
    await axios
      .post(
        `v1/super-Agent/get-paidEmisDetails`,
        { loanId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;

        const emi = data?.EMIS;
        console.log("@", emi);
        setPaidLoan(emi);
        const total = resp.data.totalRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const Superdetails = async () => {
    let body = { loanId: _id, status: selectedOption };
    await axios
      .post(`v1/super-Agent/get-superAgentLoanEmis`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setDetails(data);
        setData(data?.EMIS);
        console.log("data123", data);
        const total = resp.data.totalRow;
        // console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  useEffect(() => {
    Superdetails();
    PaidloanDetail();
    ;
  }, []);

  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    axios
      .post(`v1/super-Agent/get-superAgentLoanEmiDetails`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  const handleView = (_id) => {
    // console.log("----->DetailsTTTTTIIIIIDDDDD", _id)
    navigate(`/superagent/Myloan/Myemidetails/${_id}`);
  };

  const handlePay = (_id, loanId) => {
    const body = { emiId: _id, loanId: loanId };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to pay selected Emi ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Pay it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`v1/org/CRM/Pay-super-agent-emi`, body, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire("paid!", "Paid Emi Successfully", "success");
                return PaidloanDetail();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
      }
    });
  };

  const AddFormData = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios
      .post(`/v1/org/CRM/Pay-super-agent-emi`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        if (data.status) {
          toast.success(data.msg);
          setShow(false);

          // window.location.reload();
          return PaidloanDetail();
        } else {
          toast.error(data.msg);
        }
      });
  };

  // const formhandle = () => {
  //   setShow(false);
  //   setShow1(true);
  // };

  const GenerateInvoice = () => {
    // html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png', 1.0);
    // console.log("imgData" +imgData)
    // const pdf = new jsPDF({
    //   orientation: 'portrait',
    //   unit: 'pt',
    //   format: [612, 792]
    // });
    // pdf.internal.scaleFactor = 1;
    // const imgProps= pdf.getImageProperties(imgData);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    // pdf.save('invoice-001.pdf');
    //   const link = document.createElement('a');
    //     link.download = 'invoice.png'; // Set the desired file name and extension
    //      link.href = imgData;
    //     console.log("")
    //     link.click();
    //     console.log("link" +link)
    // });
    const element = document.getElementById("invoiceCapture");
    toPng(element)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "invoice.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error("Error saving image:", error);
      });
  };

  const handleAddLoanEmiReceipt = (item) => {
    // Sample static data for demonstration
    console.log({ item });
    const loanEmiData = {
      activityDate: Details?.agentId,
      custId: Details?._id,
      cName: `${Details?.agent_detail?.firstName} ${Details?.agent_detail?.lastName}`,
      cEmail: Details?.agent_detail?.email,
      loanId: item?.loanId,
      emiAmount: item?.emiAmount,
      interestRate: item?.interestRate,
      principalAmount: item?.loanAmount,
      totalAmount: item?.totalAmount,
      emiType: item?.scheduleType,
    };

    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the receipt heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Loan EMI Receipt") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the receipt heading
    doc.text("Loan EMI Receipt", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(loanEmiData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${loanEmiData.custId}`, 10, 80);
    doc.text(`Customer Name: ${loanEmiData.cName}`, 10, 90);
    doc.text(`Customer Email: ${loanEmiData.cEmail}`, 10, 100);

    // Add a separator line after customer details
    doc.setLineWidth(0.5);
    doc.line(10, 105, width - 10, 105); // horizontal line

    // Add the EMI details heading with left alignment
    doc.text("EMI Details", 10, 115);

    // Add the EMI details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Loan ID: ${loanEmiData.loanId}`, 10, 135);
    doc.text(`EMI Amount: ${loanEmiData.emiAmount} ETB`, 10, 145);
    doc.text(`Interest Rate: ${loanEmiData.interestRate} %`, 10, 155);
    doc.text(`Principal Amount: ${loanEmiData.principalAmount} ETB`, 10, 165);
    doc.text(`Total Amount: ${loanEmiData.totalAmount} ETB`, 10, 175);
    doc.text(`EMI Type: ${loanEmiData.emiType}`, 10, 185);

    // Save the PDF and initiate download
    doc.save("loan_emi_receipt.pdf");
  };

  // Call the function to create the demo receipt

  return (
    <>
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 ">
              <div class="col-lg-5">
                <div class="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                  {/* <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <div className="card-title m-0">
            <h3 className="fw-bold m-0">Detailsomer Detail</h3>
        </div>
    </div> */}
                  <div
                    class="card-body  pt-9 pb-8 "
                    style={{ padding: "2rem 1.25rem" }}
                  >
                    <div class="d-flex flex-wrap flex-sm-nowrap mb-0">
                      <div class="me-7 mb-4">
                        <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                          <img
                            src={
                              Details?.agent_detail?.Image ||
                              "/assets/img/male_avtar.png"
                            }
                            alt="image"
                          />
                          <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                        </div>
                      </div>

                      <div class="flex-grow-1">
                        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                          <div class="d-flex flex-column">
                            <div class="d-flex align-items-center mb-2">
                              <a
                                href="#"
                                class="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                              >
                                {Details?.agent_detail?.firstName}{" "}
                                {Details?.agent_detail?.lastName}
                              </a>
                              <a href="#">
                                <span class="svg-icon svg-icon-1 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                                      fill="white"
                                    ></path>
                                  </svg>
                                </span>
                              </a>
                            </div>

                            <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                              <a
                                href="#"
                                class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                              >
                                <span class="svg-icon svg-icon-4 me-1">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                      fill="currentColor"
                                    ></path>
                                    <rect
                                      x="7"
                                      y="6"
                                      width="4"
                                      height="4"
                                      rx="2"
                                      fill="currentColor"
                                    ></rect>
                                  </svg>
                                </span>
                                Super Agent
                              </a>
                              <a
                                href="#"
                                class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                              >
                                <span class="svg-icon svg-icon-4 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                                Ethiopian
                              </a>
                              <a
                                href="#"
                                class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                              >
                                <span class="svg-icon svg-icon-4 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                                {Details?.agent_detail?.email}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex flex-wrap flex-stack">
                          <div class="d-flex flex-column flex-grow-1 pe-8">
                            <div class="d-flex flex-wrap">
                              <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3">
                                <div class="d-flex align-items-center">
                                  <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x="13"
                                        y="6"
                                        width="13"
                                        height="2"
                                        rx="1"
                                        transform="rotate(90 13 6)"
                                        fill="currentColor"
                                      ></rect>
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <div
                                    class="fs-2 fw-bold counted"
                                    data-kt-countup="true"
                                    data-kt-countup-value={`${Details.loanAmount}`}
                                    data-kt-countup-prefix="$"
                                    data-kt-initialized="1"
                                  >
                                    {Details.loanAmount?.toFixed(2)}
                                  </div>
                                </div>

                                <div class="fw-semibold fs-6 text-gray-400">
                                  Loan Amount
                                </div>
                              </div>

                              <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div class="d-flex align-items-center">
                                  <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x="11"
                                        y="18"
                                        width="13"
                                        height="2"
                                        rx="1"
                                        transform="rotate(-90 11 18)"
                                        fill="currentColor"
                                      ></rect>
                                      <path
                                        d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>

                                  <div
                                    class="fs-2 fw-bold counted"
                                    data-kt-countup="true"
                                    data-kt-countup-value={`${Details?.totalAmount}`}
                                    data-kt-initialized="1"
                                  >
                                    {Details?.totalAmount?.toFixed(2)}
                                  </div>
                                </div>

                                <div class="fw-semibold fs-6 text-gray-400">
                                  Total Amount{" "}
                                </div>
                              </div>
                            </div>
                            <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                              <div class="d-flex justify-content-between w-75 mt-auto mb-2">
                                <span class="fw-semibold fs-6 text-gray-400">
                                  Interest Rate
                                </span>
                                <span class="fw-bold fs-6">
                                  {Details?.interestRate}%
                                </span>
                              </div>
                              <div class="h-5px mx-3 w-75 bg-light mb-3">
                                <div
                                  class="bg-success rounded h-5px "
                                  style={{
                                    width: `${Details?.interestRate}%`,
                                  }}
                                  role="progressbar"
                                  aria-valuenow={Details?.interestRate}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  aria-label={`Progress: ${Details?.interestRate}%`}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                  {/* <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                        <div className="card-title m-0">
                                            <h3 className="fw-bold m-0">Detailsomer Detail</h3>
                                        </div>
                                    </div> */}
                  <div class="card-body  pt-9 pb-10 ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Pay Method
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Pay Method"
                              value={Details?.repaymentMethod}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row pt-5">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Loan Type
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Loan Type"
                              value={Details?.typeOfLoan}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="row pt-5">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Time Period
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Time Period"
                              value={Details?.loanDuration}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row pt-5">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Approve Date
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Approve Date"
                              readOnly
                              value={
                                Details?.approveDate
                                  ? moment(Details?.approveDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="row">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Interest Rate
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Interest Rat"
                              value={`${Details?.interestRate}%`}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row pt-5">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Status
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Status"
                              value={Details?.status}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row pt-5">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Interest Amount
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Intrest Amount"
                              value={Details?.totalInterestAmount?.toFixed(2)}
                              readOnly
                            />
                          </div>
                        </div>
                        {/* <div className="row pt-5">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">Created Date</label>
                          <div className="col-lg-8 fv-row">
                            <input type="text" name="name" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Approve Date" value={loan.createdAt ? moment(loan.createdAt).format("DD-MM-YYYY"):""} readOnly />
                          </div>
                        </div> */}
                      </div>
                    </div>

                    {/* <div class="d-flex flex-wrap flex-sm-nowrap mb-3">

                                            <div class="me-7 mb-4">
                                                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                    <img src="../assets_new/images/jassica.PNG" alt="image" />
                                                    <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px">
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="flex-grow-1">

                                                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">

                                                    <div class="d-flex flex-column">

                                                        <div class="d-flex align-items-center mb-2">
                                                            <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">Jessica
                                                                Chinara</a>
                                                            <a href="#">

                                                                <span class="svg-icon svg-icon-1 svg-icon-primary">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="currentColor"></path>
                                                                        <path d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                                                                    </svg>
                                                                </span>

                                                            </a>

                                                        </div>


                                                        <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                            <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">

                                                                <span class="svg-icon svg-icon-4 me-1">
                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path>
                                                                        <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path>
                                                                        <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                                    </svg>
                                                                </span>
                                                                Farmer
                                                            </a>
                                                            <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">

                                                                <span class="svg-icon svg-icon-4 me-1">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="currentColor"></path>
                                                                        <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="currentColor"></path>
                                                                    </svg>
                                                                </span>
                                                                Ethiopia
                                                            </a>
                                                            <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">

                                                                <span class="svg-icon svg-icon-4 me-1">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                                                                        <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                                                                    </svg>
                                                                </span>
                                                                jessica@gmail.com
                                                            </a>
                                                        </div>

                                                    </div>

                                                 </div>


                                                <div class="d-flex flex-wrap flex-stack">

                                                    <div class="d-flex flex-column flex-grow-1 pe-8">

                                                        <div class="d-flex flex-wrap">

                                                            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                                <div class="d-flex align-items-center">

                                                                    <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor"></rect>
                                                                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor"></path>
                                                                        </svg>
                                                                    </span>

                                                                    <div class="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$" data-kt-initialized="1">$4,500</div>
                                                                </div>


                                                                <div class="fw-semibold fs-6 text-gray-400">
                                                                    Transaction Amount</div>

                                                            </div>

                                                            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                                <div class="d-flex align-items-center">

                                                                    <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                                                            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"></path>
                                                                        </svg>
                                                                    </span>

                                                                    <div class="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="75" data-kt-initialized="1">75</div>
                                                                </div>


                                                                <div class="fw-semibold fs-6 text-gray-400">
                                                                    Total Transaction </div>

                                                            </div>

                                                        </div>
                                                        <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                                            <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                <span class="fw-semibold fs-6 text-gray-400">Identity Trust Level
                                                                </span>
                                                                <span class="fw-bold fs-6">50%</span>
                                                            </div>
                                                            <div class="h-5px mx-3 w-100 bg-light mb-3">
                                                                <div class="bg-success rounded h-5px w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>

                                            </div>

                                        </div> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                  <div className="card-body container-xxl pt-10 pb-8"></div>
                  <div class="card-rounded  d-flex flex-stack flex-wrap pt-0 p-5 pb-5">
                    <ul
                      class="nav flex-wrap border-transparent fw-bold"
                      role="tablist"
                    >
                      <li class="nav-item my-1" role="presentation">
                        <a
                          role="tab"
                          aria-selected="true"
                          data-bs-toggle="tab"
                          href="#overview"
                          class="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 "
                        >
                          {" "}
                          Pending Emi's
                        </a>
                      </li>

                      <li class="nav-item my-1" role="presentation">
                        <a
                          role="tab"
                          aria-selected="true"
                          data-bs-toggle="tab"
                          href="#documents"
                          class="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 "
                        >
                          Paid Emi's
                        </a>
                      </li>

                      {/* <li class="nav-item my-1" role="presentation">

        <a role="tab" aria-selected="true" data-bs-toggle="tab" href="#land" class="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase">Land Registration</a>
    </li> */}

                      {/* <li class="nav-item my-1">

        <a role="tab" aria-selected="true" data-bs-toggle="tab" href="#finance" class="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase">
            Financial Activities</a>
    </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div class="tab-content mt-4" style={{ padding: "0px" }}>
                  <div
                    class="tab-pane fade active show"
                    id="overview"
                    role="tabpanel"
                  >
                    <div class="row">
                      <div className="col-md-12">
                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                          <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                EMI PENDING LIST
                              </span>
                              <span className="text-muted mt-1 fw-semibold fs-7"></span>
                            </h3>
                          </div>

                          <div className="card-body py-3">
                            <div className="table-responsive">
                              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                  <tr className="fw-bold text-muted th-title">
                                    <th className="min-w-125px">EMI-ID</th>
                                    {/* <th className="min-w-125px">Name</th> */}
                                    <th className="min-w-150px">
                                      Total Amount
                                    </th>
                                    <th className="min-w-150px">
                                      Interest Rate{" "}
                                    </th>
                                    <th className="min-w-100px">EMI Number</th>
                                    <th className="min-w-100px">EMI Amount</th>
                                    <th className="min-w-100px">EmiPay Date</th>
                                    <th className="min-w-125px">
                                      Remaining Amount
                                    </th>
                                    {/* <th className="min-w-125px">CreatedAt</th> */}
                                    <th className="min-w-125px">Loan Amount</th>
                                    <th className="min-w-125px">
                                      Intrest Amount
                                    </th>
                                    <th className="min-w-100px ">PayStatus</th>
                                    <th className="min-w-100px text-end">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.length === 0 ? (
                                    <tr>
                                      <td colSpan="11" className="text-center">
                                        {/* <img src="../../assets_new/images/tabbb.jpg"  /> */}
                                        <h1 className="p-5">
                                          No Pending Loans
                                        </h1>
                                      </td>
                                    </tr>
                                  ) : (
                                    data?.map((item, index) => (
                                      <tr key={index}>
                                        <td>{item._id}</td>
                                        {/* <td>{item.fullname}</td> */}
                                        <td>{item.totalAmount?.toFixed(2)}</td>
                                        <td>
                                          <span>{`${item.interestRate}%`}</span>{" "}
                                        </td>
                                        <td>{item.emiNumber}</td>
                                        <td>{item.emiAmount?.toFixed(2)}</td>
                                        <td>
                                          {moment(item.emiPayDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {item.remainingAmount?.toFixed(2)}
                                        </td>
                                        {/* <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td> */}
                                        <td>{item.loanAmount?.toFixed(2)}</td>
                                        <td>
                                          {item.intrestAmount?.toFixed(2)}
                                        </td>
                                        {item.payStatus == "PENDING" ? (
                                          <>
                                            <td>
                                              <span className="capitalize-text badge badge-light-pending fs-5">
                                                {item.payStatus}
                                              </span>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>
                                              <span className="badge badge-light-info fs-5 capitalize-text">
                                                {item.payStatus}
                                              </span>
                                            </td>
                                          </>
                                        )}

                                        <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                            <button
                                              onClick={(e) => {
                                                handleView(item._id);
                                              }}
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              title="View Detail"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  )}

                                  {/* <tr>
                                      <td>##41e8bd3f3ca</td>
                                      <td> Virendra Shilpkar </td>
                                      <td> virendra@gmail.com </td>
                                      <td><span>+221 123-456-7840</span> </td>
                                      <td>05/06/1980</td>
                                      <td>05/08/2020</td>
                                      <td>Ethiopia</td>
                                      <td>
                                      <td><span className="badge badge-light-info fs-5 capitalize-text">Verified</span></td> 
                                      </td>
                                      <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                              <button
                                                  className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Suspend ">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-minus-circle fs-4"></i>
                                                  </span>
                                              </a>
                                              <button
                                                  className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Password Reset ">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fas fa-repeat-alt"></i>
                                                  </span>
                                              </a>
                                              <a href='organization_detail.html'
                                                  className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="View Detail">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-eye fs-4"></i>
                                                  </span>

                                              </a>
                                              <a href='#'
                                                  className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Delete">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-trash-alt"></i>
                                                  </span>

                                              </a>
                                              <a href='#'
                                                  className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Disable OTP">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-eye-slash"></i>
                                                  </span>

                                              </a>
                                          </div>
                                      </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>

                            <div className="col-lg-12 mt-2 text-end">
                              <ReactPaginate
                                PreviousLabel={"Previous"}
                                NextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                PreviousClassName={"page-item"}
                                PreviousLinkClassName={"page-link pagestyle"}
                                NextClassName={"page-item"}
                                NextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="documents" role="tabpanel">
                    <div class="row">
                      <div className="col-md-12">
                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                          <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                EMI PAID LIST{" "}
                              </span>
                              <span className="text-muted mt-1 fw-semibold fs-7"></span>
                            </h3>
                          </div>

                          <div className="card-body py-3">
                            <div className="table-responsive">
                              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                  <tr className="fw-bold text-muted th-title">
                                    <th className="min-w-125px">EMI-ID</th>
                                    {/* <th className="min-w-125px">Name</th> */}
                                    <th className="min-w-150px">
                                      Total Amount
                                    </th>
                                    <th className="min-w-150px">
                                      Interest Rate{" "}
                                    </th>
                                    <th className="min-w-100px">EMI Number</th>
                                    <th className="min-w-100px">EMI Amount</th>
                                    <th className="min-w-100px">EmiPay Date</th>
                                    <th className="min-w-125px">
                                      Remaining Amount
                                    </th>
                                    {/* <th className="min-w-125px">CreatedAt</th> */}
                                    <th className="min-w-125px">Loan Amount</th>
                                    <th className="min-w-125px">
                                      Intrest Amount
                                    </th>
                                    <th className="min-w-100px ">PayStatus</th>

                                    <th className="min-w-100px text-center">
                                      Invoice
                                    </th>
                                    <th className="min-w-100px text-center">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {Paidloan?.length === 0 ? (
                                    <tr>
                                      <td colSpan="11" className="text-center">
                                        {/* <img src="../../assets_new/images/tabbb.jpg"  /> */}
                                        <h1 className="p-5">No Paid Loans</h1>
                                      </td>
                                    </tr>
                                  ) : (
                                    Paidloan?.map((item, index) => (
                                      <tr key={index}>
                                        <td>{item._id}</td>
                                        {/* <td>{item.fullname}</td> */}
                                        <td>{item.totalAmount?.toFixed(2)}</td>
                                        <td>
                                          <span>{`${item.interestRate}%`}</span>{" "}
                                        </td>
                                        <td>{item.emiNumber}</td>
                                        <td>{item.emiAmount?.toFixed(2)}</td>
                                        <td>
                                          {moment(item.emiPayDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {item.remainingAmount?.toFixed(2)}
                                        </td>
                                        {/* <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td> */}
                                        <td>{item.loanAmount?.toFixed(2)}</td>
                                        <td>
                                          {item.intrestAmount?.toFixed(2)}
                                        </td>
                                        {item.payStatus == "PENDING" ? (
                                          <>
                                            <td>
                                              <span className="capitalize-text badge badge-light-pending fs-5">
                                                {item.payStatus}
                                              </span>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>
                                              <span className="badge badge-light-info fs-5 capitalize-text">
                                                {item.payStatus}
                                              </span>
                                            </td>
                                          </>
                                        )}
                                        <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                            <button
                                              onClick={(e) =>
                                                handleAddLoanEmiReceipt(item)
                                              }
                                              className="btn btn-primary btn-active-light-primary me-2"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              title="Download Invoice"
                                            >
                                              Download Invoice
                                            </button>
                                          </div>
                                        </td>
                                        <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                            <button
                                              onClick={(e) => {
                                                handleView(item._id);
                                              }}
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              title="View Detail"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  )}

                                  {/* <tr>
                                      <td>##41e8bd3f3ca</td>
                                      <td> Virendra Shilpkar </td>
                                      <td> virendra@gmail.com </td>
                                      <td><span>+221 123-456-7840</span> </td>
                                      <td>05/06/1980</td>
                                      <td>05/08/2020</td>
                                      <td>Ethiopia</td>
                                      <td>
                                      <td><span className="badge badge-light-info fs-5 capitalize-text">Verified</span></td> 
                                      </td>
                                      <td>
                                         <div className="d-flex justify-content-center flex-shrink-0">
                                              <button
                                                  className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Suspend ">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-minus-circle fs-4"></i>
                                                  </span>
                                              </a>
                                              <button
                                                  className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Password Reset ">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fas fa-repeat-alt"></i>
                                                  </span>
                                              </a>
                                              <a href='organization_detail.html'
                                                  className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="View Detail">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-eye fs-4"></i>
                                                  </span>

                                              </a>
                                              <a href='#'
                                                  className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Delete">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-trash-alt"></i>
                                                  </span>

                                              </a>
                                              <a href='#'
                                                  className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  data-bs-trigger="hover" title="Disable OTP">
                                                  <span className="svg-icon svg-icon-3">
                                                      <i className="fad fa-eye-slash"></i>
                                                  </span>

                                              </a>
                                          </div>
                                      </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>

                            <div className="col-lg-12 mt-2 text-end">
                              <ReactPaginate
                                PreviousLabel={"Previous"}
                                NextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                PreviousClassName={"page-item"}
                                PreviousLinkClassName={"page-link pagestyle"}
                                NextClassName={"page-item"}
                                NextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="tab-pane fade" id="land" role="tabpanel">


    <div class="row">
      
    </div>
</div> */}

                  {/* <div class="tab-pane fade" id="finance" role="tabpanel">

    <div class="row">
       

    </div>
</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Emi Pay Details </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <input
                className="form-control"
                placeholder="Enter your email"
                type="hidden"
                name="emiId"
                value={emidata._id}
              />
              <input
                className="form-control"
                placeholder="Enter your email"
                type="hidden"
                name="loanId"
                value={emidata.loanId}
              />
              <div className="mb-3">
                <label className="mb-2">Emi Number</label>
                <input
                  className="form-control"
                  placeholder="Enter your email"
                  type="text"
                  // name="emiNumber"
                  value={emidata.emiNumber}
                />
              </div>
              <div className="mb-3">
                <label className="mb-2 bt-2">Emi Amount </label>
                <input
                  className="form-control"
                  placeholder="Enter your email"
                  type="text"
                  name="amount"
                  value={emidata.emiAmount}
                />
              </div>

              <div className="mb-3">
                <label className="mb-2">Intrest</label>
                <input
                  className="form-control"
                  placeholder="Enter your email"
                  type="text"
                  // name="interestRate"
                  value={emidata.interestRate}
                />
              </div>
              <div className="mb-3">
                <label className="mb-2">Emi Pay Amount</label>
                <input
                  className="form-control"
                  placeholder="Enter your email"
                  type="text"
                  // name="amount"
                  value={emidata.totalAmount}
                />
              </div>
              <div className="mb-3">
                <label className="mb-2">Pay Date</label>
                <input
                  className="form-control"
                  placeholder="Enter your email"
                  type="text"
                  // name="emiPayDate"
                  value={moment(emidata.emiPayDate).format("DD-MM-YYYY")}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              // onClick={() => formhandle()}
            >
              {" "}
              Pay Emi
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* <!-- Modal --> */}
      <Modal show={show1} onHide={() => setShow1(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Emi Pay Details </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData(e)}>
          <Modal.Body>
            {/* <div className="col-lg-12 " id="invoiceCapture">
            <input className="form-control" placeholder="Enter your email" type="hidden" name="emiId" value={emidata._id}/>
            <input className="form-control" placeholder="Enter your email" type="hidden" name="loanId" value={emidata.loanId}/>
            <div className="mb-3">
             <label className="mb-2">Emi Number</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="text"
                // name="emiNumber"
                value={emidata.emiNumber}
               
              />
             </div>
              <div className="mb-3">
              <label className="mb-2 bt-2">Emi Amount </label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="text"
                name="amount"
                value={emidata.emiAmount}
               
              />
              </div>
             
             <div className="mb-3">
             <label className="mb-2">Intrest</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="text"
                // name="interestRate"
                value={emidata.interestRate}
               
              />
             </div>
             <div className="mb-3">
             <label className="mb-2">Emi Pay Amount</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="text"
                // name="amount"
                value={emidata.totalAmount}
               
              />
             </div>
             <div className="mb-3">
             <label className="mb-2">Pay Date</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="text"
                // name="emiPayDate"
                value={moment(emidata.emiPayDate).format("DD-MM-YYYY")}
               
              />
             </div>
            </div> */}
            <div id="invoiceCapture">
              <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
                <div className="w-100">
                  <h4 className="fw-bold my-2">dfdfdf</h4>
                  <h6 className="fw-bold text-secondary mb-1">
                    Invoice #: 12345
                  </h6>
                </div>
                <div className="text-end ms-4">
                  <h6 className="fw-bold mt-1 mb-2">Amount&nbsp;Due:</h6>
                  <h5 className="fw-bold text-secondary"> 500</h5>
                </div>
              </div>
              <div className="p-5 bg-light">
                <Row className="mb-4">
                  <Col md={4}>
                    <div className="fw-bold">Billed to:</div>
                    <div>name</div>
                    <div>address</div>
                    <div>email</div>
                  </Col>
                  <Col md={4}>
                    <div className="fw-bold">Billed From:</div>

                    <div>name</div>
                    <div>address</div>
                    <div>email</div>
                  </Col>
                  <Col md={4}>
                    <div className="fw-bold mt-2">Date Of Issue:</div>
                    <div>20-02-2023</div>
                  </Col>
                </Row>
                <Table className="mb-0">
                  <thead>
                    <tr>
                      <th>QTY</th>
                      <th>DESCRIPTION</th>
                      <th className="text-end">PRICE</th>
                      <th className="text-end">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    return (
                    <tr>
                      <td style={{ width: "70px" }}>50</td>
                      <td>50</td>
                      <td className="text-end" style={{ width: "100px" }}>
                        50
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        50
                      </td>
                    </tr>
                    );
                  </tbody>
                </Table>
                <Table>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        SUBTOTAL
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        50
                      </td>
                    </tr>

                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        TAX
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        50
                      </td>
                    </tr>

                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        DISCOUNT
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        50
                      </td>
                    </tr>

                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        TOTAL
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        total
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <div className="bg-light py-3 px-4 rounded">Notarised</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={GenerateInvoice}
              // onClick={() => setShow1(false)}
            >
              {" "}
              Download
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default MyPaidloanDetails;
