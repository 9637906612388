import { useEffect, useState } from "react";
import "./App.css";

import Webcamera from "./Agent/Webcamera";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Home from './Chrome/Home';
// import Audits_branches from './Chrome/Audits_branches';
// import Awaiting_confirmation from './Chrome/awaiting_confirmation';
// import Awaiting_verification from './Chrome/Awaiting_verification';
// import Bank_account_settings from './Chrome/bank_account_settings';
// import Bill_payment_smart_routing from './Chrome/Bill_payment_smart_routing';
// import Customer_settings from './Chrome/Customer_settings';
// import Customer from './Chrome/Customer';
// import Customer_detail from './Chrome/Customer_detail';
// import Customer_send_message from './Chrome/Customer_send_message';
// import Customer_transa_infor from './Chrome/Customer_transa_infor';
// import Customer_transaction_reports from './Chrome/Customer_transaction_reports';
// import Manage_charges from './Chrome/Manage_charges';
// import Default_chrges_setup from './Chrome/Default_chrges_setup';
// import Paycenter_commission_manager_default from './Chrome/Paycenter_commission_manager_default';
// import Dynamic_field_list from './Chrome/Dynamic_field_list';
// import Email_settings from './Chrome/Email_settings';
// import Exchange_rates_list from './Chrome/Exchange_rates_list';
// import Exchange_rates from './Chrome/Exchange_rates';
// import Recurring_charge_fee from './Chrome/Recurring_charge_fee';
// import Prepaid_voucher_batch_history from './Chrome/Prepaid_voucher_batch_history';
// import Supported_services_settings from './Chrome/Supported_services_settings';
// import Manage_api_customers from './Chrome/Manage_api_customers';
// import Manage_transactions_history from './Chrome/Manage_transactions_history';
// import Search_transactions_detail from './Chrome/Search_transactions_detail';
// import Search_transactions from './Chrome/Search_transactions';
// import Organization from './Chrome/Organization';
// import Organization_detail from './Chrome/Organization_detail';
// import Payments_awaiting_processing from './Chrome/Payments_awaiting_processing';
// import Security_settings from './Chrome/Security_settings';

// import AutoAddress from './Agent/components/AutoAddress';

//import jk from './Chrome/demo/Index';

// import C_pay  from './Chrome/C_pay';
// import Digital_user_detail  from './Chrome/Digital_user_detail';
// import OrganizationList from './Chrome/OrganizationList';
// import OrganizationNewDetail from './Chrome/OrganizationNewDetail';

// import Add_organization from './Chrome/Add_organization';
// import Add_customer from './Chrome/Add_customer';
// import Logout from './Chrome/Logout';
// import Protected from './Protected';
// import Customer_3 from './Chrome/Customer_3';
// import Manage_partner_centers from './Chrome/Manage_partner_centers';
// import Register_pay_centers_agent from './Chrome/Register_pay_centers_agent';
// import Edit_api_pay_centers from './Chrome/Edit_api_pay_centers';
// import Aaa_jk from './Chrome/Aaa_jk';
// import Check_box_malti from './Chrome/sub_com/Check_box_malti';

//---------------------Admin--------------//

import Login from "./Admin/Login";
import Otp_login from "./Admin/Otp_login";
import AdminDashbord from "./Admin/AdminDashbord";

//---------------------Admin--------------//

////////new page added Organization
import OrganizationDashboard from "./Organization/Organization";
import AddCustomer from "./Organization/AddCutstomer";
import OtpCustomer from "./Organization/OtpCustomer";
import SuccessCustomerModal from "./Organization/SuccessCustomerModal";
import OrgLogin from "./Organization/OrgLogin";
import CustomersList from "./Organization/CustomersList";
import CustomerTranstionList from "./Organization/CustomerTranstionList";
import AdminCustomer from "./Admin/AdminCustomer";
import ListCustomer from "./Admin/ListCustomer";
import TransactionsList from "./Admin/TransactionsList";
import ListOrgnization from "./Admin/ListOrgnization";
import OrganizationAdd from "./Admin/OrgnizationAdd";
import AgentAdd from "./Organization/AgentAdd";
import AgentList from "./Organization/AgentList";
// import updateLicenses from './Organization/updateLicenses'

import AgentDashbord from "./Agent/AgentDashbord";
import LoginAgent from "./Agent/LoginAgent";
import CustomerAgentAdd from "./Agent/CustomerAgentAdd";
import AgentCustomerList from "./Agent/AgentCustomerList";
import AgentCustomerOtp from "./Agent/AgentCustomerOtp";
import AgentTransactionList from "./Agent/AgentTransactionList";
import AgentChangePassword from "./Agent/AgentChangePassword";
import AdminChangePassword from "./Admin/AdminChangePassword";
import OrgChangePassword from "./Organization/OrgChangePassword";
import AdminSetting from "./Admin/AdminSetting";
import IpAddress from "./Admin/IpAddress";
import CustomerView from "./Admin/CustomerView";
import TransactionView from "./Admin/TransactionView";
import OrgnazationView from "./Admin/OrgnazationView";
import CustmerViewOrg from "./Organization/CustmerViewOrg";
import TransctionViewOrg from "./Organization/TransctionViewOrg";
import AgentViewOrg from "./Organization/AgentViewOrg";
import CutomerViewAgent from "./Agent/CutomerViewAgent";
import TransactionViewAgent from "./Agent/TransactionViewAgent";
import AddBank from "./Organization/AddBank";
import AgentAddAccount from "./Agent/AgentAddAccount";
import AgentAccountList from "./Agent/AgentAccountList";
import MyProfile from "./Admin/MyProfile";
import AgentMyProfile from "./Agent/AgentMyProfile";
import MyProfileOrg from "./Organization/MyProfileOrg";
import OtpOrg from "./Organization/OtpOrg";
import AgentTransactionLimit from "./Admin/AgentTransactionLimit";
import AdminCustomerOtp from "./Admin/AdminCustomerOtp";
import DigitalID from "./Admin/DigitalID";
import AllDigitalID from "./Admin/AllDigitalID";
import CustomerBlockList from "./Admin/CustomerBlockList";
import OrgnizationBlockList from "./Admin/OrgnizationBlockList";
import AgentBlocskList from "./Admin/AgentBlocskList";
import SubadminAdd from "./Admin/SubadminAdd";
import AddRoles from "./Admin/AddRoles";
import UserCustView from "./Admin/UserCustView";

import SetupFees from "./Admin/SetupFees";
import PenddingDID from "./Admin/PenddingDID";
import AgentReport from "./Admin/AgentReport";
import AgentReportView from "./Admin/AgentReportView";
import OrganzationReport from "./Admin/OrganzationReport";
import DIDReport from "./Admin/DIDReport";
import SubAdminList from "./Admin/SubAdminList";
import SubAdminView from "./Admin/SubAdminView";
import Add_admin_document from "./Admin/Add_admin_document";
import RemaningLicenses from "./Organization/RemaningLicenses";
import AgentAdminAdd from "./Admin/AgentAdminAdd";
import AgentAdminList from "./Admin/AgentAdminList";
import CommisionList from "./Admin/CommisionList";
import AgentCommisionList from "./Agent/AgentCommisionList";
import AgentDIDApprovel from "./Agent/AgentDIDApprovel";
import CustmerBankDetail from "./Admin/CustmerBankDetail";
import LicensesOrganzation from "./Admin/LicensesOrganzation";
import performance_repost from "./Admin/performance_repost";
import OrganzationPerfomance from "./Admin/OrganzationPerfomance";
import AgentBlockedDIDs from "./Agent/AgentBlockedDIDs";
import ExportCustomerDIDs from "./Agent/Component/ExportCustomerDIDs";
import ExportCommissionList from "./Agent/Component/ExportCommissionList";
import ExportBlockedList from "./Agent/Component/ExportBlockedList";
import AdminExportCustList from "./Admin/ExportComponent/AdminExportCustList";
import ExportOrgCustList from "./Admin/ExportComponent/ExportOrgCustList";
import ExportAdminBlockedList from "./Admin/ExportComponent/ExportAdminBlockedList";
import OrgCustExport from "./Organization/ExportComponent/OrgCustExport";
import AllOrgnReport from "./Admin/AllOrgnReport";
import LoanApply from "./Organization/LoanComponent/LoanApply";
import CustomerLoanDashboard from "./Organization/LoanComponent/CustomerLoanDashboard";
import LongApply from "./Agent/LoanApply";
import LoanPassList from "./Organization/LoanComponent/LoanPassList";
import LoanPassDashbord from "./Organization/LoanComponent/LoanPassDashbord";
import Customerloanlist from "./Agent/loanlist/Customerloanlist";
import Loanpasslist from "./Agent/loanlist/Loanpasslist";
import CardScore from "./Organization/CreditScoreComponent/CardScore";
import AgentLoanPassDashbord from "./Agent/loanlist/AgentLoanPassDashbord";
import AgentCustLink from "./Agent/LinkComponent/AgentCustLink";
import OrgCustLink from "./Organization/LinkComponent/OrgCustLink";
import AdminCustLink from "./Admin/LinkComponent/AdminCustLink";
import Scanner from "./Agent/Scanner";
import AdminLogsList from "./Admin/LogsComponent/AdminLogsList";
import OrgLogsList from "./Admin/LogsComponent/OrgLogsList";
import AgentLogsList from "./Organization/LogsComponent/AgentLogsList";
import Agentuserwallet from "./Agent/Wallet/Agentuserwallet";
import AgentLatestTransaction from "./Agent/Wallet/AgentLatestTransaction";
import OrgWallet from "./Organization/orgwallet/OrgWallet";
import Orgwebcamera from "./Organization/Orgwebcamera";
import OrgLatesttransaction from "./Organization/orgwallet/OrgLatesttransaction";
import Agent_Performance_repost from "./Organization/Agent_Performance_repost";
import CustomerLogin from "./Customers/CustomerLogin";
import OtpCust from "./Customers/OtpCust";
import CustomerDashbord from "./Customers/CustomerDashbord";
import OrgnzationCustList from "./Customers/OrgnizationComponent/OrgnzationCustList";
import TransactionCustList from "./Customers/TransactionComponent/TransactionCustList";
import CustomerWallet from "./Customers/Wallet/CustomerWallet";
import FuseWallet from "./Customers/Wallet/FuseWallet";
import CustomerLoanApply from "./Customers/loanprocess/CustomerLoanApply";
import Agent_customer_face_add from "./Agent/Agent_customer_face_add";
import Orginization_customer_face_add from "./Organization/Orginization_customer_face_add";
import Customercreateddemo from "./Agent/Customercreateddemo";
import AddCustomerdemo from "./Organization/AddCustomerdemo";
import AdminCustomerDemo from "./Admin/AdminCustomerDemo";
import Admin_customer_face_Add from "./Admin/Admin_customer_face_Add";
import OrgcustomerBlocklist from "./Organization/OrgcustomerBlocklist";
import OrgDigitalId from "./Organization/OrgDigitalId";
import OrdAgentReportView from "./Organization/OrdAgentReportView";
import ORGAgentReportView_performe from "./Organization/ORGAgentReportView_performe";
import Orgviewagent_performance from "./Organization/Orgviewagent_performance";
import Orgselfreport from "./Organization/Orgselfreport";
import Orgdeleteuse from "./Organization/Orgdeleteuse";
import OrgBlocklist from "./Organization/OrgBlocklist";
import Exportorgblocklist from "./Organization/Exportorgblocklist";
import AgentOrgTransactionlimit from "./Organization/AgentOrgTransactionlimit";
import Admin_user_profile_View from "./Admin/Admin_user_profile_View";
import EmpolyList from "./Admin/EmpolyList";
import Agentlist from "./Admin/Agentlist";
import EmpolyeesRegister from "./Organization/EmpolyeesRegister";
import EmpolyListORG from "./Organization/EmpolyList";
import Registerempolyees from "./Admin/Registerempolyees";
import Header from "./Organization/Header";
import Sidebar from "./Organization/Sidebar";
import AgentHeader from "./Agent/Header";
import AgentSidebar from "./Agent/Sidebar";
import AdmitHeader from "./Admin/Header";
import AdmitSidebar from "./Admin/Sidebar";
import Createadmin from "./Organization/Createadmin";
import Viewadmin from "./Organization/Viewadmin";
import Blockagentlist from "./Organization/Blockagentlist";
import UpdateLicence from "./Organization/UpdateLicence";
import ImageReader from "./Admin/ImageReader";
import CreateAgent from "./Organization/SuperAgent/CreateAgent";
import SuperAgentList from "./Organization/SuperAgent/SuperAgentList";
import SuperAgentView from "./Organization/SuperAgent/SuperAgentView";
import SuperAgentBlockList from "./Organization/SuperAgent/SuperAgentBlockList";
import SuperAgentWallet from "./Organization/SuperAgent/SuperAgentWallet";
import SuperAgentWalletHistory from "./Organization/SuperAgent/SuperAgentWalletHistory";
import SuperAgentLogin from "./SuperAgent/SuperAgentLogin";
import SuperAgentSidebar from "./SuperAgent/Sidebar";
import SuperAgentHeader from "./SuperAgent/Header";
import SuperAgentDashboard from "./SuperAgent/SuperAgentDashboard";
import RegisterCustomer from "./SuperAgent/CRM/RegisterCustomer";
import RegisterCustomer2 from "./SuperAgent/CRM/RegisterCustomer2";
import OtpVerifyCutomer from "./SuperAgent/CRM/OtpVerifyCutomer";
import SuperAgentCustomerList from "./SuperAgent/CRM/SuperAgentCustomerList";
import SuperAgentCustomerBlock from "./SuperAgent/CRM/SuperAgentCustomerBlock";
import SuperAgentAwatingList from "./SuperAgent/CRM/SuperAgentAwatingList";
import SuperAgentWalletList from "./SuperAgent/WalletManagment/SuperAgentWalletList";
import SuperAgentLoanPass from "./SuperAgent/LoanManagment/SuperAgentLoanPass";
import OcrHistoryList from "./Admin/LogsComponent/KachaLogs/OcrHistoryList";
import FaceHistoryList from "./Admin/LogsComponent/KachaLogs/FaceHistoryList";
import NotificationView from "./Admin/NotificationComponent/NotificationView";
import LicenseRequest from "./Admin/NotificationComponent/LicenseRequest";
import Performance_repost from "./Admin/performance_repost";
import AgentCustExport from "./Organization/ExportComponent/AgentCustExport";
import SuperAgentloanApply from "./SuperAgent/LoanManagment/SuperAgentloanApply";
import Applyforloan from "./SuperAgent/LoanManagment/Applyforloan";
import SupercustomerView from "./SuperAgent/CRM/SupercustomerView";
import Pendingloan from "./SuperAgent/LoanManagment/Pendingloan";
import AddAgentbysuper from "./SuperAgent/agentManagment/AddAgentbysuper";
import AgentsuperList from "./SuperAgent/agentManagment/AgentsuperList";
import SuperAgentLoanDetails from "./SuperAgent/LoanManagment/SuperAgentLoanDetails";
import LoanApplayAgent from "./Agent/loanlist/LoanApplayAgent";
import AgentLoanEmiDetail from "./Agent/loanlist/AgentLoanEmiDetail";

import SuperAgentSavingsList from "./SuperAgent/SavingsManagment/SuperAgentSavingsList";
import SuperAgentAddLoan from "./Organization/SuperAgent/SuperAgentAddLoan";
import SuperAgentLoanList from "./Organization/SuperAgent/SuperAgentLoanList";
import SuperAgentLoanDetailsOrg from "./Organization/SuperAgent/SuperAgentLoanDetailsOrg";
import SuperAgentEmiDetails from "./Organization/SuperAgent/SuperAgentEmiDetails";
import AddBasicInformation from "./Agent/CustomerComponent/AddBasicInformation";
import AddressInformation from "./Agent/CustomerComponent/AddressInformation";
import AddDocument from "./Agent/CustomerComponent/AddDocument";

import BulkUploadData from "./SuperAgent/BulkUploadData/BulkUploadData";
import BulkUploadDataDetails from "./SuperAgent/BulkUploadData/BulkUploadDataDetails";
import LoanDefulterLevelOne from "./SuperAgent/LoanManagment/LoanDefulterLevelOne";
import Myapplyloanlist from "./SuperAgent/Myloan/Myapplyloanlist";
import Myloandetails from "./SuperAgent/Myloan/Myloandetails";
import Myemidetails from "./SuperAgent/Myloan/Myemidetails";
import SuperAgentListAdmin from "./Admin/SuperAgent/SuperAgentListAdmin";
import SuperAgentViewAdmin from "./Admin/SuperAgent/SuperAgentViewAdmin";
import SuperAgentReport from "./Admin/SuperAgent/SuperAgentReport";
import SuperAgentPerfomance from "./Admin/SuperAgent/SuperAgentPerfomance";
import SuperAgentReportOrg from "./Organization/SuperAgent/SuperAgentReportOrg";
import SuperAgentPerfomaceOrg from "./Organization/SuperAgent/SuperAgentPerfomaceOrg";
import SuperAgentLogsOrg from "./Organization/LogsComponent/SuperAgentLogsOrg";
import UserLogsOrg from "./Organization/LogsComponent/UserLogsOrg";
import EmplyeeDetails from "./Organization/EmplyeeDetails";
import AdminDetailsView from "./Organization/AdminDetailsView";
import NotificationOrg from "./Organization/Notifications/NotificationOrg";
import LoginSuperAgent from "./SuperAgent/LoginSuperAgent";
import AgentSuperReport from "./SuperAgent/agentManagment/AgentSuperReport";
import AgentSuperView from "./SuperAgent/agentManagment/AgentSuperView";
import AgentSuperBlockList from "./SuperAgent/agentManagment/AgentSuperBlockList";
import LoginJdc from "./Jdc/common/LoginJdc";
import DashbordJdc from "./Jdc/common/DashbordJdc";

// import UserLogs from './Organization/LogsComponent/UserLogs'
import JdcSidebar from "./Jdc/common/Sidebar";
import JdcHeader from "./Jdc/common/Header";
import LicenseOrderHistory from "./Organization/LicenseOrderHistory";
import EmployeeEdit from "./Organization/EmployeeEdit";
import AdminEdit from "./Organization/AdminEdit";
import ActiveJdcList from "./Jdc/CRM/ActiveJdcList";
import AdminUnblockListOrg from "./Organization/AdminUnblockListOrg";
import EmployeeUnblockList from "./Organization/EmployeeUnblockList";
import ActiveJdcDetail from "./Jdc/CRM/ActiveJdcDetail";
import ActiveJdcBlockList from "./Jdc/CRM/ActiveJdcBlockList";
import SuperAgentAddAdmin from "./SuperAgent/OrganisationManagment/SuperAgentAddAdmin";
import SuperAgentViewAdminList from "./SuperAgent/OrganisationManagment/SuperAgentViewAdminList";
import SuperAgentOrganistionReport from "./SuperAgent/Reports/SuperAgentOrganistionReport";
import SuperAgentReportAgent from "./SuperAgent/Reports/SuperAgentReportAgent";
import SuperAgentListAgent from "./SuperAgent/Reports/SuperAgentListAgent";
import SuperAgentAdminBlockList from "./SuperAgent/OrganisationManagment/SuperAgentAdminBlockList";
import SuperAgentCustomerExport from "./SuperAgent/Export/SuperAgentCustomerExport";
import SuperAgentExportAgent from "./SuperAgent/Export/SuperAgentExportAgent";
import SuperAgentAdminExport from "./SuperAgent/Export/SuperAgentAdminExport";
import AddDocumentAgentCreated from "./Organization/SuperAgent/AddDocumentAgentCreated";
import Superagentprofile from "./SuperAgent/profile/Superagentprofile";
import Custometrnsaction from "./SuperAgent/CRM/Custometrnsaction";
import Savingcustomer from "./SuperAgent/CRM/Savingcustomer";
import WithoutIdsWallet from "./Admin/WithoutIdsWallet";
import Loancustumerlist from "./Organization/LoanManagement/Loancustumerlist";
import LoanApplycustomerList from "./SuperAgent/LoanManagment/LoanApplycustomerList";
import SuperAgentSavingCustomerlist from "./SuperAgent/LoanManagment/SuperAgentSavingCustomerlist";
import SuperAgentCustomerLoanDashboard from "./SuperAgent/LoanManagment/SuperAgentCustomerLoanDashboard";
import EmiDetailsSuperAgentLoan from "./SuperAgent/LoanManagment/EmiDetailsSuperAgentLoan";
import RejectloanlistCustomer from "./SuperAgent/LoanManagment/RejectloanlistCustomer";
import SuperAgentAloowLoan from "./SuperAgent/LoanManagment/SuperAgentAloowLoan";
import EMIlogs from "./SuperAgent/LoanManagment/EMIlogs";
import EMiDetailsCustomer from "./SuperAgent/LoanManagment/EMiDetailsCustomerSuperAgent";
import EMiDetailsCustomerSuperAgent from "./SuperAgent/LoanManagment/EMiDetailsCustomerSuperAgent";
import RequestLoan from "./SuperAgent/SavingsManagment/RequestLoan";
import SuperAgentAddCustomer from "./SuperAgent/CustomerComponent/SuperAgentAddCustomer";
import SuperAddressInformation from "./SuperAgent/CustomerComponent/SuperAddressInformation";
import SuperAddDocument from "./SuperAgent/CustomerComponent/SuperAddDocument";
import Dashbboardcustomers from "./SuperAgent/CRM/Dashbboardcustomers";
import MandatorySavings from "./SuperAgent/SavingsManagment/MandatorySavings";
import VoluntorySavings from "./SuperAgent/SavingsManagment/VoluntorySavings";
import MonthlyIntrestTransactions from "./SuperAgent/SavingsManagment/MonthlyIntrestTransactions";

import SavingIntrest from "./Admin/SavingIntrest";
import Withdrawalrequest from "./SuperAgent/SavingsManagment/Withdrawalrequest";
import ShareManagementDashboard from "./SuperAgent/ShareManagement/ShareManagementDashboard";
import Shareupdatedadmit from "./Organization/Shareupdatedadmit";
import ShareApplyCustomerList from "./SuperAgent/ShareManagement/ShareApplyCustomerList";
import SharePrice from "./SuperAgent/ShareManagement/SharePrice";
import ChangeIntrestRate from "./SuperAgent/ShareManagement/ChangeIntrestRate";
import ApplyLoan from "./Jdc/CRM/ApplyLoan";
import JdcpendingLoan from "./Jdc/CRM/JdcpendingLoan";
import JdcPassLoans from "./Jdc/CRM/JdcPassLoans";
import JdcAcceptLoan from "./Jdc/CRM/JdcAcceptLoan";
import Jdccooperative from "./Jdc/CRM/Jdccooperative";
import Unionlist from "./Jdc/CRM/Unionlist";
import LoanTypes from "./SuperAgent/LoanManagment/LoanTypes";
import AddProduct from "./SuperAgent/Products/AddProduct";
import ProductManagementList from "./SuperAgent/Products/ProductManagementList";
import ProductStoreManagement from "./SuperAgent/Products/ProductStoreManagement";
import UpdateProduct from "./SuperAgent/Products/UpdateProduct";
import ViewUserCardProduct from "./SuperAgent/Products/ViewUserCardProduct";
import BuyProduct from "./SuperAgent/Products/BuyProduct";
import MyCart from "./SuperAgent/Products/MyCart";
import UserOrder from "./SuperAgent/Products/UserOrder";
import OrderDetail from "./SuperAgent/Products/OrderDetail";
import SellProduct from "./SuperAgent/Products/SellProduct";
import SellngOrder from "./SuperAgent/Products/SellngOrder";
import RoleManagment from "./Admin/SuperAgent/RoleManagment";
import OrderList from "./Jdc/BuyingSelling/OrderList";
import JdcOrderDetail from "./Jdc/BuyingSelling/JdcOrderDetail";
import JdcProduct from "./Jdc/BuyingSelling/JdcProduct";
import JdcSelling from "./Jdc/BuyingSelling/JdcSelling";
import TransactionJdc from "./Jdc/BuyingSelling/TransactionJdc";
import MainDashboard from "./MainDashboard";
import Invoice from "./Invoice/Invoice";
import SuperUserTransaction from "./SuperAgent/Products/SuperUserTransaction";
import LoanInvoice from "./Invoice/LoanInvoice";
import LoanPaidEmiInvoice from "./Invoice/LoanPaidEmiInvoice";
import BuyProductSuper from "./SuperAgent/Products/BuyProductSuper";
import BuyingProductList from "./SuperAgent/Products/BuyingProductList";
import BuyingInvoice from "./Invoice/BuyingInvoice";
import JdcByuingList from "./Jdc/BuyingSelling/JdcByuingList";
import TransactionSuperReports from "./SuperAgent/TransactionsReports/TransactionSuperReports";
import TransactionsPage from "./Jdc/BuyingSelling/TransactionsPage";
import AnalyticMainPage from "./Admin/Analytics/AnalyticMainPage";
import ApplyforPersionLoan from "./SuperAgent/Myloan/ApplyforPersionLoan";
import AllSuperAgentApplyLoanList from "./Organization/SuperAgentApplyLoan/AllSuperAgentApplyLoanList";
import OrgRejectLoanforSuperAgentList from "./Organization/SuperAgentApplyLoan/OrgRejectLoanforSuperAgentList";
import OrgSuperAgentLoanDetails from "./Organization/SuperAgentApplyLoan/OrgSuperAgentLoanDetails";
import OrgApproveLoanList from "./Organization/SuperAgentApplyLoan/OrgApproveLoanList";
import OrgSuperAgentEmiDetails from "./Organization/SuperAgentApplyLoan/OrgSuperAgentEmiDetails";
import OrgShareDashboard from "./Organization/OrgShareManagement/OrgShareDashboard";
import AddRequestToShareBYOrg from "./SuperAgent/ApplyShareOrgManagement/AddRequestToShareBYOrg";
import OrgPenddingRequestShare from "./Organization/OrgShareManagement/PenddingRequestShare/OrgPenddingRequestShare";
import AddExistingLoanpersonal from "./SuperAgent/CRM/AddExistingLoanpersonal";
import ExistingLoanAdd from "./SuperAgent/LoanManagment/ExistingLoanAdd";
import SavingForSuper from "./Organization/SuperAgent/SavingForSuper";
import WithdrawalShareRequest from "./Organization/OrgShareManagement/WithdrawalShareRequest/WithdrawalShareRequest";
import OrgProductsManagemtList from "./Organization/StoreManagementOrg/ProductManagementOrg/OrgProductsManagemtList";
import OrgAddProductManagement from "./Organization/StoreManagementOrg/OrgAddProductManagement/OrgAddProductManagement";
import UpdateOrgProduct from "./Organization/StoreManagementOrg/OrgUpdateProduct/UpdateOrgProduct";
import OrgProductStoreManagementList from "./Organization/StoreManagementOrg/OrgStoreAddProductBySuperAgent/OrgProductStoreManagementList";
import OrgMyStoreProduct from "./Organization/StoreManagementOrg/OrgMyStoreProduct/OrgMyStoreProduct";
import SuperAgentOrderListOrg from "./Organization/StoreManagementOrg/OrgSuperAgentOrderList/SuperAgentOrderListOrg";
import OrgOrderDetail from "./Organization/StoreManagementOrg/ProductManagementOrg/OrgOrderDetail";
import InterestSavingDetail from "./SuperAgent/CRM/InterestSavingDetail";
import PrivateRoute from "./Allrouting/AdminRouting/PrivateRoute";
import AgentPrivatedRouting from "./Allrouting/AgentRouting/AgentPrivatedRouting";
import OrgPrivatedRouting from "./Allrouting/OrgRouting/OrgPrivatedRouting";
import SuperAgentPrivateRoute from "./Allrouting/SuperAgentRouting/SuperAgentPrivateRoute";
import JdcPrivatedRouting from "./Allrouting/JdcRouting/JdcPrivatedRouting";
import NotFound from "./Allrouting/NotFound";
import SavingPageForDashCust from "./SuperAgent/CRM/SavingPageForDashCust";
import SavingPriceUpdated from "./SuperAgent/SavingsManagment/SavingPriceUpdated";
import AdminSavingTransactions from "./Admin/Transactions History/Saving Transactions history/AdminSavingTransactions";
import LoanTransactions from "./Admin/Transactions History/Loan Transactions History/LoanTransactions";
import ShareTransactionHistory from "./Admin/Transactions History/Share Transactions History/ShareTransactionHistory";
import StoreTransactionHistory from "./Admin/Transactions History/Store Transaction History/StoreTransactionHistory";
import SuperLoginLogs from "./Admin/LogsComponent/SuperLoginLogs";
import BlockedIPs from "./Admin/LogsComponent/BlockedIPs";
import JdcCreatedSubadmin from "./Jdc/JdcSupAdmin/JdcCreatedSubadmin";
import JdcSubadminlist from "./Jdc/JdcSupAdmin/JdcSubadminlist";
import JdcSubAdminUpdate from "./Jdc/JdcSupAdmin/JdcSubAdminUpdate";
import AskToAI from "./Invoice/AskToAI";
import NonMemberStore from "./SuperAgent/Products/NonMemberStore";
import JdcProfile from "./Jdc/Profile/JdcProfile";
import AdminDashboardIds from "./Admin/DashboardIds/AdminDashboardIds";
import StroreFrontTrans from "./Admin/StoreFront/StroreFrontTrans";
import UnitMainPage from "./SuperAgent/UnitManagement/UnitMainPage";
import AddCity from "./SuperAgent/CityManagement/AddCity/AddCity";
import CategoryListAdmin from "./Admin/CategoryManagement/CategoryListAdmin/CategoryListAdmin";
import AddCategoryAdmin from "./Admin/CategoryManagement/AddCategoryAdmin/AddCategoryAdmin";
import UpdateCategoryAdmin from "./Admin/CategoryManagement/UpdateCategoryAdmin/UpdateCategoryAdmin";
import AllAccountManagementList from "./Admin/AccountManagement/AllAccountManagementList/AllAccountManagementList";
import AddAccountAdmin from "./Admin/AccountManagement/AddAccountAdmin/AddAccountAdmin";
import UpdateAccountAdmin from "./Admin/AccountManagement/UpdateAccountAdmin/UpdateAccountAdmin";
import AccountManagementSuperAgent from "./SuperAgent/AccountManagementSuperAgent/AccountManagementSuperAgent";
import AllAccountsList from "./SuperAgent/AccountManagementSuperAgent/AllAccountsList";
import AccountDetail from "./SuperAgent/AccountManagementSuperAgent/AccountDetail";
import JDCAnalyticPage from "./Jdc/JDCAnalyticPage";
import CategoryListSuperAgent from "./SuperAgent/SuperAgentCategoryManagement/CategoryListSuperAgent/CategoryListSuperAgent";
import AddCategorySuperAgent from "./SuperAgent/SuperAgentCategoryManagement/AddCategorySuperAgent/AddCategorySuperAgent";
import UpdateCategorySuperAgent from "./SuperAgent/SuperAgentCategoryManagement/UpdateCategorySuperAgent/UpdateCategorySuperAgent";
import SuperAgentAllAccountManagementList from "./SuperAgent/SuperAgentAccountManagement/SuperAgentAllAccountManagementList/SuperAgentAllAccountManagementList";
import AddAccountSuperAgent from "./SuperAgent/SuperAgentAccountManagement/AddAccountSuperAgent/AddAccountSuperAgent";
import UpdateAccountSuperAgent from "./SuperAgent/SuperAgentAccountManagement/UpdateAccountSuperAgent/UpdateAccountSuperAgent";
import TransactionsInvoice from "./Invoice/TransactionsInvoice";
import ProductCategory from "./SuperAgent/productcategory/ProductCategory";
import SupperAgentSavingManagement from "./SuperAgent/SuperAgentSettingManagement/SupperAgentSavingManagement/SupperAgentSavingManagement";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "leave",
  "unload",
];

let timer;

const handleLogoutTimer = () => {
  timer = setTimeout(() => {
    resetTimer();

    // Display toast message for session expiration
    toast.info("Your session has expired. You will be logged out.");
    // Log the user out after a short delay to give time to see the toast message
    setTimeout(() => {
      logoutAction();
    }, 2000); // Wait for 2 seconds before logging out
  }, 1800000); // 1800000,   120000,30 minutes of inactivity
};

const resetTimer = () => {
  if (timer) clearTimeout(timer);
};

const logoutAction = () => {
  localStorage.clear();
  window.location.reload();
};

function App() {
  const { authType } = useSelector((state) => state.auth);

  const [isUserInactive, setIsUserInactive] = useState(false); // Track inactivity state

  const handleUserActivity = () => {
    if (isUserInactive) {
      setIsUserInactive(false); // Reset inactivity state if activity detected
    }
    resetTimer(); // Reset the inactivity timer
    handleLogoutTimer(); // Restart the logout timer
  };

  useEffect(() => {
    // Attach event listeners to detect user activity
    events.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });

    // Attach an event listener for offline status (optional)
    window.addEventListener("offline", logoutAction);

    // Cleanup event listeners when the component is unmounted
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
      window.removeEventListener("offline", logoutAction);

      if (timer) clearTimeout(timer);
    };
  }, [isUserInactive]); // Dependency array to trigger re-renders on inactivity change

  useEffect(() => {
    // Function to handle success in getting location
    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    };

    // Function to handle errors in getting location
    const handleError = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          console.log("An unknown error occurred.");
          break;
        default:
          console.log("An unknown error occurred.");
      }
    };

    // Request location access
    const requestLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    // Call the function to request location access
    requestLocation();
  }, []);

  // useEffect(() => {
  //   const handleKeyDown = (e) => {
  //     if (e.ctrlKey && e.shiftKey && e.key == "I") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.shiftKey && e.key == "C") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.shiftKey && e.key == "J") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.shiftKey && e.key == "U") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.key == "U") {
  //       e.preventDefault();
  //     }
  //     if (e.key == 123) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  // click disable

  // useEffect(() => {
  //   const handleRightClick = (event) => {
  //     event.preventDefault();
  //   };

  //   // Attach the event listener
  //   document.addEventListener("contextmenu", handleRightClick);

  //   // Clean up the event listener
  //   return () => {
  //     document.removeEventListener("contextmenu", handleRightClick);
  //   };
  // }, []);

  return (
    <div className="App">
      <ToastContainer />

      <Routes>
        {/* ////////new page added   Orgnzation   */}
        <Route exact path="/loginorg" element={<OrgLogin />} />
        <Route element={<OrgPrivatedRouting />}>
          <Route exact path="/ChromePayDash" element={<MainDashboard />} />

          <Route
            exact
            path="/organization"
            element={<OrganizationDashboard />}
          />
          <Route exact path="/addcustomer" element={<AddCustomer />} />
          <Route
            exact
            path="/Org/products/ManagementList"
            element={<OrgProductsManagemtList />}
          />
          <Route
            exact
            path="/Org/Super-Agent/Ordeals"
            element={<SuperAgentOrderListOrg />}
          />
          <Route
            exact
            path="/Org/My/cartlist/:_id"
            element={<OrgMyStoreProduct />}
          />
          <Route
            exact
            path="/Org/add/products"
            element={<OrgAddProductManagement />}
          />
          <Route
            exact
            path="/Org/Updated/products/:_id"
            element={<UpdateOrgProduct />}
          />
          <Route
            exact
            path="/Org/Strore/ManagementList/:_id"
            element={<OrgProductStoreManagementList />}
          />
          <Route exact path="/addcustomer/otp" element={<OtpCustomer />} />
          <Route
            exact
            path="/addcustomer/otp/success"
            element={<SuccessCustomerModal />}
          />
          <Route exact path="/customer-list" element={<CustomersList />} />
          <Route
            exact
            path="/Org/superAgent-loan-apply-list"
            element={<AllSuperAgentApplyLoanList />}
          />
          <Route
            exact
            path="/Org/superAgent-share-apply-list"
            element={<OrgPenddingRequestShare />}
          />
          <Route
            exact
            path="/Org/superAgent-Withdrawal-apply-list"
            element={<WithdrawalShareRequest />}
          />

          <Route
            exact
            path="/Org/superAgent-loan-approve-list"
            element={<OrgApproveLoanList />}
          />
          <Route
            exact
            path="/Org/superAgent/Emi-loan-detail/:_id/:custId"
            element={<OrgSuperAgentEmiDetails />}
          />

          <Route
            exact
            path="/Org/superAgent-loan-details/:_id/:custID"
            element={<OrgSuperAgentLoanDetails />}
          />

          <Route
            exact
            path="/Org/superAgent-loan-Reject-list"
            element={<OrgRejectLoanforSuperAgentList />}
          />
          <Route
            exact
            path="/transtion-list"
            element={<CustomerTranstionList />}
          />
          <Route exact path="/agent-add" element={<AgentAdd />} />
          <Route exact path="/agent-list" element={<AgentList />} />
          <Route
            exact
            path="/org-change-password"
            element={<OrgChangePassword />}
          />
          <Route
            exact
            path="/org-customer-view/:_id"
            element={<CustmerViewOrg />}
          />
          <Route
            exact
            path="/transaction-view-org/:_id"
            element={<TransctionViewOrg />}
          />
          <Route exact path="/agent-view-org/:_id" element={<AgentViewOrg />} />
          <Route exact path="/Add-Bank" element={<AddBank />} />
          <Route exact path="/my-profile-org" element={<MyProfileOrg />} />
          <Route exact path="/otp-org" element={<OtpOrg />} />
          {/* <Route exact path='/update-license' element={<updateLicenses />}/> */}

          <Route exact path="/update-license" element={<UpdateLicence />} />
          <Route
            exact
            path="/remaning-license"
            element={<RemaningLicenses />}
          />
          <Route
            exact
            path="/Share-updated-org"
            element={<Shareupdatedadmit />}
          />
          <Route exact path="/loan-apply-list" element={<LoanApply />} />
          <Route
            exact
            path="/loan-dashboard/:_id/:custID"
            element={<CustomerLoanDashboard />}
          />
          <Route exact path="/loan-pass" element={<LoanPassList />} />
          <Route
            exact
            path="/loan-pass-dashbord/:_id/:custID"
            element={<LoanPassDashbord />}
          />
          <Route exact path="/card-score" element={<CardScore />} />
          <Route exact path="/org-customer-links" element={<OrgCustLink />} />
          <Route exact path="/agent-logs-links" element={<AgentLogsList />} />
          <Route exact path="/OrgWallet/:_id" element={<OrgWallet />} />
          <Route exact path="/Orgwebcamera/:_id" element={<Orgwebcamera />} />
          <Route
            exact
            path="/OrgLatesttransaction/:_id"
            element={<OrgLatesttransaction />}
          />
          <Route
            exact
            path="/Agent_Performance_repost/:_id"
            element={<Agent_Performance_repost />}
          />
          <Route
            exact
            path="/Orginization_customer_face_add"
            element={<Orginization_customer_face_add />}
          />
          <Route exact path="/AddCustomerdemo" element={<AddCustomerdemo />} />
          <Route
            exact
            path="/OrgcustomerBlocklist"
            element={<OrgcustomerBlocklist />}
          />
          <Route exact path="/OrgDigitalId" element={<OrgDigitalId />} />

          <Route
            exact
            path="/ORGAgentReportView_performe/:_id"
            element={<ORGAgentReportView_performe />}
          />
          <Route
            exact
            path="/loan-management-customer-list"
            element={<Loancustumerlist />}
          />
          <Route
            exact
            path="/Orgviewagent_performance/:_id"
            element={<Orgviewagent_performance />}
          />
          <Route
            exact
            path="/OrdAgentReportView"
            element={<OrdAgentReportView />}
          />
          <Route exact path="/Orgselfreport" element={<Orgselfreport />} />
          <Route exact path="/Orgdeleteuse" element={<Orgdeleteuse />} />
          <Route exact path="/OrgBlocklist" element={<OrgBlocklist />} />
          <Route
            exact
            path="/Exportorgblocklist"
            element={<Exportorgblocklist />}
          />
          <Route
            exact
            path="/AgentOrgTransactionlimit"
            element={<AgentOrgTransactionlimit />}
          />
          <Route
            exact
            path="/Admin_user_profile_View/:_id"
            element={<Admin_user_profile_View />}
          />
          <Route
            exact
            path="/EmpolyeesRegister"
            element={<EmpolyeesRegister />}
          />
          <Route exact path="/EmpolyListORG" element={<EmpolyListORG />} />
          <Route exact path="/Createadmin" element={<Createadmin />} />
          <Route exact path="/Viewadmin" element={<Viewadmin />} />
          <Route exact path="/Blockagentlist" element={<Blockagentlist />} />
          <Route exact path="/user-logs-org" element={<UserLogsOrg />} />
          <Route
            exact
            path="/employee-details/:_id"
            element={<EmplyeeDetails />}
          />
          <Route
            exact
            path="/admin-details-view/:_id"
            element={<AdminDetailsView />}
          />
          <Route exact path="/notification/org" element={<NotificationOrg />} />
          <Route
            exact
            path="/org/license/order-history"
            element={<LicenseOrderHistory />}
          />
          <Route
            exact
            path="/employee/edit-org/:_id"
            element={<EmployeeEdit />}
          />
          <Route exact path="/admin/edit-org/:_id" element={<AdminEdit />} />
          <Route
            exact
            path="/admin/unblock/list-org"
            element={<AdminUnblockListOrg />}
          />
          <Route
            exact
            path="/employee/unblock/list-org"
            element={<EmployeeUnblockList />}
          />

          {/* <Route exact path='/UserLogs' element={<UserLogs />}/>  */}

          {/* //////////------ Super agent Org  --------///// */}

          <Route exact path="/super-agent-add" element={<CreateAgent />} />
          <Route
            exact
            path="/super-agent-add/AddDocumentAgentCreated"
            element={<AddDocumentAgentCreated />}
          />
          <Route exact path="/super-agent-list" element={<SuperAgentList />} />
          <Route
            exact
            path="/super-agent-view/:_id"
            element={<SuperAgentView />}
          />
          <Route
            exact
            path="/Org/ShareDashboard/:_id"
            element={<OrgShareDashboard />}
          />
          <Route
            exact
            path="/super-agent-block"
            element={<SuperAgentBlockList />}
          />
          <Route
            exact
            path="/super-agent-wallet"
            element={<SuperAgentWallet />}
          />
          <Route
            exact
            path="/super-agent-wallet-history/:_id"
            element={<SuperAgentWalletHistory />}
          />
          <Route
            exact
            path="/superagent/add-loan/:_id"
            element={<SuperAgentAddLoan />}
          />
          <Route
            exact
            path="/superagent/loan-list"
            element={<SuperAgentLoanList />}
          />
          <Route
            exact
            path="/superagent/loan-details/:_id"
            element={<SuperAgentLoanDetailsOrg />}
          />
          <Route
            exact
            path="/superagent/emi-customer-detail/:_id"
            element={<SuperAgentEmiDetails />}
          />
          <Route
            exact
            path="/superagent/super-agent-report-org"
            element={<SuperAgentReportOrg />}
          />
          <Route
            exact
            path="/superagent/super-agent-perfomace-org/:_id"
            element={<SuperAgentPerfomaceOrg />}
          />
          <Route
            exact
            path="/superagent/super-agent-logs"
            element={<SuperAgentLogsOrg />}
          />
          {/*--------------Export Admin---------------- */}
          <Route
            exact
            path="/Export-customer-export"
            element={<OrgCustExport />}
          />
          <Route
            exact
            path="/Export-agent-list"
            element={<AgentCustExport />}
          />
          <Route
            exact
            path="/super-agent-savings/:_id"
            element={<SavingForSuper />}
          />

          <Route
            exact
            path="/org/order/detail/:_id/:agentId"
            element={<OrgOrderDetail />}
          />
        </Route>

        {/* //////////------ Super agent   --------///// */}
        <Route exact path="/superlogin" element={<SuperAgentLogin />} />

        <Route element={<SuperAgentPrivateRoute />}>
          <Route
            exact
            path="/Super-agent-dashbord"
            element={<SuperAgentDashboard />}
          />

          <Route exact path="/super/bulk-upload" element={<BulkUploadData />} />
          <Route
            exact
            path="/super/bulk-view/:id"
            element={<BulkUploadDataDetails />}
          />
          <Route
            exact
            path="/Super-agent-Customer"
            element={<RegisterCustomer />}
          />
          <Route
            exact
            path="/Super-agent-Customer2"
            element={<RegisterCustomer2 />}
          />
          <Route
            exact
            path="/Super-agent/otp-verfiy"
            element={<OtpVerifyCutomer />}
          />
          <Route
            exact
            path="/Super-agent/customer-list"
            element={<SuperAgentCustomerList />}
          />
          <Route
            exact
            path="/superagent/customer/Existing/Loan/:_id"
            element={<AddExistingLoanpersonal />}
          />
          <Route
            exact
            path="/superagent/customer/Existing-Loan-allow/:_id"
            element={<ExistingLoanAdd />}
          />
          <Route exact path="/user-order/list" element={<UserOrder />} />
          <Route exact path="/super-accounts" element={<AllAccountsList />} />
          <Route exact path="/super-agent-saving-management" element={<SupperAgentSavingManagement />} />
          <Route
            exact
            path="/super-agent-category-list"
            element={<CategoryListSuperAgent />}
          />

          <Route
            exact
            path="/super-agent-category-add"
            element={<AddCategorySuperAgent />}
          />
          <Route
            exact
            path="/super-agent-category-update"
            element={<UpdateCategorySuperAgent />}
          />

          <Route
            exact
            path="/super-agent-account-list"
            element={<SuperAgentAllAccountManagementList />}
          />
          <Route
            exact
            path="/super-agent-account-add"
            element={<AddAccountSuperAgent />}
          />
          <Route
            exact
            path="/super-agent-account-update"
            element={<UpdateAccountSuperAgent />}
          />
          <Route exact path="/sell-order/list" element={<SellngOrder />} />
          <Route
            exact
            path="/Super-agent/Emi-logs-list"
            element={<EMIlogs />}
          />
          <Route
            exact
            path="/Super-agent/customer-block"
            element={<SuperAgentCustomerBlock />}
          />
          <Route
            exact
            path="/Super-agent/customer-awating"
            element={<SuperAgentAwatingList />}
          />
          <Route
            exact
            path="/Super-agent/wallet-list"
            element={<SuperAgentWalletList />}
          />
          <Route
            exact
            path="/Super-agent/loan-pass"
            element={<SuperAgentLoanPass />}
          />
          <Route
            exact
            path="/SuperAgentloanApply"
            element={<SuperAgentloanApply />}
          />
          <Route exact path="/Applyforloan/:_id" element={<Applyforloan />} />
          <Route
            exact
            path="/Super-Agent-Customer-Loan-Dashboard/:_id/:custID"
            element={<SuperAgentCustomerLoanDashboard />}
          />
          <Route
            exact
            path="/Super-Agent-customer-view/:_id"
            element={<SupercustomerView />}
          />
          <Route
            exact
            path="/Super-Agent-pendingloan"
            element={<Pendingloan />}
          />
          <Route
            exact
            path="/Super-Agent-Share-Apply-list"
            element={<ShareApplyCustomerList />}
          />
          <Route
            exact
            path="/add-agent-by-super"
            element={<AddAgentbysuper />}
          />
          <Route
            exact
            path="/superagent-agent-list"
            element={<AgentsuperList />}
          />
          <Route
            exact
            path="/superagent-agent-detail/:_id"
            element={<SuperAgentLoanDetails />}
          />
          <Route
            exact
            path="/superagent/saving-list"
            element={<SuperAgentSavingsList />}
          />
          <Route
            exact
            path="/superagent/loan-defulter/level-one"
            element={<LoanDefulterLevelOne />}
          />
          <Route
            exact
            path="/superagent/Myloan/Myapplyloanlist"
            element={<Myapplyloanlist />}
          />
          <Route
            exact
            path="/superagent/Add-requiredbyOrg"
            element={<AddRequestToShareBYOrg />}
          />
          <Route
            exact
            path="/superagent/Myloan/Myloandetails/:_id"
            element={<Myloandetails />}
          />
          <Route
            exact
            path="/superagent/Myloan/Myemidetails/:_id"
            element={<Myemidetails />}
          />
          <Route
            exact
            path="/superagent/agent-report/:_id"
            element={<AgentSuperReport />}
          />
          <Route
            exact
            path="/superagent/transaction/reports"
            element={<TransactionSuperReports />}
          />
          <Route
            exact
            path="/superagent/super-agent-view/:_id"
            element={<AgentSuperView />}
          />
          <Route
            exact
            path="/superagent/super-agent-block"
            element={<AgentSuperBlockList />}
          />
          <Route
            exact
            path="/superagent/add-admin-by-super"
            element={<SuperAgentAddAdmin />}
          />
          <Route
            exact
            path="/superagent/Apply-for-loan"
            element={<ApplyforPersionLoan />}
          />
          <Route
            exact
            path="/superagent/view-admin-list"
            element={<SuperAgentViewAdminList />}
          />
          <Route
            exact
            path="/superagent/Loan-Apply-customer-List"
            element={<LoanApplycustomerList />}
          />
          <Route
            exact
            path="/superagent/Loan-Reject-customer-List"
            element={<RejectloanlistCustomer />}
          />
          <Route
            exact
            path="/superagent/Loan-Saving-Customer-list"
            element={<SuperAgentSavingCustomerlist />}
          />
          <Route
            exact
            path="/superagent/super-agent-report"
            element={<SuperAgentOrganistionReport />}
          />
          <Route
            exact
            path="/superagent/agent-report-agent"
            element={<SuperAgentListAgent />}
          />
          <Route
            exact
            path="/superagent/agent-list-super"
            element={<SuperAgentReportAgent />}
          />
          <Route
            exact
            path="/superagent/admin-block-list"
            element={<SuperAgentAdminBlockList />}
          />
          {/* <Route exact path='/superagent/profile' element={<SuperAgentAdminBlockList />}/>  */}

          {/* Export */}
          <Route
            exact
            path="/superagent/super-agent-active-export"
            element={<SuperAgentCustomerExport />}
          />
          <Route
            exact
            path="/superagent/super-agent-export-agent"
            element={<SuperAgentExportAgent />}
          />
          <Route
            exact
            path="/superagent/profile"
            element={<Superagentprofile />}
          />

          <Route
            exact
            path="/superagent/customer/transaction/:_id"
            element={<Custometrnsaction />}
          />

          <Route
            exact
            path="/superagent/share-management-dashboard/:_id"
            element={<ShareManagementDashboard />}
          />
          <Route
            exact
            path="/superagent/account-management-dashboard"
            element={<AccountManagementSuperAgent />}
          />
          <Route
            exact
            path="/superagent/customer/Emi-Customer-Details/:_id/:_emiId"
            element={<EMiDetailsCustomerSuperAgent />}
          />

          <Route
            exact
            path="/superagent/customer/loan-allow/:_id"
            element={<SuperAgentAloowLoan />}
          />
          <Route
            exact
            path="/superagent/customer/Saving/:_id"
            element={<Savingcustomer />}
          />
          <Route
            exact
            path="/dashboard/customer/saving/:_id"
            element={<SavingPageForDashCust />}
          />
          <Route
            exact
            path="/Super-Agent/Emi-loan-detail/:_id/:custId"
            element={<EmiDetailsSuperAgentLoan />}
          />
          <Route
            exact
            path="/customer/addCustomer"
            element={<SuperAgentAddCustomer />}
          />
          <Route
            exact
            path="/super/customer/address"
            element={<SuperAddressInformation />}
          />
          <Route
            exact
            path="/agent/customer/document"
            element={<SuperAddDocument />}
          />
          <Route exact path="/loan-request" element={<RequestLoan />} />
          <Route
            exact
            path="/super/dash-customers"
            element={<Dashbboardcustomers />}
          />
          <Route
            exact
            path="/super/saving/mandatory"
            element={<MandatorySavings />}
          />
          <Route
            exact
            path="/super/saving/voluntory"
            element={<VoluntorySavings />}
          />
          <Route
            exact
            path="/super/saving/Intrest/transactions"
            element={<MonthlyIntrestTransactions />}
          />
          <Route exact path="/super/share/price" element={<SharePrice />} />
          <Route
            exact
            path="/super/saving/price"
            element={<SavingPriceUpdated />}
          />
          <Route
            exact
            path="/super/Change-intrest-rate"
            element={<ChangeIntrestRate />}
          />
          <Route
            exact
            path="/super/withdrawal/request"
            element={<Withdrawalrequest />}
          />
          <Route
            exact
            path="/super/Loan/Type/Setting"
            element={<LoanTypes />}
          />
          <Route exact path="/add/products" element={<AddProduct />} />
          <Route
            exact
            path="/non/member/register"
            element={<NonMemberStore />}
          />
          <Route
            exact
            path="/update/products/:_id"
            element={<UpdateProduct />}
          />
          <Route exact path="/sell/products/:_id" element={<SellProduct />} />
          <Route
            exact
            path="/update/products/:_id"
            element={<UpdateProduct />}
          />
          <Route
            exact
            path="/saving/detail/:_id"
            element={<InterestSavingDetail />}
          />
          <Route
            exact
            path="/ViewUser/CardUpdate/:_id"
            element={<ViewUserCardProduct />}
          />
          <Route
            exact
            path="/Buy/Product/:_id/:custId"
            element={<BuyProduct />}
          />
          <Route exact path="/Buy/Products" element={<BuyProductSuper />} />
          <Route
            exact
            path="/products/ManagementList"
            element={<ProductManagementList />}
          />
          <Route
            exact
            path="/Strore/ManagementList/:_id"
            element={<ProductStoreManagement />}
          />
          <Route exact path="/user/cart/:_id" element={<MyCart />} />
          <Route exact path="/super-agent-add-city" element={<AddCity />} />
          <Route
            exact
            path="/order/detail/:_id/:custId"
            element={<OrderDetail />}
          />
          <Route
            exact
            path="/super/user/transaction"
            element={<SuperUserTransaction />}
          />
          <Route
            exact
            path="/buying/product/list"
            element={<BuyingProductList />}
          />

          <Route exact path="/Invoice/:custId/:_id" element={<Invoice />} />
          <Route
            exact
            path="/Buying-invouve/:_id"
            element={<BuyingInvoice />}
          />
          <Route
            exact
            path="/transactions-invoice"
            element={<TransactionsInvoice />}
          />
          <Route
            exact
            path="/product-category-management"
            element={<ProductCategory />}
          />
          <Route exact path="/ask-to-open-ai" element={<AskToAI />} />
          <Route
            exact
            path="/LoanInvoive/:_id/:custId"
            element={<LoanInvoice />}
          />
          <Route
            exact
            path="/LoanPaidInvoive/:_id/:custId"
            element={<LoanPaidEmiInvoice />}
          />
          <Route exact path="/unit-management" element={<UnitMainPage />} />
          <Route
            exact
            path="/account-detail/:_id"
            element={<AccountDetail />}
          />
        </Route>

        {/* <Route
          exact
          path="/order/detail/:_id/:custId"
          element={<OrderDetail  />}
        /> */}
        {/* // JdC farmer // */}
        <Route exact path="/login-jdc" element={<LoginJdc autType="jdc" />} />
        <Route exact path="/login-ice" element={<LoginJdc autType="ice" />} />

        <Route element={<JdcPrivatedRouting />}>
          <Route
            exact
            path={`/${authType}-dashbord`}
            element={<DashbordJdc />}
          />
          <Route
            exact
            path={`/crm/${authType}-active-list`}
            element={<ActiveJdcList />}
          />
          <Route
            exact
            path={`/crm/${authType}-active-block`}
            element={<ActiveJdcBlockList />}
          />
          <Route
            exact
            path={`/crm/${authType}-active-details/:_id`}
            element={<ActiveJdcDetail />}
          />
          <Route
            exact
            path={`${authType}/apply_loan/:_id`}
            element={<ApplyLoan />}
          />
          <Route
            exact
            path={`${authType}/pending/list`}
            element={<JdcpendingLoan />}
          />
          <Route
            exact
            path={`${authType}/pass/list`}
            element={<JdcPassLoans />}
          />
          <Route
            exact
            path={`${authType}/Accept/loan/:_id/:_id`}
            element={<JdcAcceptLoan />}
          />
          <Route
            exact
            path={`${authType}/cooperative`}
            element={<Jdccooperative />}
          />
          <Route exact path={`${authType}/union`} element={<Unionlist />} />

          <Route exact path={`${authType}/orders`} element={<OrderList />} />

          <Route
            exact
            path={`${authType}/order/detail/:_id/:custId`}
            element={<JdcOrderDetail />}
          />

          <Route
            exact
            path={`${authType}/product/list`}
            element={<JdcProduct />}
          />
          <Route
            exact
            path={`${authType}/Buying/list`}
            element={<JdcByuingList />}
          />

          <Route
            exact
            path={`${authType}/selling/order/list`}
            element={<JdcSelling />}
          />

          <Route
            exact
            path={`${authType}/transaction`}
            element={<TransactionJdc />}
          />
          <Route
            exact
            path={`${authType}/transaction/data`}
            element={<TransactionsPage />}
          />
          <Route
            exact
            path={`${authType}/subadmin-created`}
            element={<JdcCreatedSubadmin />}
          />
          <Route
            exact
            path={`${authType}/update-subadmin`}
            element={<JdcSubAdminUpdate />}
          />
          <Route
            exact
            path={`${authType}/subadmin-list`}
            element={<JdcSubadminlist />}
          />

          <Route exact path={`${authType}/profile`} element={<JdcProfile />} />
          <Route exact path={`/analytic-page`} element={<JDCAnalyticPage />} />
        </Route>

        {/* ////////new page start Admin    */}

        <Route exact path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route exact path="/admin" element={<AdminDashbord />} />
          <Route exact path="/customer-add" element={<AdminCustomer />} />
          <Route exact path="/list-customer" element={<ListCustomer />} />

          <Route
            exact
            path="/admin-account-management-list"
            element={<AllAccountManagementList />}
          />
          <Route
            exact
            path="/admin-add-account-management"
            element={<AddAccountAdmin />}
          />
          <Route
            exact
            path="/admin/update-account"
            element={<UpdateAccountAdmin />}
          />
          <Route
            exact
            path="/all-saving-transactions"
            element={<AdminSavingTransactions />}
          />
          <Route
            exact
            path="/all-loan-transactions"
            element={<LoanTransactions />}
          />
          <Route
            exact
            path="/all-share-transactions"
            element={<ShareTransactionHistory />}
          />
          <Route
            exact
            path="/all-store-transactions"
            element={<StoreTransactionHistory />}
          />
          <Route exact path="/transction-list" element={<TransactionsList />} />
          <Route exact path="/organization-Add" element={<OrganizationAdd />} />
          <Route
            exact
            path="/organization-list-admin"
            element={<ListOrgnization />}
          />
          <Route
            exact
            path="/Admin-change-password"
            element={<AdminChangePassword />}
          />
          <Route exact path="/Admin-setting" element={<AdminSetting />} />
          <Route exact path="/customer-ipaddress" element={<IpAddress />} />
          <Route
            exact
            path="/customer-view-admin/:_id"
            element={<CustomerView />}
          />
          <Route
            exact
            path="/transaction-view-admin/:_id"
            element={<TransactionView />}
          />
          <Route
            exact
            path="/organzation-view-admin/:_id"
            element={<OrgnazationView />}
          />
          <Route exact path="/my-profile" element={<MyProfile />} />
          <Route
            exact
            path="/agent-transaction-limit"
            element={<AgentTransactionLimit />}
          />
          <Route
            exact
            path="/admin-customer-otp"
            element={<AdminCustomerOtp />}
          />
          <Route exact path="/digital-id-list" element={<DigitalID />} />
          <Route
            exact
            path="/digital-approve-list"
            element={<AllDigitalID />}
          />
          <Route
            exact
            path="/Customer-block-list"
            element={<CustomerBlockList />}
          />
          <Route
            exact
            path="/organisations-block-list"
            element={<OrgnizationBlockList />}
          />
          <Route exact path="/agent-block-list" element={<AgentBlocskList />} />
          <Route exact path="/subadmin-add" element={<SubadminAdd />} />
          <Route exact path="/roles-add/:_id" element={<AddRoles />} />
          <Route
            exact
            path="/user-customer-view/:_id"
            element={<UserCustView />}
          />
          <Route exact path="/setup-fees" element={<SetupFees />} />
          <Route exact path="/pendding-did" element={<PenddingDID />} />
          <Route exact path="/agent-report" element={<AgentReport />} />
          <Route
            exact
            path="/agent-report-view/:_id"
            element={<AgentReportView />}
          />
          <Route
            exact
            path="/organzation-report"
            element={<OrganzationReport />}
          />
          <Route exact path="/did-report" element={<DIDReport />} />
          <Route exact path="/subadmin-role-list" element={<SubAdminList />} />
          <Route exact path="/subadmin-view/:_id" element={<SubAdminView />} />
          <Route
            exact
            path="/Add_admin_document/:id"
            element={<Add_admin_document />}
          />
          <Route exact path="/Agent-admin-add" element={<AgentAdminAdd />} />
          <Route exact path="/Agent-admin-list" element={<AgentAdminList />} />
          <Route
            exact
            path="/customer-commission-list"
            element={<CommisionList />}
          />
          <Route
            exact
            path="/custmer-bank-detail/:_id/:custID"
            element={<CustmerBankDetail />}
          />
          <Route exact path="/less-licence" element={<LicensesOrganzation />} />
          <Route
            exact
            path="/performance_repost/:_id"
            element={<Performance_repost />}
          />
          <Route
            exact
            path="/oragnzation-performance_repost/:_id"
            element={<OrganzationPerfomance />}
          />
          <Route
            exact
            path="/all-orgnzation-report"
            element={<AllOrgnReport />}
          />
          <Route
            exact
            path="/admin-customer-links"
            element={<AdminCustLink />}
          />
          <Route exact path="/admin-logs-list" element={<AdminLogsList />} />
          <Route
            exact
            path="/orgnization-logs-list"
            element={<OrgLogsList />}
          />
          <Route
            exact
            path="/superagent-login-logs"
            element={<SuperLoginLogs />}
          />
          <Route
            exact
            path="/AdminCustomerDemo"
            element={<AdminCustomerDemo />}
          />
          <Route
            exact
            path="/Admin_customer_face_Add"
            element={<Admin_customer_face_Add />}
          />
          <Route exact path="/EmpolyList" element={<EmpolyList />} />
          <Route exact path="/Agentlist" element={<Agentlist />} />
          <Route
            exact
            path="/Registerempolyees"
            element={<Registerempolyees />}
          />
          <Route exact path="/ImageReader" element={<ImageReader />} />
          <Route exact path="/kacha/ocr" element={<OcrHistoryList />} />
          <Route exact path="/kacha/face" element={<FaceHistoryList />} />
          <Route exact path="/notification" element={<NotificationView />} />
          <Route exact path="/license/request" element={<LicenseRequest />} />
          <Route exact path="/blocked/ips" element={<BlockedIPs />} />
          <Route
            exact
            path="/admin/super-agent-list"
            element={<SuperAgentListAdmin />}
          />
          <Route
            exact
            path="/admin/without-ids-wallet"
            element={<WithoutIdsWallet />}
          />
          <Route
            exact
            path="/admin/super-agent-view/:_id"
            element={<SuperAgentViewAdmin />}
          />
          <Route
            exact
            path="/admin/super-agent-report"
            element={<SuperAgentReport />}
          />
          <Route
            exact
            path="/admin/super-agent-perfomance/:_id"
            element={<SuperAgentPerfomance />}
          />
          <Route
            exact
            path="/admin/saving/Interest"
            element={<SavingIntrest />}
          />

          <Route
            exact
            path="/admin/category-list"
            element={<CategoryListAdmin />}
          />

          <Route
            exact
            path="/admin/add-category"
            element={<AddCategoryAdmin />}
          />
          <Route
            exact
            path="/admin/update-category"
            element={<UpdateCategoryAdmin />}
          />

          <Route
            exact
            path="/role/management/:_id"
            element={<RoleManagment />}
          />
          <Route exact path="/analytic/page" element={<AnalyticMainPage />} />

          {/*--------------Export Admin---------------- */}
          <Route
            exact
            path="/admin-did-customer-export"
            element={<AdminExportCustList />}
          />
          <Route
            exact
            path="/Org-customer-export"
            element={<ExportOrgCustList />}
          />
          <Route
            exact
            path="/admin-blocked-export"
            element={<ExportAdminBlockedList />}
          />
          <Route
            exact
            path="/admin-dashboard-ids"
            element={<AdminDashboardIds />}
          />
          <Route
            exact
            path="/store-transactions"
            element={<StroreFrontTrans />}
          />
        </Route>
        {/* ////////new page End Admin    */}

        {/* //////////------Agent  --------///// */}

        <Route exact path="/login-agent" element={<LoginAgent />} />

        <Route element={<AgentPrivatedRouting />}>
          <Route exact path="/agent-dashbord" element={<AgentDashbord />} />
          <Route
            exact
            path="/agent-customer-add"
            element={<CustomerAgentAdd />}
          />
          <Route
            exact
            path="/agent-customer-list"
            element={<AgentCustomerList />}
          />
          <Route
            exact
            path="/agent-customer-otp"
            element={<AgentCustomerOtp />}
          />
          <Route
            exact
            path="/Agent-transction-list"
            element={<AgentTransactionList />}
          />
          <Route
            exact
            path="/Agent-change-password"
            element={<AgentChangePassword />}
          />
          <Route
            exact
            path="/Agent-customer-view/:_id"
            element={<CutomerViewAgent />}
          />
          <Route
            exact
            path="/Agent-transaction-view/:_id"
            element={<TransactionViewAgent />}
          />
          <Route
            exact
            path="/Agent-Add-Account/:_id"
            element={<AgentAddAccount />}
          />
          <Route
            exact
            path="/Agent-Add-Account-list"
            element={<AgentAccountList />}
          />
          <Route exact path="/agent-my-profile" element={<AgentMyProfile />} />
          <Route
            exact
            path="/Agent-comssion-list"
            element={<AgentCommisionList />}
          />
          <Route
            exact
            path="/agent-did-approvel"
            element={<AgentDIDApprovel />}
          />
          <Route
            exact
            path="/agent-did-blocked"
            element={<AgentBlockedDIDs />}
          />
          <Route exact path="/longApply/:_id" element={<LongApply />} />
          <Route
            exact
            path="/Customerloanlist"
            element={<Customerloanlist />}
          />
          <Route exact path="/Loanpasslist" element={<Loanpasslist />} />
          <Route
            exact
            path="/agent-loan-pass-dashbord/:_id"
            element={<AgentLoanPassDashbord />}
          />
          <Route
            exact
            path="/agent-customer-links"
            element={<AgentCustLink />}
          />
          <Route
            exact
            path="/Agentuserwallet/:_id"
            element={<Agentuserwallet />}
          />
          <Route exact path="/scanner" element={<Scanner />} />
          <Route
            exact
            path="/latest-transaction/:_id"
            element={<AgentLatestTransaction />}
          />
          <Route exact path="/AgentWebcamera/:_id" element={<Webcamera />} />
          <Route
            exact
            path="/Agent_customer_face_add"
            element={<Agent_customer_face_add />}
          />
          <Route
            exact
            path="/Customercreateddemo"
            element={<Customercreateddemo />}
          />
          <Route
            exact
            path="/agent-did-customer"
            element={<ExportCustomerDIDs />}
          />
          <Route
            exact
            path="/commision-list-export"
            element={<ExportCommissionList />}
          />
          <Route
            exact
            path="/block-list-export"
            element={<ExportBlockedList />}
          />
          <Route
            exact
            path="/agent/loan/applay/:_id"
            element={<LoanApplayAgent />}
          />
          <Route
            exact
            path="/agent/loan-detail/:_id"
            element={<AgentLoanEmiDetail />}
          />
          <Route
            exact
            path="/agent/emi-customer-detail/:_id"
            element={<EMiDetailsCustomer />}
          />
        </Route>

        {/* Agent Customer Add */}
        {/* <Route
          exact
          path="/agent/customer/Info"
          element={<AddBasicInformation  />}
        />
        <Route
          exact
          path="/agent/customer/address"
          element={<AddressInformation  />}
        />
        <Route
          exact
          path="/agent/customer/document"
          element={<AddDocument  />}
        /> */}

        {/* <Route exact path='/logout' element={<Logout/>}/>
  <Route exact path='/otp_login' element={<Otp_login/>}/>
  <Route exact path='/Aaa_jk' element={<Aaa_jk/>}/>
  <Route exact path='/Check_box_malti' element={<Check_box_malti/>}/>
  <Route exact path='/Home'><Protected cmp = {Home/>}/></Route>
  <Route exact path='/Audits_branches' element={<Audits_branches/>}/>
  
  <Route exact path='/Awaiting_confirmation' element={<Awaiting_confirmation/>}/>
  <Route exact path='/awaiting_verification' element={<Awaiting_verification/>}/>

  <Route exact path='/Bank-account-settings' element={<Bank_account_settings/>}/>
  <Route exact path='/bill-payment-smart-routing' element={<Bill_payment_smart_routing/>}/>

  <Route exact path='/customer-settings' element={<Customer_settings/>}/>
  <Route exact path='/customer' element={<Customer/>}/>
  <Route exact path='/customer_3' element={<Customer_3/>}/>
  <Route exact path='/add-customer' element={<Add_customer/>}/>
  <Route exact path='/customer_detail' element={<Customer_detail/>}/>
  <Route exact path='/customer_send_message' element={<Customer_send_message/>}/>
  <Route exact path='/customer_transa_infor' element={<Customer_transa_infor/>}/>
  <Route exact path='/customer_transaction_reports' element={<Customer_transaction_reports/>}/>

  <Route exact path='/dynamic_field_list' element={<Dynamic_field_list/>}/>
  <Route exact path='/default_chrges_setup' element={<Default_chrges_setup/>}/>

  <Route exact path='/email_settings' element={<Email_settings/>}/>


  <Route exact path='/manage_charges' element={<Manage_charges/>}/>
  <Route exact path='/manage_api_customers' element={<Manage_api_customers/>}/>
  <Route exact path='/manage_transactions_history' element={<Manage_transactions_history/>}/>

  <Route exact path='/exchange_rates_list' element={<Exchange_rates_list/>}/>
  <Route exact path='/exchange_rates' element={<Exchange_rates/>}/>

  <Route exact path='/paycenter_commission_manager_default' element={<Paycenter_commission_manager_default/>}/>
  <Route exact path='/recurring_charge_fee' element={<Recurring_charge_fee/>}/>
  <Route exact path='/prepaid_voucher_batch_history' element={<Prepaid_voucher_batch_history/>}/>
  <Route exact path='/supported_services_settings' element={<Supported_services_settings/>}/>
  <Route exact path='/search_transactions_detail' element={<Search_transactions_detail/>}/>
  <Route exact path='/search_transactions' element={<Search_transactions/>}/>
  <Route exact path='/add-organization' element={<Add_organization/>}/>
  <Route exact path='/organization' element={<Organization/>}/>
  <Route exact path='/organization_detail/' element={<Organization_detail/>}/>
  <Route exact path='/payments_awaiting_processing' element={<Payments_awaiting_processing/>}/>
  <Route exact path='/security_settings' element={<Security_settings/>}/>

  <Route exact path='/manage_partner_centers' element={<Manage_partner_centers/>}/>
  <Route exact path='/register_pay_centers_agent' element={<Register_pay_centers_agent/>}/>
  <Route exact path='/edit_api_pay_centers' element={<Edit_api_pay_centers/>}/>

 */}

        {/* //////////------Customer  --------///// */}
        {/* <Route exact path='/customer-login' element={<CustomerLogin/>}/>
  <Route exact path='/otp-cust' element={<OtpCust/>}/>
  <Route exact path='/customer-dash' element={<CustomerDashbord/>}/>
  <Route exact path='/customer-org-list' element={<OrgnzationCustList/>}/>
  <Route exact path='/customer-transaction-list' element={<TransactionCustList/>}/>
  <Route exact path='/CustomerWallet' element={<CustomerWallet/>}/>
  <Route exact path='/FuseWallet' element={<FuseWallet/>}/>
  <Route exact path='/CustomerLoanApply' element={<CustomerLoanApply/>}/> */}

        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
