import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CustomerLoader from "../../CRM/Modal/CustomerLoder";

const SupperAgentSavingManagement = () => {
  const token = localStorage.getItem("SuperAgentChromepayToken");
  const [showLoader, setShowLoader] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [savingTypeData, setSavingTypeData] = useState([]);
  const [shareEnabled, setShareEnabled] = useState(false);
  const [updatedData, setUpdatedData] = useState({});

  // Fetch saving types data
  const fetchData = async () => {
    setShowLoader(true);
    try {
      const response = await axios.post(
        `v1/super-Agent/get-saving-types`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSavingTypeData(response.data.data);
    } catch (error) {
      console.error("Error fetching saving types:", error);
      toast.error("Failed to fetch saving types. Please try again.");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  // Add saving type
  const addSavingType = async (formData) => {
    setShowLoader(true);
    try {
      const response = await axios.post(
        `v1/super-Agent/add-SavingType`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        toast.success(response.data.msg);
        setShowAddModal(false);
        fetchData();
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("API Call Failed:", error);
      toast.error("Failed to add saving type. Please try again.");
    } finally {
      setShowLoader(false);
    }
  };

  // Update saving type
  const updateSavingType = async (formData) => {
    setShowLoader(true);
    try {
      const response = await axios.post(
        `v1/super-Agent/update-SavingType`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        toast.success(response.data.msg);
        setShowUpdateModal(false);
        fetchData();
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("API Call Failed:", error);
      toast.error("Failed to update saving type. Please try again.");
    } finally {
      setShowLoader(false);
    }
  };

  // Delete saving type
  const deleteSavingType = async (typeId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(
            `/v1/super-Agent/remove-Saving-Types`,
            { typeId },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          Swal.fire("Deleted!", "Saving type deleted successfully.", "success");
          fetchData();
        } catch (error) {
          console.error("Error deleting saving type:", error);
          toast.error("Failed to delete saving type. Please try again.");
        }
      }
    });
  };

  // Open update modal with selected data
  const openUpdateModal = (item) => {
    setUpdatedData(item);
    setShareEnabled(item.isShare);
    setShowUpdateModal(true);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setUpdatedData((prev) => ({
      ...prev,
      [name]: type === "number" ? Number(value) : value,
    }));
  };

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={showLoader} />

      <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                  <div className="card-rounded mt-2 d-flex flex-stack flex-wrap pt-0 p-5 pb-5">
                    <h5 className="text-light" style={{ marginTop: "20px", fontSize: "20px" }}>
                      Saving Type Management
                    </h5>
                    <button
                      onClick={() => setShowAddModal(true)}
                      className="btn btn-light btn-active-light-primary me-2"
                    >
                      Add Saving Type
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="tab-content mt-4" style={{ padding: "0px" }}>
                  <div className="tab-pane fade active show" id="overview" role="tabpanel">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                          <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">Saving Type List</span>
                            </h3>
                          </div>
                          <div className="card-body py-3">
                            <div className="table-responsive">
                              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                  <tr className="fw-bold text-muted text-center th-title">
                                    <th>No.</th>
                                    <th>Saving Type</th>
                                    <th>Interest Rate</th>
                                    <th>Share</th>
                                    <th>Share Percentage</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {savingTypeData.length > 0 ? (
                                    savingTypeData.map((item, index) => (
                                      <tr className="text-center" key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.type}</td>
                                        <td>{item.interestRate}</td>
                                        <td>{item.isShare ? "Yes" : "No"}</td>
                                        <td>{item.sharePer}</td>
                                        <td>
                                          <div className="d-flex gap-3 justify-content-center">
                                            <button
                                              onClick={() => openUpdateModal(item)}
                                              className="btn btn-icon btn-active-color-primary btn-sm"
                                              title="Update"
                                            >
                                              <i className="fad fa-edit fs-4"></i>
                                            </button>
                                            <button
                                              onClick={() => deleteSavingType(item._id)}
                                              className="btn btn-icon btn-danger btn-sm"
                                              title="Delete"
                                            >
                                              <i className="fad fa-trash-alt fs-4"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="6" className="text-center">
                                        <span style={{ color: "green", fontSize: "2rem" }}>
                                          No Saving Types Found. Please Add One.
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Saving Type Modal */}
      <SavingTypeForm
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        onSubmit={addSavingType}
        shareEnabled={shareEnabled}
        setShareEnabled={setShareEnabled}
      />

      {/* Update Saving Type Modal */}
      <SavingTypeForm
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
        onSubmit={updateSavingType}
        shareEnabled={shareEnabled}
        setShareEnabled={setShareEnabled}
        data={updatedData}
        isUpdate
      />
    </>
  );
};

// Reusable Saving Type Form Component
const SavingTypeForm = ({
  show,
  onHide,
  onSubmit,
  shareEnabled,
  setShareEnabled,
  data = {},
  isUpdate = false,
}) => {
  const [formData, setFormData] = useState(data);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "number" ? Number(value) : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{isUpdate ? "Update" : "Add"} Saving Type</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mb-3">
            <label className="mb-2">Type</label>
            <select
              className="form-control"
              name="type"
              value={formData.type || ""}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Type</option>
              <option value="mandatory">Mandatory</option>
              <option value="voluntary">Voluntary</option>
              <option value="children">Children</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="mb-2">Is Share</label>
            <div className="d-flex gap-3">
              <div className="form-check">
                <input
                  type="radio"
                  name="isShare"
                  value="false"
                  checked={!formData.isShare}
                  onChange={(e) => {
                    handleChange(e);
                    setShareEnabled(false);
                  }}
                />
                <label className="form-check-label">No</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  name="isShare"
                  value="true"
                  checked={formData.isShare}
                  onChange={(e) => {
                    handleChange(e);
                    setShareEnabled(true);
                  }}
                />
                <label className="form-check-label">Yes</label>
              </div>
            </div>
          </div>

          {shareEnabled && (
            <div className="mb-3">
              <label className="mb-2">Share Percentage</label>
              <input
                type="number"
                className="form-control"
                name="sharePer"
                value={formData.sharePer || ""}
                onChange={handleChange}
                required
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            {isUpdate ? "Update" : "Add"} Saving Type
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SupperAgentSavingManagement;