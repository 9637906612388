import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import CustomerLoader from "../CRM/Modal/CustomerLoder";

import { useLocation, useNavigate, useParams } from "react-router-dom";

var jwt = require("jsonwebtoken");

export default function BulkUploadDataDetails() {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgType = localStorage.getItem("orgType");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;
  const navigate = useNavigate();
  const [custID, setcustID] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [notereason, setnotereason] = useState("");
  const [total, settotal] = useState([]);
  const [Query, setQuery] = useState("");
  const csvId = useParams();
  let ID = localStorage.getItem("ID");
  const [data, setData] = useState({});
  const [paymenthandler, setpaymenthandler] = useState(false);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  const userlist = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-Upload-Details`,
        { id: csvId?.id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        if (resp?.data?.status) {
          const data = resp?.data;
          console.log(data);
          setData(data);
          const total = resp?.data?.totalSuccessRecords;
          settotal(total);
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          setShowLoader(false);
        }
      });
  };

  const handleUpdateCout = (total) => {
    const totalPage = Math.ceil(total / limit);
    setpageCount(totalPage);
  };

  useEffect(() => {
    userlist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };

    axios
      .post(
        `/v1/super-Agent/get-Upload-Details`,
        {
          id: csvId?.id,
          limit: 10,
          page: page,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  useEffect(() => {
    ;
  }, []);

  ///new code
  let selectedFile = null;

  const downloadCsvFile = async () => {
    // Define the headers for the CSV
    setShowLoader(true);

    const response = await axios.post(
      `/v1/super-Agent/get-Upload-Details`,
      {
        id: csvId?.id,
        limit: 100 * 100 * 100,
        page: 1,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    const headers = [
      "Sr.No.",
      "Bruh IDs Ref No.",
      "Full Name",
      "Phone",
      "Email",
      "Education",
      "MotherName",
      "BirthPlace",
      "Nationality",
      "Profession",
      "Address",
      "City",
      "SubCity",
      "Date of Registration",
      "Error Message", // Only for failed records
    ];

    // Prepare the data for the CSV
    const successData = response?.data?.successData || [];

    if (successData) {
      setShowLoader(false);

      // Create CSV rows for success data
      const successRows = successData.map((item, index) => [
        index + 1,
        item.digitalrefID,
        item.fullname,
        item.phone,
        item.email,
        item.education,
        item.motherName,
        item.birthPlace,
        item.nationality,
        item.professoin,
        item.address,
        item.city,
        item.subCity,
        item.registrationDate,
        "", // Empty for error message
      ]);

      // Combine the rows
      const rows = [...successRows];

      // Create CSV content
      const csvContent = [headers, ...rows]
        .map((e) => e.join(",")) // Join each row with commas
        .join("\n"); // Join rows with new lines

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "upload_details.csv"); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const downloadCsvFailed = async () => {
    // Define the headers for the CSV
    setShowLoader(true);

    const response = await axios.post(
      `/v1/super-Agent/get-Upload-Details`,
      {
        id: csvId?.id,
        limit: 100 * 100 * 100,
        page: 1,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    const headers = [
      "Sr.No.",
      "Bruh IDs Ref No.",
      "Full Name",
      "Phone",
      "Email",
      "Education",
      "MotherName",
      "BirthPlace",
      "Nationality",
      "Profession",
      "Address",
      "City",
      "SubCity",
      "Date of Registration",
      "Error Message", // Only for failed records
    ];

    const failedData = response?.data?.failedData || [];
    if (failedData) {
      // Prepare the data for the CSV
      setShowLoader(false);

      // Create CSV rows for success data

      // Create CSV rows for failed data
      const failedRows = failedData.map((item, index) => [
        index + 1,
        item.digitalrefID,
        item.fullname,
        item.phone,
        item.email,
        item.education,
        item.motherName,
        item.birthPlace,
        item.nationality,
        item.professoin,
        item.address,
        item.city,
        item.subCity,
        item.registrationDate,
        item.error, // Error message
      ]);

      // Combine the rows
      const rows = [...failedRows];

      // Create CSV content
      const csvContent = [headers, ...rows]
        .map((e) => e.join(",")) // Join each row with commas
        .join("\n"); // Join rows with new lines

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "upload_details.csv"); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };
  return (
    <>
      <CustomerLoader loader={ShowLoader} />
      <ToastContainer />
      <div className="wrapper d-flex flex-column flex-row-fluid">
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <ul
                      className="mt-3 nav flex-wrap border-transparent fw-bold"
                      role="tablist"
                    >
                      <li className="nav-item my-1" role="presentation">
                        <a
                          onClick={() =>
                            handleUpdateCout(data?.totalSuccessRecords)
                          }
                          role="tab"
                          aria-selected="true"
                          data-bs-toggle="tab"
                          href="#SUCCESS"
                          className="btn btn-color-gray-600 btn-active-secondary active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                        >
                          Success List
                        </a>
                      </li>
                      <li className="nav-item my-1" role="presentation">
                        <a
                          onClick={() =>
                            handleUpdateCout(data?.totalFailedRecords)
                          }
                          role="tab"
                          aria-selected="true"
                          data-bs-toggle="tab"
                          href="#Failed"
                          className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                        >
                          Failed List
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="SUCCESS"
                  role="tabpanel"
                >
                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div class="card-header border-0 pt-6 d-flex align-items-center">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1">
                            Success List
                          </span>
                          <span className="text-muted mt-1 fw-semibold fs-7">
                            {" "}
                            Over {data?.totalSuccessRecords} Success List
                          </span>
                        </h3>
                        <div class="card-title">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h1 className="fw-semibold me-3 text-white">
                              Bulk Upload Details
                            </h1>
                            <Button onClick={downloadCsvFile} variant="primary">
                              Download CSV
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th
                                  className="text-center"
                                  style={{ paddingRight: "1em" }}
                                >
                                  Sr.No.
                                </th>
                                <th></th>
                                <th className="min-w-150px">
                                  Bruh IDs Ref No.
                                </th>
                                <th>Date of Registration</th>
                                {/* <th className="min-w-100px text-center">
                                  Action
                                </th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {data?.successData?.map((item, index) => (
                                <tr>
                                  <td
                                    className="text-center"
                                    style={{ paddingRight: "1em" }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td>
                                    {" "}
                                    <div class="d-flex align-items-center">
                                      <div class="symbol symbol-45px me-5">
                                        <img src={item?.IDphoto} alt="" />
                                      </div>
                                      <div class="d-flex justify-content-start flex-column">
                                        <a class="usertitle Acolor mb-2">
                                          {" "}
                                          {item.fullname}
                                        </a>
                                        <span class="font-icon mb-1">
                                          <span class="">
                                            <i class="fal fa-phone-volume"></i>
                                          </span>
                                          +{item.phone}
                                        </span>

                                        <span class="font-icon mb-1">
                                          <span class="">
                                            <i class="fal fa-envelope"></i>
                                          </span>
                                          {item.email}{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.digitalrefID}</td>
                                  <td>
                                    {item.registrationDate}
                                  </td>
                                  {/* <td className="text-center">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        navigate(
                                          `/Super-Agent-customer-view/${item._id}`
                                        )
                                      }
                                      className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      title="View Detail"
                                    >
                                      <span className="svg-icon svg-icon-3">
                                        <i className="fad fa-eye fs-4"></i>
                                      </span>
                                    </button>
                                  </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            PreviousLabel={"Previous"}
                            NextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end gap-1"
                            }
                            pageClassName={"page-item mx-2"}
                            pageLinkClassName={"page-link"}
                            PreviousClassName={"page-item"}
                            PreviousLinkClassName={"page-link pagestyle"}
                            NextClassName={"page-item"}
                            NextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* faild data */}
                <div className="tab-pane fade" id="Failed" role="tabpanel">
                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div class="card-header border-0 pt-6 d-flex align-items-center">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1">
                            Failed List
                          </span>
                          <span className="text-muted mt-1 fw-semibold fs-7">
                            {" "}
                            Over {data?.totalFailedRecords} Success List
                          </span>
                        </h3>
                        <div class="card-title">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h1 className="fw-semibold me-3 text-white">
                              Bulk Upload Details
                            </h1>
                            <Button
                              onClick={downloadCsvFailed}
                              variant="primary"
                            >
                              Download CSV
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th
                                  className="text-center"
                                  style={{ paddingRight: "1em" }}
                                >
                                  Sr.No.
                                </th>
                                <th></th>
                                <th className="min-w-150px">
                                  Bruh IDs Ref No.
                                </th>
                                <th>Date of Registration</th>
                                <th className="min-w-100px text-center">
                                  Error Message
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {data?.failedData?.map((item, index) => (
                                <tr>
                                  <td
                                    className="text-center"
                                    style={{ paddingRight: "1em" }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td>
                                    {" "}
                                    <div class="d-flex align-items-center">
                                      <div class="symbol symbol-45px me-5">
                                        <img src={item?.IDphoto} alt="" />
                                      </div>
                                      <div class="d-flex justify-content-start flex-column">
                                        <a class="usertitle Acolor mb-2">
                                          {" "}
                                          {item.fullname}
                                        </a>
                                        <span class="font-icon mb-1">
                                          <span class="">
                                            <i class="fal fa-phone-volume"></i>
                                          </span>
                                          +{item.phone}
                                        </span>

                                        <span class="font-icon mb-1">
                                          <span class="">
                                            <i class="fal fa-envelope"></i>
                                          </span>
                                          {item.email}{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.digitalrefID}</td>
                                  <td>
                                    {item.registrationDate}
                                  </td>
                                  <td className="text-danger">{item?.error}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            PreviousLabel={"Previous"}
                            NextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end gap-1"
                            }
                            pageClassName={"page-item mx-2"}
                            pageLinkClassName={"page-link"}
                            PreviousClassName={"page-item"}
                            PreviousLinkClassName={"page-link pagestyle"}
                            NextClassName={"page-item"}
                            NextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
