import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import CustomerLoader from "./Modal/CustomerLoder";

import CustomerLoader from "../CRM/Modal/CustomerLoder";

import { useNavigate } from "react-router-dom";

var jwt = require("jsonwebtoken");

export default function BulkUploadData() {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgType = localStorage.getItem("orgType");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;
  const navigate = useNavigate();
  const [custID, setcustID] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [notereason, setnotereason] = useState("");
  const [total, settotal] = useState([]);
  const [Query, setQuery] = useState("");

  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);
  const [paymenthandler, setpaymenthandler] = useState(false);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    axios
      .post(`/v1/super-Agent/csv-upload-list`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        const total = resp.data.totlaRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const userlist = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/csv-upload-list`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        if (resp?.data?.status) {
          const data = resp?.data;
          console.log(data);
          setData(data?.data);
          const total = resp?.data?.counts;
          settotal(total);
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
          setShowLoader(false);
        }
      });
  };

  useEffect(() => {
    userlist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };

    axios
      .post(`v1/super-Agent/csv-upload-list`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  /////////////unblocked user api call ////////////

  const handleunblocked = (blocked, _id) => {
    let custId = _id;
    let status = blocked;
    let body = { custId: custId, status: status };
    axios
      .post(`/v1/super-Agent/block-unblock-user`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to UnBlock selected Customer ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, UnBlock it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              "Unblocked!",
              "Customer UnBlocked   successfully",
              "success"
            );
            userlist();
          }
        });
      })
      .catch();
  };

  const userpay = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    axios.post(`/Chrome_pay_transection/${custID}`, formData).then((res) => {
      if (res.status) {
        setpaymenthandler(false);
        let data = res.data;
        {
          data.status
            ? Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.msg}`,
                showConfirmButton: false,
                timer: 3500,
              })
            : Swal.fire({
                icon: "error",
                text: `${data.msg}`,
              });
        }
      }
    });
  };
  const handleView = (_id) => {
    return false;
  };

  useEffect(() => {}, []);

  ///new code
  let selectedFile = null;
  const handleFileChange = (event) => {
    selectedFile = event.target.files[0]; // Store the selected file
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedFile) {
      // alert("Please select a file to upload.");
      Swal.fire("Please select", "Please select a file to upload.", "warning");
      return;
    }
    e.target.reset();
    uploadCsvFile(selectedFile);
  };

  const uploadCsvFile = async (file) => {
    // Check if the selected file is a CSV
    if (!file || !file.name.endsWith(".csv")) {
      Swal.fire("Invalid File", "Please upload a valid CSV file.", "error");
      return;
    }
    setShowLoader(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "/v1/super-Agent/csv-upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your token variable
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setShowLoader(false);
      Swal.fire("Success", "CSV file uploaded successfully!", "success");
      userlist();
    } catch (error) {
      setShowLoader(false);
      Swal.fire(
        "Upload Failed",
        error.response.data.message || "An error occurred during the upload.",
        "error"
      );
    }
  };

  const handleDownload = () => {
    const fileName = "chromepay_bulkupload_file.csv";
    const fileUrl = `https://dlcbucket.blr1.digitaloceanspaces.com/videos/1738045675508_samplecsv.csv`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <CustomerLoader loader={ShowLoader} />
      <ToastContainer />
      <div className="wrapper d-flex flex-column flex-row-fluid">
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">
                        Upload CSV File
                      </h1>
                    </div>
                    <form
                      onSubmit={handleSubmit}
                      className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-4 h-lg-60px me-lg-10 my-5 shadow-sm border"
                    >
                      <div className="row w-100">
                        {/* File Input Section */}
                        <div className="col-lg-7 d-flex align-items-center mb-3 mb-lg-0">
                          <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-3">
                            <i className="fad fa-file-import fs-3 text-secondary"></i>
                          </span>
                          <input
                            type="file"
                            accept=".csv"
                            onChange={(event) => handleFileChange(event)}
                            className="form-control form-control-flush flex-grow-1 border border-gray-300 rounded"
                            style={{
                              padding: "8px 12px",
                              fontSize: "14px",
                              height: "auto",
                            }}
                          />
                        </div>

                        {/* Button Section */}
                        <div className="col-lg-5 d-flex align-items-center justify-content-lg-end gap-3">
                          <button
                            type="submit"
                            className="btn btn-dark px-4 py-2"
                            id="kt_advanced_search_button_1"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={userlist}
                            className="btn btn-secondary px-4 py-2"
                            id="kt_advanced_search_button_2"
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary px-4 py-2"
                            id="kt_advanced_search_button_3"
                            onClick={handleDownload}
                          >
                            Download Sample CSV
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span class="fw-semibold text-white opacity-50">
                        BULK UPLOAD LIST
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-2 col-sm-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                            <label className="small_label">Start Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="fromDate"
                              placeholder="from Date.."
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-lg-2 d-flex align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label">End Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="toDate"
                              placeholder="to Date.."
                            />
                          </div>
                          <div className="col-lg-4" style={{ padding: "7px" }}>
                            <div className="min-w-175px d-flex gap-1 gap-sm-5">
                              <button
                                type="submit"
                                className="btn btn-dark"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={userlist}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        BULK UPLOAD LIST
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} CSV Files
                      </span>
                    </h3>
                    <div class="card-title"></div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th
                              className="text-center"
                              style={{ paddingRight: "1em" }}
                            >
                              Sr.No.
                            </th>
                            <th className="min-w-150px">File Name</th>
                            <th>Date</th>
                            <th className="min-w-100px text-center">
                              Success Counts
                            </th>
                            <th className="min-w-100px text-center">
                              Failed Counts
                            </th>
                            <th className="min-w-100px text-center">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item, index) => (
                            <tr>
                              <td
                                className="text-center"
                                style={{ paddingRight: "1em" }}
                              >
                                {index + 1}
                              </td>
                              <td>{item.fileName}</td>
                              <td>
                                {Moment(item.createdAt).format("DD/MM//YYYY")}
                              </td>
                              <td className="text-success text-center">
                                <b>{item.sucessCounts}</b>
                              </td>
                              <td className="text-danger text-center">
                                <b>{item.failedCounts}</b>
                              </td>
                              <td className="text-center">
                                <button
                                  type="button"
                                  onClick={() =>
                                    navigate(`/super/bulk-view/${item._id}`)
                                  }
                                  className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                  title="View Detail"
                                >
                                  <span className="svg-icon svg-icon-3">
                                    <i className="fad fa-eye fs-4"></i>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-1"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                show={paymenthandler}
                onHide={() => setpaymenthandler(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Payment details</Modal.Title>
                </Modal.Header>
                <form onSubmit={userpay}>
                  <Modal.Body>
                    <div className="col-lg-12 ">
                      <div className="row">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          {" "}
                          User Number
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="receiver_phone"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Number"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type="submit" className="center">
                      {" "}
                      Pay
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
