import React, { useRef, useState } from "react";
import moment from "moment";
import axios from "axios";
import Jwt from "jsonwebtoken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";

export default function ChangeIntrestRate() {
  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");

  const [latestIntrestPrice, setlatestIntrestPrice] = useState("");
  const [selectedType, setselectedType] = useState("");
  const [savingTypes, setSavingTypes] = useState([]);
  const [savingTypesAndInterest, setSavingTypesAndInterest] = useState([]);

  useEffect(() => {
    ;
    getTypesOfSaving()
    getTypesWithInterestOfSaving()

    interestRatecount();
  }, []);

  const interestRatecount = async (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        let data = resp?.data?.data;

        if (resp.status) {
          setlatestIntrestPrice(data);
        } else {
          toast.error(data.msg);
        }
      });
  };

  const IntrestRateUpdated = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    axios
      .post(`/v1/super-Agent/update-interest-rate`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);

            interestRatecount();
            setselectedType("")
            e.target.reset();
            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("Server error");
        }
      });
  };


  const getTypesOfSaving = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-types`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          console.log("test-saving-types", data);
          if (data.status) {
            console.log("ppppp", data.data);
            setSavingTypes(data.data);

            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };


  const getTypesWithInterestOfSaving = (e) => {
    axios
      .post(
        `/v1/super-Agent/saving-interest-find`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          console.log("test-saving-Interest-types", data);
          if (data.status) {
            console.log("ppppp", data.data);
            setSavingTypesAndInterest(data.data);

            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };

  return (
    <>
      <div>
        <ToastContainer />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">
                          Interest Rate Information
                        </h3>
                      </div>
                    </div>

                    <div className="row px-3">

                    {savingTypesAndInterest.map((item) => (
  <div
    key={item.id} // Make sure to have a unique key for each element
    id="kt_account_settings_profile_details"
    className="collapse show col-lg-6"
  >
    <div className="d-flex p-9">
      <label className="col-lg-6 col-form-label fw-semibold fs-6">
        Current {item.typeDetails.type} Interest Rate %
      </label>
      <div className="col-lg-6 fv-row">
        <input
          type="text"
          name="Licenses"
          className="form-control form-control-lg form-control-solid"
          placeholder="Current Mandatory Interest Rate"
          defaultValue={item?.interest}
          readOnly
        />
      </div>
    </div>
  </div>
))}


                       {/* <div
                        id="kt_account_settings_profile_details"
                        className="collapse show col-lg-6"
                      >
                        <div className="d-flex p-9">
                          <label className="col-lg-6 col-form-label fw-semibold fs-6">
                            Current Voluntary Interest Rate %
                          </label>
                          <div className="col-lg-6 fv-row">
                            <input
                              type="text"
                              name="Licenses"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Current Voluntary Interest Rate"
                              defaultValue={latestIntrestPrice?.voluntary}
                              readOnly
                            />
                          </div>
                        </div>
                       
                      </div>   */}

               

                      <div
                        id="kt_account_settings_profile_details"
                        className="collapse show border-top "
                      >
                        <div className="d-flex p-9">
                        <div className="card-title m-0">
                        <h3 className="fw-bold m-0">
                       Update Interest Rate 
                        </h3>
                      </div>


                        </div>
                        <form
                          onSubmit={(e) => IntrestRateUpdated(e)}
                          id="kt_account_profile_details_form"
                          className="form"
                        >
                          <div className="card-body  p-9">
                            <div className="row  w-50  g-3 mb-6">
                              <label className="col-lg-6 col-form-label required fw-semibold fs-6">
                                Type of Interest
                              </label>
                              <div className="col-lg-6 fv-row">
                                <select
                                  name="type"
                                  required
                                  className="form-select  flex-grow-1"
                                  onChange={(e) =>
                                    setselectedType(e.target.value)
                                  }
                                  data-placeholder="Please Select"
                                >
                                  <option value="">Select Interest Type</option>
                                  {savingTypes.map((option) => (
                                                  <option
                                                    key={option.type}
                                                    value={option._id}
                                                  >
                                                    {option.type}
                                                  </option>
                                                ))}{" "}
                                </select>
                              </div>

                            
                                <>
                                  {" "}
                                  <label className="col-lg-6 col-form-label fw-semibold fs-6">
                                    Update Mandatory Interest Rate %
                                  </label>
                                  <div className="col-lg-6 fv-row">
                                    <input
                                      type="number"
                                      min={1}
                                      name="interestRate"
                                      className="form-control form-control-lg form-control-solid"
                                      placeholder="Update Mandatory Interest Rate "
                                      max={100}
                                      defaultValue={
                                        latestIntrestPrice?.mandatory
                                      }
                                    />
                                  </div>{" "}
                                </>
                              

                              {/* {selectedType == "volantary" && (
                                <>
                                  {" "}
                                  <label className="col-lg-6 col-form-label fw-semibold fs-6">
                                    Update Voluntary Interest Rate %
                                  </label>
                                  <div className="col-lg-6 fv-row">
                                    <input
                                      type="number"
                                      min={1}
                                      name="interestRate"
                                      className="form-control form-control-lg form-control-solid"
                                      placeholder="Update Voluntary Interest Rate "
                                      defaultValue={
                                        latestIntrestPrice?.voluntary
                                      }
                                      max={100}
                                    />
                                    <input
                                      type="hidden"
                                      min={1}
                                      name="type"
                                      className="form-control form-control-lg form-control-solid"
                                      placeholder="Update Mandatory Interest Rate "
                                      value="voluntary"
                                    />
                                  </div>{" "}
                                </>
                              )} */}

                              <div className="col-lg-2 fv-row">
                                <button
                                  type="submit"
                                  className="btn btn-light btn-sub me-2"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* added new progess bar  */}
    </>
  );
}
