import Header from "../Header";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import Sidebar from "../Sidebar";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import SuperAgentSavingIntrestCompontent from "./OrgSavingSuperAgentManagement/SuperAgentSavingIntrestComponets/SuperAgentSavingIntrestCompontent";
import SuperAgentWithdrawalComponets from "./OrgSavingSuperAgentManagement/SuperAgentWithdrawalComponets/SuperAgentWithdrawalComponets";
import AddSuperAgentSavingbyOrgComponets from "./OrgSavingSuperAgentManagement/AddSuperAgentSavingbyOrgComponets/AddSuperAgentSavingbyOrgComponets";
import VoluntaryToMandatoryTransferComponets from "./OrgSavingSuperAgentManagement/VoluntaryToMandatoryTransferComponets/VoluntaryToMandatoryTransferComponets";

var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const SavingForSuper = () => {
  const [data, setData] = useState([]);
  const [userPro, setUserinfo] = useState([]);
  const [userLoanDetail, setUserLoan] = useState([]);
  const [NumberOfsavings, setNuberOfSavings] = useState("");

  function formatLargeNumber(Amount, decimalPlaces) {
    // Check if amount is a number
    let amount = parseFloat(Amount);

    if (isNaN(amount)) {
      return "Invalid input";
    }

    // Round to the specified number of decimal places
    amount = amount.toFixed(decimalPlaces);

    // Separate integer and decimal parts
    const [integerPart, decimalPart] = amount.split(".");

    // Add commas to the integer part
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    // Concatenate integer and decimal parts
    let formattedAmount = formattedIntegerPart;
    if (decimalPart !== undefined && decimalPart !== "") {
      formattedAmount += "." + decimalPart;
    }

    return formattedAmount;
  }

  let token = localStorage.getItem("organizationToken");
  let { _id } = useParams();

  const costumerDetails = () => {
    axios
      .post(
        `/v1/org/CRM/get-super_agent-details`,
        { agentId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.list;
        console.log("---->>>>testagent--->>>", data);
        setUserinfo(data);

        console.log("data===== filter", data);
      });
  };

  const customerLoanDetail = () => {
    axios
      .post(
        `/v1/DID/get-both-saving-amount`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        // let data = resp.data.data;
        let saveData = resp.data;

        setUserLoan(saveData);
      });
  };

  const interestRatecount = async (e) => {
    axios
      .post(
        `/v1/org/CRM/get-savings`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        if (resp.status) {
          let data = resp?.data?.data;
          setNuberOfSavings(data);
        } else {
          toast.error(data.msg);
        }
      });
  };

  // api call
  useEffect(() => {
    ;
    costumerDetails();
    customerLoanDetail();
    interestRatecount();
  }, []);

  const handleReloadData = () => {
    costumerDetails();
    customerLoanDetail();
    interestRatecount();
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
          bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              {/* <!--begin: Pic--> */}
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={
                                      userPro?.Image ||
                                      "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/download.png"
                                    }
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              {/* <!--end::Pic--> */}

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  {/* <!--begin::User--> */}
                                  <div className="d-flex flex-column">
                                    {/* <!--begin::Name--> */}
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {userPro?.firstName + userPro?.lastName}
                                      </a>

                                      {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                    </div>
                                    {/* <!--end::Name--> */}

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {"Super Agent"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.ETH || "Ethiopia"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.email}
                                      </a>
                                    </div>
                                    {/* <!--end::Info--> */}
                                  </div>
                                  {/* <!--end::User--> */}
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap"></div>
                                    <div className="d-flex  flex-wrap mt-3">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {userPro?.manStatus == "Open" ? (
                                              formatLargeNumber(
                                                userPro?.mandatory,
                                                2
                                              )
                                            ) : (
                                              <span style={{ color: "red" }}>
                                                {userLoanDetail?.manStatus}
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Mandatory Savings (ETB)
                                        </div>
                                      </div>

                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              userPro?.voluntary || 0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Voluntary Savings (ETB)
                                        </div>
                                      </div>
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              userPro?.numberOfShares || 0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Share's Quantity
                                        </div>
                                      </div>
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${formatLargeNumber(
                                              userPro?.totalShareAmount || 0,
                                              2
                                            )}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Share's Amount (ETB)
                                        </div>
                                      </div>

                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div className="d-flex align-items-center justify-content-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >
                                              {`${
                                                userLoanDetail?.totalWithdrawal ||
                                                0
                                              }`}
                                            </div>
                                          </div>
                                          <div className="fw-semibold fs-6 text-white text-center">
                                            Total withdraw
                                          </div>
                                        </div> */}
                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div className="d-flex align-items-center justify-content-center">
                                            <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                            <div
                                              className="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >
                                              {`${
                                                userLoanDetail?.totalInterest Rate|| 0
                                              }`}
                                            </div>
                                          </div>
                                          <div className="fw-semibold fs-6 text-white text-center">
                                            Total Intrest Amount
                                          </div>
                                        </div> */}
                                    </div>
                                    <div className="d-flex mt-3"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                ADD SAVINGS
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                WITHDRAWAL
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#Mandatory"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Voluntary To Mandatory
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#Savinginterest"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Saving Interest
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    {/* Add saving  done  1111*/}

                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      <AddSuperAgentSavingbyOrgComponets
                        _id={_id}
                        userDetail={{ ...userPro, ...userLoanDetail }}
                        handleReloadData={handleReloadData}
                      />
                    </div>

                    {/* withdraw done */}

                    <div
                      className="tab-pane fade"
                      id="documents"
                      role="tabpanel"
                    >
                      <SuperAgentWithdrawalComponets
                        _id={_id}
                        userDetail={{ ...userPro, ...userLoanDetail }}
                        handleReloadData={handleReloadData}
                      />
                    </div>

                    {/* Voluntary To Mandatory  done*/}

                    <div
                      className="tab-pane fade"
                      id="Mandatory"
                      role="tabpanel"
                    >
                      <VoluntaryToMandatoryTransferComponets
                        _id={_id}
                        userDetail={{ ...userPro, ...userLoanDetail }}
                        handleReloadData={handleReloadData}
                      />
                    </div>

                    {/* savingtarget  done*/}

                    <div
                      className="tab-pane fade"
                      id="Savinginterest"
                      role="tabpanel"
                    >
                      <SuperAgentSavingIntrestCompontent _id={_id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavingForSuper;
