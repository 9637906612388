import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../../Agent/Header";
import Sidebar from "../../Agent/Sidebar";

const AgentPrivatedRouting = () => {
  const isAuthenticated = !!localStorage.getItem("CromepayAgentToken"); // or use your own authentication logic
  const location = useLocation();

  return isAuthenticated ? (
    <>
    <Header/>
      <Outlet />
      <Sidebar/>
    </>
  ) : (
    <Navigate to="/login-agent" />
  );
};
export default AgentPrivatedRouting;
