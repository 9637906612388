import React, { useRef, useState } from 'react'
// import Sidebar from './Sidebar'
// import Header from './Header'
import axios from "axios";
import Jwt from "jsonwebtoken";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Moment from 'moment';

function RequestLoan() {

    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [license, setLicense] = useState([]);
    const [Paymentprocess, setPaymentprocess] = useState(false)
    const [custom, setCustom] = useState('')
    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState('');
    const focusPoint = useRef(null);
    const [pageCount, setpageCount] = useState("");
    const [mandatoryAmount, setMandatory] = useState([]);
    const [volantoryAmount, setVolantory] = useState([]);
    const currentFont = localStorage.getItem('liesenapply');
    let token = localStorage.getItem("SuperAgentChromepayToken")



    let getSavingAccounts = () => {
        axios.post('/v1/super-Agent/get-saving-Accs', {}, { headers: { "Authorization": `Bearer ${token}` } })
            .then((resp) => {
                let mandatoryAmount = resp.data.mandatoryAmount;
                let volantoryAmount = resp.data.volantoryAmount

                setMandatory(mandatoryAmount);
                setVolantory(volantoryAmount);


            })
    }


    const addactive = () => {

        focusPoint.current.classList.toggle("processactive");
    }
    const focusPointpayment = useRef(null);
    const addactivepayment = () => {

        focusPoint.current.classList.toggle("processactivepayment");
    }

    const options = [
        { value: '1000', label: '1K' },
        { value: '10000', label: '10K' },
        { value: '50000', label: '50K' },
        { value: '100000', label: '100K' },
        { value: '500000', label: '500K' },
        { value: '1000000', label: '1M' },
        { value: '2000000', label: '2M' },
        { value: '5000000', label: '5M' },
        { value: 'option2', label: 'Custom' },

    ];

    const Paymentprocessbank = async () => {
        await axios.post(`/v1/org/dash/LicenseProcess`, {}, { headers: { "Authorization": `Bearer ${token}` } })
            .then(resp => {
                let data = resp.data.status;
                setPaymentprocess(data);
                console.log("+++++++++++++++++++", data);
            })
    }
    useEffect(() => {
        Paymentprocessbank();
        getSavingAccounts();
        if (currentFont) {
            addactive();
        }

    }, [])
    useEffect(() => {

        if (Paymentprocess) {
            addactivepayment()
        }

    }, [Paymentprocess])
    useEffect(() => {
        ;
        userlist();
    }, [])

    console.log("jjjjjjjjjjjjjjjjjjj", Paymentprocess);



    const AddLicense = (e) => {

        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        console.log("@@@" + Formvlaues.Licenses)
        axios.post(`/v1/super-Agent/send-request`, formData, { headers: { "Authorization": `Bearer ${token}` } })
            .then(resp => {
                if (resp.status) {
                    let data = resp.data
                    if (data.status) {
                        Swal.fire(
                            'SuccessFully!',
                            data.msg,
                            'success'
                            )
                             toast.success();
                            
                            e.target.reset();
                            return Licenseslist();


                    } else {
                        toast.error(data.msg)
                    }
                } else {
                    toast.error(data.msg)
                }

            })

    };

    const userlist = () => {
        axios.post(`/v1/org/dash/detail`, {}, { headers: { "Authorization": `Bearer ${token}` } })
            .then((resp) => {
                let data = resp.data;

                setLicense(data);

            });
    }


    const limit = 5;

    const formsave = (e, page) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        console.log("Formvlaues === ", Formvlaues);
        axios.post(`/v1/super-Agent/get-saving-amount-requests`, formData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
            const total = resp.data.totalRow;
            console.log(total);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
        });
    };
    const Licenseslist = async () => {
        await axios.post(`/v1/super-Agent/get-saving-amount-requests`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
            console.log(data);
            const total = resp.data.totalRow;
            console.log(total);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
        });
    };
    useEffect(() => {
        Licenseslist();
        
    }, []);
    const fetchComments = async (page) => {
        const senData = { page: page };
        // const cosole = Fromvalue;
        // console.log(Fromvalue);
        axios.post(`/v1/super-Agent/get-saving-amount-requests`, senData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
        });
        return data;
    };

    const handlePageClick = async (data) => {
        // console.log(data.selected);
        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
        setData(commentsFormServer);
    };
    return (
        <>
            <div>




                <ToastContainer />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" >
                            <div className="row g-5 g-xl-8">
                                <div className="col-lg-12">
                                    <div className="card mb-5 mb-xl-10">

                                        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bold m-0">Request D-ID Licenses</h3>
                                            </div>
                                        </div>
                                        <div id="kt_account_settings_profile_details" className="collapse show">
                                            <div className='d-flex p-7 row gx-3'>
                                                <label className="col-lg-2 col-form-label fw-semibold fs-6">Remaining Mandatory Saving </label>
                                                <div className="col-lg-4 fv-row">
                                                    <input type="text"
                                                        value={mandatoryAmount}

                                                        name="Licenses" className="form-control form-control-lg form-control-solid" placeholder="Remaining Mandatory Saving Amount" />
                                                </div>

                                                <label className="col-lg-2 col-form-label fw-semibold fs-6">Remaining Voluntary Saving </label>
                                                <div className="col-lg-4 fv-row">
                                                    <input type="text"
                                                        value={volantoryAmount}

                                                        name="Licenses" className="form-control form-control-lg form-control-solid" placeholder="Remaining Mandatory Saving Amount" />
                                                </div>
                                            </div>
                                            <form onSubmit={(e) => AddLicense(e)} id="kt_account_profile_details_form" className="form" >
                                                <div className="card-body border-top p-9">



                                                    <div className="row mb-6">
                                                        <label className="col-lg-2 col-form-label fw-semibold fs-6">Enter Amount (ETB)</label>


                                                        <div className="col-lg-4 fv-row">
                                                            <select
                                                                value={selectedOption}
                                                                onChange={(e) => {
                                                                    setSelectedOption(e.target.value);
                                                                    setInputValue('');
                                                                }}
                                                                name="amount"
                                                                className="form-select border-0 flex-grow-1"
                                                            >
                                                                <option value="">Select an option</option>
                                                                {options.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                        {selectedOption === 'option2' && (

                                                            <><div className="col-lg-4 fv-row">
                                                                <input type="text"
                                                                    value={inputValue}
                                                                    onChange={(e) => setInputValue(e.target.value)}
                                                                    name="amount" className="form-control form-control-lg form-control-solid" placeholder="Amount of required Amount" />
                                                            </div></>
                                                        )}
                                                        <label className="col-lg-2 col-form-label fw-semibold fs-6">Enter Reason For Amount </label>
                                                        <div className="col-lg-4 fv-row">
                                                            <input type="text"

                                                                name="reason" className="form-control form-control-lg form-control-solid" placeholder="Enter Reason For Amount" />
                                                        </div>
                                                        <div className="col-lg-12 fv-row offset-11 mt-5">


                                                            <button
                                                                type="submit"
                                                                className="btn btn-light btn-sub me-2"
                                                            >
                                                                Submit
                                                            </button>

                                                        </div>
                                                    </div>

                                                    {/* <div className='d-flex p-5 row '>
                                                <label className="col-lg-2 col-form-label fw-semibold fs-6">Enter Reason For Amount </label>
                                                <div className="col-lg-4 fv-row">
                                                    <input type="text"
                                                        value= "reason"
                                                        name="Licenses" className="form-control form-control-lg form-control-solid" placeholder="Enter Reason For Amount" />
                                                </div>

                                            </div>  */}







                                                </div>
                                                {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button  type="submit" className="btn btn-light btn-sub me-2">Submit</button>
                                       
                                    </div> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-12">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-lg-12">
                                        <div
                                            className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                                            style={{ marginTop: "2rem" }}
                                        >
                                            <div className="card-body container-xxl pt-10 pb-8">
                                                <div className="d-flex align-items-center">
                                                    <h1 className="fw-semibold me-3 text-white">Filter</h1>
                                                    <span className="fw-semibold text-white opacity-50">
                                                        {" "}
                                                        Licens Order History
                                                    </span>
                                                </div>

                                                <div className="d-flex flex-column">
                                                    <div className="d-lg-flex align-lg-items-center">
                                                        <form onSubmit={(e) => formsave(e)}>
                                                            <div className="d-flex flex-column">
                                                                <div className="d-lg-flex align-lg-items-center">
                                                                    <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                                                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                                                                            {/* <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="digitalrefID"
                                  placeholder="Enter D-ID ref"
                                />
                              </div> */}

                                                                            {/* <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-phone"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                  </svg>
                                </span>
                                <input
                                  type="number"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="phone"
                                  placeholder="Contact No."
                                />
                              </div> */}
                                                                            {/* 
                              <div className="col-lg-2 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0  flex-grow-1"
                                  name="status"
                                  data-control="select2"
                                  data-placeholder="Category"
                                  data-hide-search="true"
                                >
                                  <option value="" selected disabled>
                                    Status
                                  </option>
                                  <option value="pending">Pending</option>
                                  <option value="verified">Verified</option>
                                </select>
                              </div> */}

                                                                            <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <svg
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <rect
                                                                                            x="2"
                                                                                            y="2"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="13"
                                                                                            y="2"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="13"
                                                                                            y="13"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="2"
                                                                                            y="13"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                                <select
                                                                                    name="status"
                                                                                    className="form-select border-0 flex-grow-1"
                                                                                    data-control="select2"
                                                                                    data-placeholder="Status"
                                                                                    data-hide-search="true"
                                                                                >
                                                                                    <option value="" selected disabled>
                                                                                        Status
                                                                                    </option>
                                                                                    <option value="PENDING">Pending</option>
                                                                                    <option value="PASS">Approved</option>
                                                                                    <option value="PASS">Pass</option>
                                                                                    {/* <option value="Uganda">Uganda</option> */}
                                                                                </select>
                                                                            </div>

                                                                            <div className="col-lg-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                                <label className="small_label">
                                                                                    Start Date
                                                                                </label>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                                </span>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                                    name="fromDate"
                                                                                    placeholder="Date"
                                                                                />
                                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                            </div>

                                                                            <div className="col-lg-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                                <label className="small_label">End Date</label>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                                </span>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                                    name="toDate"
                                                                                    placeholder="Date"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="min-w-150px text-end d-flex">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-dark mr-3"
                                                                                id="kt_advanced_search_button_1"
                                                                            >
                                                                                Search
                                                                            </button>
                                                                            <button
                                                                                type="reset"
                                                                                onClick={Licenseslist}
                                                                                className="btn btn-secondary"
                                                                                id="kt_advanced_search_button_1"
                                                                            >
                                                                                Reset
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                            <div className="card-header border-0 pt-5">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold fs-3 mb-1">
                                                        License Order History
                                                    </span>
                                                    <span className="text-muted mt-1 fw-semibold fs-7"></span>
                                                </h3>
                                            </div>

                                            <div className="card-body py-3">
                                                <div className="table-responsive">
                                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                                        <thead>
                                                            <tr className="fw-bold text-muted th-title">
                                                                <th className="min-w-300px">ID Photo</th>

                                                                <th className="min-w-150px">Amount</th>
                                                                <th className="min-w-150px"> Date of Apply</th>
                                                                <th className="min-w-100px">Reason</th>
                                                                <th className="min-w-100px">Status</th>
                                                                {/* <th className="min-w-100px">
                              facial Identification
                            </th> */}
                                                                <th className="min-w-100px ">Approve Status</th>
                                                                {/* <th className="min-w-100px text-center">Actions</th> */}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {data.map((item) => (
                                                                <tr>
                                                                    <td>
                                                                        {" "}
                                                                        <div class="d-flex align-items-center">
                                                                            <div class="symbol symbol-45px me-5">
                                                                                <img src =  "https://chromepay1.s3.ap-south-1.amazonaws.com/abc/avatar-659651_640%20%281.png" alt="" />

                                                                            </div>
                                                                            <div class="d-flex justify-content-start flex-column">
                                                                                <a
                                                                                    href={`/org-customer-view/${item._id}`}
                                                                                    class="usertitle Acolor mb-2"
                                                                                >
                                                                                    {" "}
                                                                                    {item.ord_detail?.name}
                                                                                </a>
                                                                                <span class="font-icon mb-1">
                                                                                    <span class="">
                                                                                        <i class="fal fa-phone-volume"></i>
                                                                                    </span>
                                                                                    +{item.phone}
                                                                                </span>

                                                                                <span class="font-icon mb-1">
                                                                                    <span class="">
                                                                                        <i class="fal fa-envelope"></i>
                                                                                    </span>
                                                                                    {item.ord_detail?.email}{" "}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        {item.amount}
                                                                    </td>
                                                                    <td>
                                                                        {Moment(item.createdAt).format("DD/MM/YYYY")}
                                                                    </td>

                                                                    <td>{item.reason}</td>


                                                                    {/*                               
                              {item.biometric == 0 ? (
                                <td class="text-center">
                                  <span
                                    class="svg-icon svg-icon-muted svg-icon-2hx faild"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                        fill="currentColor"
                                      ></path>
                                      <rect
                                        x="9"
                                        y="13.0283"
                                        width="7.3536"
                                        height="1.2256"
                                        rx="0.6128"
                                        transform="rotate(-45 9 13.0283)"
                                        fill="currentColor"
                                      ></rect>
                                      <rect
                                        x="9.86664"
                                        y="7.93359"
                                        width="7.3536"
                                        height="1.2256"
                                        rx="0.6128"
                                        transform="rotate(45 9.86664 7.93359)"
                                        fill="currentColor"
                                      ></rect>
                                    </svg>
                                  </span>
                                </td>
                              ) : (
                                <td class="text-center ">
                                  <span
                                    class="svg-icon svg-icon-muted svg-icon-2hx success"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </td>
                              )} */}


                                                                    {item.status == "PENDING" ? (
                                                                        <>
                                                                            <td>
                                                                                <span className="badge badge-light-pending text-danger fs-5">
                                                                                    {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <td>
                                                                                <span className="badge badge-light-info fs-5 capitalize-text">
                                                                                    {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                    {item.status == "PENDING" ? (
                                                                        <>
                                                                            <td>
                                                                                <span className="badge badge-light-pending  text-danger fs-5">
                                                                                    {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    ) : (

                                                                        <>
                                                                            {item.status == "Reject" ?
                                                                                (
                                                                                    <>
                                                                                        <td>
                                                                                            <span class="badge badge-light-pending text-danger  fs-5">
                                                                                                {item.status}
                                                                                            </span>
                                                                                        </td>
                                                                                    </>) : (
                                                                                    <>
                                                                                        <td>
                                                                                            <span class="badge badge-light-info text-success fs-5">
                                                                                                {item.status}
                                                                                            </span>
                                                                                        </td>
                                                                                    </>)}

                                                                        </>
                                                                    )}

                                                                </tr>
                                                            ))}

                                                            {/* <tr>
                                                        <td>##41e8bd3f3ca</td>
                                                        <td> Virendra Shilpkar </td>
                                                        <td> virendra@gmail.com </td>
                                                        <td><span>+221 123-456-7840</span> </td>
                                                          <td>05/06/1980</td>
                                                        <td>05/08/2020</td>
                                                        <td>Ethiopia</td>
                                                        <td>
                                                        <td><span className="badge badge-light-info fs-5 capitalize-text">Verified</span></td> 
                                                        </td>
                                                        <td>
                                                           <div className="d-flex justify-content-center flex-shrink-0">
                                                                <button
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Suspend ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-minus-circle fs-4"></i>
                                                                    </span>
                                                                </a>
                                                                <button
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Password Reset ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fas fa-repeat-alt"></i>
                                                                    </span>
                                                                </a>
                                                                <a href='organization_detail.html'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="View Detail">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Delete">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-trash-alt"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Disable OTP">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye-slash"></i>
                                                                    </span>

                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="col-lg-12 mt-2 text-end">
                                                    <ReactPaginate
                                                        PreviousLabel={"Previous"}
                                                        NextLabel={"Next"}
                                                        breakLabel={"..."}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination justify-content-end"}
                                                        pageClassName={"page-item"}
                                                        pageLinkClassName={"page-link"}
                                                        PreviousClassName={"page-item mx-2"}
                                                        PreviousLinkClassName={"page-link pagestyle"}
                                                        NextClassName={"page-item"}
                                                        NextLinkClassName={"page-link"}
                                                        breakClassName={"page-item"}
                                                        breakLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>








            {/* added new progess bar  */}



        </>
    )
}

export default RequestLoan
