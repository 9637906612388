import Sidebar from "../Sidebar";
import Header from "../Header";
import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { error } from "jquery";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "./Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

function SuperAgentAwatingList() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [menu, setMenu] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgType = localStorage.getItem("orgType");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;

  const userlist = async () => {
    await axios
      .post(
        `/v1/super-Agent/awaiting-user`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.filter;

        setData(data);
        console.log("dghffdf", resp);
        const total = resp.data.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      })
      .catch();
  };

  useEffect(() => {
    userlist();
    // subAdminlist();
    ;
  }, []);
  ///////////////pagenestion///////////////
  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    axios
      .post(`/v1/super-Agent/awaiting-user`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    console.log("pushpak", page);
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  ////////////////delete api call /////////////////
  const handleVerfiy = (_id) => {
    const { custId } = { custId: _id };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to verify selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, verify it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `/v1/super-Agent/verify-customers`,
            { custId },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire(
                  "verified!",
                  "Customer verified successfully",
                  "success"
                );
                userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
      }
    });
  };

  //   const subAdminlist = async () => {
  //     await axios.post(`/subAdminRole/${orgID}`).then((resp) => {
  //       let data = resp.data.find;

  //       setMenu(data.customer);
  //     });
  //   };

  const handleView = (_id) => {
    console.log(_id);
    navigate(`/Super-Agent-customer-view/${_id}`);
    //window.location = `/org-customer-view/${_id}`;
    return false;
  };

  const fitteruserdata = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    axios
      .post(`/v1/super-Agent/awaiting-user`, Formvlaues)
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        console.log("ghjsgywqbqwkjsdhbmnswbqsjwbd,wjsquk    svwqmjdhw", data);
      })
      .catch(error);
  };
  return (
    <div>
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                      Bruh ID
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <form onSubmit={(e) => fitteruserdata(e)}>
                        <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                          <div className="row flex-grow-1 mb-5 mb-lg-0">
                            <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              {orgType === "Union" ? (
                                <>
                                  <input
                                    type="text"
                                    className="form-control form-control-flush flex-grow-1"
                                    name="digitalrefID"
                                    placeholder="Bruh IDs ref"
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    className="form-control form-control-flush flex-grow-1"
                                    name="digitalrefID"
                                    placeholder="Union-IDs ref"
                                  />
                                </>
                              )}
                            </div>

                            <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-phone"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control form-control-flush flex-grow-1"
                                name="phone"
                                placeholder="Contact No"
                              />
                            </div>

                            <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="2"
                                    y="2"
                                    width="9"
                                    height="9"
                                    rx="2"
                                    fill="currentColor"
                                  />
                                  <rect
                                    opacity="0.3"
                                    x="13"
                                    y="2"
                                    width="9"
                                    height="9"
                                    rx="2"
                                    fill="currentColor"
                                  />
                                  <rect
                                    opacity="0.3"
                                    x="13"
                                    y="13"
                                    width="9"
                                    height="9"
                                    rx="2"
                                    fill="currentColor"
                                  />
                                  <rect
                                    opacity="0.3"
                                    x="2"
                                    y="13"
                                    width="9"
                                    height="9"
                                    rx="2"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              <select
                                className="form-select border-0 flex-grow-1"
                                name="status"
                                data-control="select2"
                                data-placeholder="Category"
                                data-hide-search="true"
                              >
                                <option defaultValue="" selected disabled>
                                  Status
                                </option>

                                <option defaultValue="verified">
                                  Verified
                                </option>
                                <option defaultValue="pending">pending</option>
                              </select>
                            </div>

                            <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <i className="fas fa-globe-americas fs-1"></i>
                              </span>
                              <select
                                className="form-select border-0 flex-grow-1"
                                data-control="select2"
                                data-placeholder="Country"
                                data-hide-search="true"
                                name="nationality"
                              >
                                <option value="" selected disabled>
                                  Country
                                </option>
                                <option value="ETHOPIAN">Ethiopia</option>
                                <option value="India">India</option>
                                <option value="USA">USA</option>
                                <option value="Uganda">Uganda</option>
                              </select>
                            </div>

                            <div className="col-lg-2 col-sm-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                              <label className="small_label">Start Date</label>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <i className="fad fa-calendar fs-2"></i>
                              </span>
                              <input
                                type="date"
                                className="form-control unstyled form-control-flush flex-grow-1"
                                name="fromDate"
                                placeholder="from Date.."
                              />
                              <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                            </div>

                            <div className="col-lg-2 col-sm-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                              <label className="small_label">End Date</label>
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <i className="fad fa-calendar fs-2"></i>
                              </span>
                              <input
                                type="date"
                                className="form-control unstyled form-control-flush flex-grow-1"
                                name="toDate"
                                placeholder="to Date.."
                              />
                            </div>
                          </div>

                          <div className="min-w-175px d-flex gap-2 gap-sm-5">
                            <button
                              type="submit"
                              className="btn btn-dark"
                              id="kt_advanced_search_button_1"
                            >
                              Search
                            </button>
                            <button
                              type="reset"
                              onClick={userlist}
                              className="btn btn-secondary"
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                      Bruh Id List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            {orgType === "Union" ? (
                              <>
                                <th className="min-w-125px">Bruh IDs ref</th>
                              </>
                            ) : (
                              <>
                                <th className="min-w-125px">Union-IDs ref</th>
                              </>
                            )}

                            {/* <th className="min-w-125px">D-ID</th> */}
                            <th className="min-w-125px">Full Name</th>
                            <th className="min-w-125px">Email</th>
                            <th className="min-w-125px">Date of Birth</th>
                            <th className="min-w-125px">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item) => (
                            <tr>
                              <td className="text-center">
                                {item.digitalrefID}{" "}
                              </td>
                              {/* <td className="text-center">{item._id}</td> */}
                              <td className="text-center"> {item.fullname}</td>
                              <td className="text-center"> {item.email}</td>

                              <td className="text-center">
                                {/* { item.dateOfBirth.trim()} */}
                                {moment(
                                  item.dateOfBirth
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td className="text-center">
                                <span className="capitalize-text badge badge-light-pending fs-5 capitalize-text">
                                  {item.status}
                                </span>
                              </td>
                              <td className="text-center">
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  {item.status == "pending" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleVerfiy(item._id);
                                        }}
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Pending "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          {/* <i className="fad fa-eye-slash"></i> */}

                                          <img
                                            src="/assets_new/images/pendding1.png"
                                            width="30px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {/* added new button  */}

                                  <button
                                    onClick={(e) => {
                                      handleView(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-1"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAgentAwatingList;
