import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

function JdcpendingLoan({ hadlerjdc }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  var decode1 = jwt.decode(token);
  // let orgID = decode1.OrganisationID;

  const [pageCount, setpageCount] = useState("");
  const [notereason, setnotereason] = useState("");
  const [total, settotal] = useState([]);

  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);
  const [paymenthandler, setpaymenthandler] = useState(false);
  const [userdetails, setuserdetails] = useState([]);
  const [Delethandler, setDelethandler] = useState(false);
  const [customerloanid, setcustomerloanid] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);

  const limit = 10;

  const ActiveCustomerpending = async () => {
    await axios
      .post(
        `/v1/super-Agent/get_jdc_pending-loans`,
        { page: "1" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        console.log(
          "data===== ActiveCustomer pending list totalPagetotalPagetotalPagetotalPagetotalPagetotalPage",
          data
        );
        let filter = data.data;
        setuserdetails(filter);
        setShowLoader(false)
        const total = resp.data.totalRow;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        console.log("data===== filter", filter);
      });
  };

  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    setShowLoader(true)

    await axios
      .post(
        `/v1/super-Agent/get_jdc_pending-loans`,

        { senData },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        let filter = data.data;
        console.log({ filter });
        setuserdetails(filter);
        setShowLoader(false)

      });
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    
  };

  useEffect(() => {
    ActiveCustomerpending();
  }, []);

  ///////////////pagenestion///////////////

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/org/ActiveDID/getcustFilter`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        const total = resp.data.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const LoanRejected = () => {
    Swal.fire({
      title: "Enter Loan Reject Ression",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit ",
      showLoaderOnConfirm: true,
      preConfirm: async (rejectReason) => {
        return await axios
          .post(
            `/v1/super-Agent/loan-Reject`,
            {
              rejectReason: rejectReason,
              loanId: customerloanid,
              status: "REJECT",
            },
            { headers: { Authorization: `bearer ${token}` } }
          )
          .then((response) => {
            let data = response.data;
            {
              data.status
                ? Swal.fire(
                    "Successfully",
                    // navigate(`/Agent-customer-view/${_id}`)
                    ActiveCustomerpending()
                  )
                : Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid OTP!",
                  });
            }
          })
          .catch();
      },
    });
  };

  const VerfiyDelete = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDelethandler(false);
      let data = response.data;
      if (data.status) {
        LoanRejected();
      }
    } catch (error) {}
  };

  useEffect(() => {
    ;
  }, []);


  function FingRightImage(item,IDphoto) {
    const imageUrl = IDphoto;

    function isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

    if (imageUrl && isValidUrl(imageUrl)) {
      return imageUrl;
    } else {
      if (item?.gender === "MALE") {
        return "/assets_new/images/defaultmale.jpg";
      } else {
        return "/assets_new/images/femaledefault.png";
      }
    }
  }
  
  function handleImageError(e, item) {
    e.target.src = item?.gender === "MALE"
      ? "/assets_new/images/defaultmale.jpg"
      : "/assets_new/images/femaledefault.png";
  }




  return (
    <>
      <ToastContainer />

      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Customers
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="d-flex flex-column">
                        <div className="d-lg-flex align-lg-items-center">
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-12 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="digitalrefID"
                                  placeholder="Enter D-ID ref"
                                />
                              </div>
                            </div>

                            <div className="min-w-150px gap-1  text-end d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark mr-3"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        PENDING LOAN LIST
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Customer
                      </span>
                    </h3>
                    <div class="card-title"></div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-300px">ID Photo</th>
                            <th className="min-w-125px">Loan Amount</th>
                            <th className="min-w-125px">Super Agent</th>
                            <th className="min-w-125px">Organisation</th>

                            <th className="min-w-150px">Interest</th>
                            <th className="min-w-150px"> Interest Rate</th>
                            <th className="min-w-100px">Time Period</th>
                            <th className="min-w-100px">Total Amount</th>
                            {/* <th className="min-w-100px">
                                facial Identification
                              </th> */}
                            <th className="min-w-100px ">Status</th>
                            {/* <th className="min-w-100px text-center">Actions</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {userdetails?.map((item) => (
                            <tr>
                              <td>
                                {" "}
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-45px me-5">
                                  <img
                                        src={FingRightImage(item,item?.custId?.IDphoto)}
                                        alt=""
                                        onError={(e) => handleImageError(e, item)} 
                                      />
                                  </div>
                                  <div class="d-flex justify-content-start flex-column">
                                    <a
                                      //   href={`/org-customer-view/${item._id}`}
                                      class="usertitle Acolor mb-2"
                                    >
                                      {" "}
                                      {item?.custId?.fullname}
                                    </a>
                                    <span class="font-icon mb-1">
                                      <span class="">
                                        <i class="fal fa-phone-volume"></i>
                                      </span>
                                      +{item?.custId?.phone}
                                    </span>

                                    <span class="font-icon mb-1">
                                      <span class="">
                                        <i class="fal fa-envelope"></i>
                                      </span>
                                      {item?.custId?.email}{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>{item?.loanAmount}</td>
                              <td>{item?.superData?.firstName + item?.superData?.lastName}</td>
                              <td>{item?.organisation?.name}</td>
                              <td>{item?.totalInterestAmount}</td>
                              <td>{item?.interestRate} %</td>
                              <td>{item?.loanDuration}</td>
                              <td>{item?.totalAmount}</td>

                              {item.status == "PENDING" ? (
                                <>
                                  <td>
                                    <span className="capitalize-text badge badge-light-pending fs-5">
                                      {item.status}
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <span className="badge badge-light-info fs-5 capitalize-text">
                                      {item.status}
                                    </span>
                                  </td>
                                </>
                              )}

                              <td>
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  {/* <button
                                    onClick={() =>
                                      navigate(
                                        `/jdc/Accept/loan/${item._id}/${item?.custId?._id}`,
                                        {
                                          state: item,
                                        }
                                      )
                                    }
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button> */}

                                  {/* <button
                                    onClick={(e) => {
                                      setDelethandler(true);
                                      setcustomerloanid(item._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Loan Reject"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button> */}
                                  {/* <button
                                      className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      title="Disable OTP"
                                    >
                                      <span className="svg-icon svg-icon-3">
                                        <i className="fad fa-eye-slash"></i>
                                      </span>
                                    </button> */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-2"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-center"
          show={Delethandler}
          onHide={() => setDelethandler(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Verify Password</Modal.Title>
          </Modal.Header>
          <form onSubmit={VerfiyDelete}>
            <Modal.Body>
              <div className="col-lg-12 ">
                <div className="row">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    {" "}
                    Password
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="password"
                      name="password"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Enter password"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit" className="center">
                {" "}
                Verify
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
}

export default JdcpendingLoan;
