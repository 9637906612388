import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { useState , useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Chart from "react-apexcharts";
function AgentViewOrg() {
    const {_id} = useParams('');
    const [transections, setTransactions] = useState([]);
    const [users, setUsers] = useState([]);
    const [datas, setDatas] = useState('');
     let token = localStorage.getItem("organizationToken")
    const [frequency, setFrequency] = useState("month")
    const [useractivedatamonths, setuseractivedatamonths] = useState([]);
    const [useractivedatayear, setuseractivedatayear] = useState([]);
    const agentID = _id;
    const agnetdata = async () => {
        const {ID} = {ID :_id}
        await axios.post(`/v1/org/Agent/Agent_dash_main/${ID}`,{},{  headers:{"Authorization":`Bearer ${token}`}})
        .then(resp =>{
            let data = resp.data
            const user = data.finduser
            const transaction =  data.findTrans
            setTransactions(transaction);
            setUsers(user);
            setDatas(data);
            
        })

    }
 
    useEffect(() => {
     ;
     agnetdata();
    }, [])

    const transactionGraph = async (sort) => {
        const body ={agentId:agentID,filter: 'month'}
        await axios.post(`/v1/org/CRM/org-agent-cust-graph`,body,{ headers: {"Authorization" : `Bearer ${token}`}}).then((res) => {
          const userData = res.data.data;
    
          // console.log("++++++++++++++", userData);
          const categories = userData?.map((item) => item.count);
          const users = userData?.map((item) => item.monthName);
         
          setuseractivedatayear(categories);
          setuseractivedatamonths(users);
           console.log("$$$", categories)
          // setTranscation(data);
          // setuseractivedatayear(Object.values(userData));
    
          // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", useractivedatayear);
        });
      };
      useEffect(() => {
        transactionGraph(frequency);
      }, [])
    const handlesorting = (event) => {
        let sort = event.target.value;
        setFrequency(sort);
         transactionGraph(sort);
        console.log(sort)
    
      }
   
    return (
     <>
      <ToastContainer position="top-right"  />
 
     <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
     <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="container-xxl" >
                    <div className="row g-5 g-xl-8">
                        <div className="col-lg-12">
                         <div className="row">
                             <div className="col-lg-6">
                                    <div className="card card-flush">

                                        <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">

                                            <h3 className="card-title align-items-start flex-column text-white pt-15">
                                                <span className="fw-bold fs-2x mb-3">Agent Dashboard</span>
                                                <div className="fs-4 text-white">

                                                </div>
                                            </h3>


                                        </div>


                                        <div className="card-body mt-n20">

                                            <div className="mt-n20 position-relative dashorgnastion">

                                                <div className="row g-3 g-lg-6">

                                                    <div className="col-6">

                                                        <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">

                                                            <div className="symbol symbol-30px me-5 mb-8">
                                                                <span className="symbol-label">

                                                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                        <i className="fad fa-users"></i>
                                                                    </span>

                                                                </span>
                                                            </div>


                                                            <div className="m-0">
                                                                <span data-kt-countup="true" data-kt-countup-value={datas.findNoOfuser} className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                                                    {datas.findNoOfuser}</span>
                                                                <span className="text-gray-500 fw-semibold fs-6">
                                                                    Number Of Users</span>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="col-6">

                                                        <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">

                                                            <div className="symbol symbol-30px me-5 mb-8">
                                                                <span className="symbol-label">
                                                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                        <i className="fad fa-hands-usd"></i>
                                                                    </span>
                                                                </span>
                                                            </div>

                                                            <div className="m-0">
                                                                <span className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                                                {'$' + datas.totalTrasections}</span>
                                                                <span className="text-gray-500 fw-semibold fs-6">
                                                                Transaction Volume </span>
                                                            </div>

                                                        </div>

                                                    </div>


                                                    <div className="col-4">

                                                        <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">

                                                            <div className="symbol symbol-30px me-5 mb-8">
                                                                <span className="symbol-label">

                                                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                        <i className="fas fa-id-card"></i>
                                                                    </span>

                                                                </span>
                                                            </div>


                                                            <div className="m-0">
                                                                <span data-kt-countup="true" data-kt-countup-value={datas.NoOfTrans} className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                                                    {datas.NoOfTrans}</span>
                                                                <span className="text-gray-500 fw-semibold fs-6">
                                                                Number of Transactions </span>
                                                            </div>

                                                        </div>

                                                    </div>


                                                    <div className="col-4">

                                                        <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">

                                                            <div className="symbol symbol-30px me-5 mb-8">
                                                                <span className="symbol-label">

                                                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                        <i className="fad fa-landmark"></i>
                                                                    </span>

                                                                </span>
                                                            </div>
                                                            <div className="m-0">
                                                                <span data-kt-countup="true" data-kt-countup-value="0" className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                                                    0</span>
                                                                <span className="text-gray-500 fw-semibold fs-6">
                                                                 Loan Applications</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    
                                                    <div className="col-4">
                                                    <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                                        <div className="symbol symbol-30px me-5 mb-8">
                                                            <span className="symbol-label">
                                                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                    <i class="fad fa-hand-holding-usd"></i>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="m-0">
                                                           <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                                           <small>$</small>{datas.commissionamount}</span>
                                                            <span className="text-gray-500 fw-semibold fs-6">
                                                                Commission Amounts</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                    <div className="card card-flush h-xl-100">
                      <div className="container pt-7">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                              Agent Activity
                              </span>
                              <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                            </h3>
                          </div>
                          <div className="col-md-3 text-end"></div>
                          <div className="col-md-3  text-end">
                          <form >
                              <div className=" align-items-end filterCss gap-1 ">
                                {/* <div>
                                  <select
                                    className="form-select border-0 "
                                    
                                
                                    onChange={datafilter}
                                    name="filter"
                                  >
                                    <option value="1" selected="selected">
                                      {" "}
                                      Data filter
                                    </option>
                                    <option value="Day"> Day</option>
                                    <option value="Month"> Months</option>
                                    <option value="Year"> Year</option>
                                  </select>
                                </div> */}
<div className='d-flex align-items-center g-5 mb-4 ' style={{ "justify-content": "space-between" }}>

{/* <label className='mb-0 text-label ms-3'><h4> <i className="mdi mdi-chart-line   align-middle me-1"/>  Order Graph</h4></label> */}
<select defaultValue={frequency} onChange={handlesorting} className="form-select graph-select" >
  <option value="week"> Week</option>
  <option value="month"> Monthly</option>
  <option value="year">Yearly</option>

</select>
</div>
                               
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white overflow-auto mt-md-5 pt-md-5">
                        <Chart
                          type="bar"
                          width={"100%"}
                          height={400}
                          series={[
                            {
                              name: "User",
                               data:  useractivedatamonths,
                              // data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                            },
                          ]}
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 5,
                                columnWidth: 40,
                                startingShape: "flat",
                                endingShape: "rounded",
                                dataLabels: {
                                  position: "top",
                                },
                              },
                            },

                            title: {
                              //   text: "BarChar Developed by DevOps Team",
                              //   style: { fontSize: 30 },
                            },

                            subtitle: {
                              //   text: "This is BarChart Graph",
                              //   style: { fontSize: 18 },
                            },

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                               categories:useractivedatayear,
                              // categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
                                // title: {
                                //   text: "Year BY data",
                                //   style: { color: "##3c7f8c", fontSize: 10 },
                                // },
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                                style: { fontSize: "15", colors: ["#3c7f8c"] },
                              },
                              title: {
                                // text: "User In (K)",
                                // style: { color: "#f90000", fontSize: 15 },
                              },
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },

                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["white"],
                                fontSize: 10,
                                position: "top",
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>
                            </div>
                            <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                                style={{marginTop :  '2rem'}}>

                                <div className="card-body container-xxl pt-10 pb-8">

                                    <div className="d-flex align-items-center">
                                        <h1 className="fw-semibold me-3 text-white">Recent Data</h1>
                                        <span className="fw-semibold text-white opacity-50"></span>
                                    </div>

                                    {/* <div className="d-flex flex-column">

                                        <div className="d-lg-flex align-lg-items-center">

                                            <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5 w-xxl-850px h-lg-60px me-lg-10 my-5">

                                                <div className="row flex-grow-1 mb-5 mb-lg-0">

                                                    <div className="col-lg-4 d-flex align-items-center mb-3 mb-lg-0">
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                        <input type="text" autoComplete="off" className="form-control form-control-flush flex-grow-1" name="search" placeholder="User Name.." />

                                                    </div>





                                                    <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5">
                                                        </div>
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                        <select className="form-select border-0 flex-grow-1" data-control="select2" data-placeholder="Category" data-hide-search="true">
                                                            <option defaultValue="">Please Select</option>
                                                            <option defaultValue="1" selected="selected">Category</option>
                                                            <option defaultValue="2">In Progress</option>
                                                            <option defaultValue="3">Verified</option>
                                                        </select>

                                                    </div>

                                                </div>

                                                <div className="min-w-150px text-end">
                                                    <button type="submit" className="btn btn-dark" id="kt_advanced_search_button_1">Search</button>
                                                </div>

                                            </div>



                                        </div>

                                    </div> */}

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-12">

                            <div className="card card-xxl-stretch mb-5 mb-xl-8">

                                <div className="card-header border-0 pt-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold fs-3 mb-1">Recent Users</span>
                                        <span className="text-muted mt-1 fw-semibold fs-7">Over {datas.findNoOfuser} Customer</span>
                                    </h3>

                                </div>

                                <div className="card-body py-3">

                                    <div className="table-responsive">

                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                            <thead>
                                                <tr className="fw-bold text-muted th-title">

                                                 
                                                    <th className="min-w-125px">ID</th>
                                                    <th className="min-w-125px">Full Name</th>
                                                    <th className="min-w-150px">Email </th>
                                                    <th className="min-w-150px">Contact No. </th>
                                                    <th className="min-w-100px">Date of creation</th>
                                                    <th className="min-w-100px ">Status</th>
                                                    <th className="min-w-100px text-center">Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                             {users?.map((item)=>

                                                <tr>

                                                     <td>{item._id}</td>
                                                    <td>
                                                        {item.fullname}
                                                    </td>
                                                    <td>{item.email}   </td>
                                                    <td><span>{item.phone} </span>
                                                </td>
                                                <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                                                <td>
                                                    {item.status == "pending" ?
                                                    <>
                                                    <span className="capitalize-text badge badge-light-pending fs-5">{item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}</span>
                                                    </>:
                                                    <><span className="badge badge-light-info fs-5 capitalize-text">{item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}</span></>}
                                                   </td>
                                                <td>
                                                   <div className="d-flex justify-content-center flex-shrink-0">
                                                        <a href="#" className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1">
                                                            <span className="svg-icon svg-icon-3">
                                                            <i className="fad fa-eye fs-4"></i>
                                                            </span>

                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            )}
                                           

                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>

            </div>



            {/* <div className="col-lg-6">

                <div className="card card-xxl-stretch mb-5 mb-xl-8">

                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">Recent Transaction</span>
                            <span className="text-muted mt-1 fw-semibold fs-7">Over {datas.NoOfTrans} Customer</span>
                        </h3>

                    </div>

                    <div className="card-body py-3">

                        <div className="table-responsive">

                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                <thead>
                                    <tr className="fw-bold text-muted th-title">

                                        <th className="min-w-125px">Transaction <br /> Date </th>
                                        <th className="min-w-150px">PCN  <br />Number</th>
                                        <th className="min-w-150px">Sender <br /> Name </th>
                                        <th className="min-w-100px">Beneficiary <br /> Name </th>
                                        <th className="min-w-100px text-center">Sending <br /> Amount</th>
                                        <th className="min-w-100px text-center">Receiver <br /> Amount</th>
                                        <th className="min-w-100px ">Status</th>
                                        <th className="min-w-100px text-center">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {transections.map((item) =>
                                    <tr>


                                        <td>
                                            {moment(item.transactionDate).format()}
                                        </td>
                                        <td>{item.transactionID}   </td>
                                        <td><span>{item.senderName}</span>
                                    </td>
                                    <td>{item.beneficiaryName}</td>

                                    <td>{item.sendingAmount}</td>
                                    <td>{item.receiverAmount}</td>
                                    <td><span className="text-success">{item.status}</span></td>
                                    <td>
                                       <div className="d-flex justify-content-center flex-shrink-0">
                                            <a href="digital_user_detail.html" className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1">
                                                <span className="svg-icon svg-icon-3">
                                                    <i className="fad fa-eye fs-4"></i>
                                                </span>

                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                )}
                               


                    </tbody>

                </table>

            </div>

        </div>

      </div >

    </div > */}

  </div >
</div >

            </div>

            </div>
     </>
  );   
}

export default AgentViewOrg